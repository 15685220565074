import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { Select, Spin } from 'antd';
import bem from 'easy-bem';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getApplications, getCategories, getPromos } from 'models/catalog/actions';

import { ReactComponent as BadgeCheck } from 'assets/icons/badge-check.svg';
import { DropdownSearch, CardLogo } from 'components';
import { LoadingOutlined } from '@ant-design/icons';
import VerticalApplicationCard from '../vertical-application-card';
import SelectCatalog from '../select-catalog';

import './style.less';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const LOCAL_STORAGE_SEARCH_KEY = 'applicationsCatalog';

export const DEFAULT_OPTIONS = [
  { label: 'Все', value: 'all' },
  { label: 'Новинки', value: 'news' },
  { label: 'Бесплатные', value: 'free' },
  { label: 'Академическая лицензия', value: 'academic' },
];

const SoftwareSearch = () => {
  const b = bem('software-search');

  const [selectedItem, setSelectedItem] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const resultBlockLocation = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const searchCategory = new URLSearchParams(location.search).get('category');

  const applications = useSelector((state) => state.catalog.applications);
  const categories = useSelector((state) => state.catalog.categories);
  const promos = useSelector((state) => state.catalog.promos);
  const appLoading = useSelector((state) => state.catalog.applicationsLoading);

  const typeOptions = useMemo(() => {
    const promoTypes = [...promos].reduce((acc, cur) => {
      const { type } = cur;
      if (!acc.type[type.id]) {
        acc.type[type.id] = true;
        acc.options.push({ label: type.value, value: type.id });
      }
      return acc;
    }, { type: {}, options: [] });
    return [...DEFAULT_OPTIONS, ...promoTypes.options];
  }, [promos]);

  if (!searchCategory) {
    history.push('/catalog/search?category=all');
  }

  const applicationsFiltered = useMemo(
    () => applications.filter((x) => {
      if (searchCategory.trim() === '') {
        return true;
      }
      return (
        x.categories.filter(
          (categ) => categ.slug
            .toLocaleLowerCase()
            .search(searchCategory.toLocaleLowerCase()) > -1
        ).length > 0 || searchCategory.toLocaleLowerCase() === 'all'
      );
    }),
    [applications, searchCategory]
  );

  const applicationsListed = useMemo(
    () => applicationsFiltered.map((application) => ({
      value: application.slug,
      label: application.title,
      logo: application.mainImageUrl,
      vendor: application.vendor,
      analogs: application.analogs,
    })),
    [applicationsFiltered]
  );

  const handleChange = (categoryItem) => {
    setSelectedItem(categoryItem);
    const selectedOption = DEFAULT_OPTIONS.find(
      (item) => item.value === categoryItem.value
    ) || { value: 'special' };
    const promoPath = selectedOption.value === 'special' ? `&specialOfferId=${categoryItem.value}` : '';

    history.push(`/catalog/search?category=${searchCategory}&${selectedOption.value}=true${promoPath}`);
  };

  // TODO: find better method
  const getFilterParams = () => typeOptions.find(({ value }) => {
    const isSpecailOffer = new URLSearchParams(history.location.search).get('specialOfferId') === value;
    return !!new URLSearchParams(history.location.search).get(value) || isSpecailOffer;
  });

  useEffect(() => {
    const value = getFilterParams();
    if (!value || value.value === 'all') {
      dispatch(getApplications({ }));
      setSelectedItem(typeOptions[0]);
      return;
    }
    setSelectedItem(value);
    let params = { [value?.value]: true };
    if (!DEFAULT_OPTIONS.find((option) => value.value === option.value)) {
      params = { special: true, specialOfferId: value.value };
    }
    dispatch(getApplications(params));
  }, [location, typeOptions]);

  useEffect(() => {
    dispatch(getPromos({ stage: 'published' }));
    dispatch(getCategories({ stage: 'published' }));
  }, [dispatch]);

  useEffect(() => {
    resultBlockLocation?.current?.scrollIntoView();
  }, [dispatch, location.search]);

  getFilterParams();

  const handleSearchFilter = (input, { vendor, analogs }) => {
    const isVendorMatched = vendor?.name
      ?.toLowerCase()
      ?.includes(input.toLowerCase());
    const isAnalogsMatched = analogs?.find((analog) => analog.name.toLowerCase().includes(input.toLowerCase()));
    return isVendorMatched || isAnalogsMatched;
  };

  const handleChangeSearchValue = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSelectItem = (applicationSlug) => {
    const application = applications.find(
      (application) => application.slug === applicationSlug
    );
    history.push(`/catalog/${application.vendor.slug}/${applicationSlug}`);
  };

  const selectedCategoryTitle = useMemo(() => {
    const mainCategory = categories.find((category) => category.slug === searchCategory);
    return mainCategory?.title || 'Все приложения';
  }, [location.search, categories]);

  const apps = useMemo(() => (
    applicationsFiltered.map((app) => {
      const promo = promos.find((promoItem) => promoItem.type.id === app.specialOffer);

      return (
        <VerticalApplicationCard key={app.id} promo={promo} {...app} />
      );
    })
  ), [applicationsFiltered, promos]);

  return (
    <>
      <div className={b()}>
        <div className="catalog-content">
          <div className={b('search-block')}>
            <div className="search-input-wrapper">
              <div className="search-input">
                <h2 className="large-title">
                  Программное обеспечение:&nbsp;
                  <span>{selectedCategoryTitle}</span>
                </h2>
                <h2 className="mobile-title">
                  ПО:
                  <span>{selectedCategoryTitle}</span>
                </h2>
                <DropdownSearch
                  size="large"
                  localStorageKey={LOCAL_STORAGE_SEARCH_KEY}
                  options={applicationsListed}
                  onFilter={handleSearchFilter}
                  onChangeSearchValue={handleChangeSearchValue}
                  onSelectItem={handleSelectItem}
                  placeholder="Поиск по программному обеспечению"
                >
                  {applicationsListed.map(
                    ({
                      value, label, logo, vendor, analogs
                    }) => (
                      <Select.Option
                        key={value}
                        value={value}
                        label={label}
                        vendor={vendor}
                        analogs={analogs}
                      >
                        {logo ? (
                          <img
                            alt="option-logo"
                            src={logo}
                            className="ooc-dropdown-search__option-img"
                          />
                        ) : (
                          <CardLogo
                            className="ooc-dropdown-search__option-img"
                            label={label}
                          />
                        )}
                        <div className="software-search__option-label">
                          <span>{label}</span>
                          <span className="software-search__option-label__subtite">
                            {vendor.name}
                          </span>
                        </div>
                        {analogs?.find((analog) => analog.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())) && (
                          <div className="software-search__option-suffix">
                            <BadgeCheck />
                            Аналог
                          </div>
                        )}
                      </Select.Option>
                    )
                  )}
                </DropdownSearch>
              </div>
            </div>
          </div>
          <div ref={resultBlockLocation} className={b('selects')}>
            <SelectCatalog
              handleChange={handleChange}
              options={typeOptions}
              selectedItem={selectedItem}
            />
          </div>

          <div className={b('results-block')}>
            <div className="centred">
              <Spin spinning={appLoading} size="large" indicator={loadingIcon} />
            </div>

            {!appLoading && (
              <div className="card-list">
                {applicationsFiltered.length > 0
                  ? (
                    <div className="content-grid">
                      {apps}
                    </div>
                  )
                  : (
                    <div className="no-results">
                      <h3 className="no-results-header">Ничего не найдено</h3>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftwareSearch;
