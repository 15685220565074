import React, {
  createContext, useContext, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import ButtonV2 from '../button-v2';
import './style.less';


export const LimitedAccessModalContext = createContext(null);

export const LimitedAccessModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('limited-access');
  const toggle = () => setIsOpen((v) => !v);

  return (
    <LimitedAccessModalContext.Provider value={{ toggle }}>
      {children}
      <Modal
        title="Ошибка"
        visible={isOpen}
        onOk={toggle}
        onCancel={toggle}
        footer={[
          <ButtonV2 onClick={toggle} type="primary">
            {t('good')}
          </ButtonV2>
        ]}
      >
        <div className="content">
          <img src="/images/limited-access/work-time-bro.png" alt="" />
          <p className="content-text">
            {t('access-denied')}
          </p>
        </div>
      </Modal>
    </LimitedAccessModalContext.Provider>
  );
};

export const useLimitedAccessModalContext = () => useContext(LimitedAccessModalContext);

export default LimitedAccessModalProvider;
