import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './style.less';
import AdminDirectoryManagment from './components/admin-directory-managment';
import AdminUserManagment from './components/admin-user-managment';
import AdminPromoManagment from './components/admin-promo-managment';


const CatalogAdmin = () => (
  <Route>
    <Switch>
      <Route exact path="/catalog-admin">
        <Redirect to="/catalog-admin/directory-managment" />
      </Route>

      <Route exact path="/catalog-admin/directory-managment">
        <AdminDirectoryManagment />
      </Route>
      
      <Route exact path="/catalog-admin/user-managment">
        <AdminUserManagment />
      </Route>
      
      <Route exact path="/catalog-admin/promo-managment">
        <AdminPromoManagment />
      </Route>
    </Switch>
  </Route>
);

export default CatalogAdmin;
