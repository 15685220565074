import React from 'react';
import bem from 'easy-bem';

import './style.less';


const TabResult = (props) => {
  const b = bem('tab-result');

  const {
    type,
    title,
    subTitle,
    description
  } = props;

  const getIcon = () => {
    switch (type) {
      case 'failure': {
        return <img src="/icons/failure-icon.png" alt="" />;
      }
      case 'success':
      default: {
        return <img src="/icons/celebration-confeti-v2.png" alt="" />;
      }
    }
  };

  return (
    <div className={b({ type })}>
      <div className={b('icon')}>{ getIcon() }</div>
      <div className={b('title')}>{ title }</div>
      <div className={b('sub-title')}>{ subTitle }</div>
      <div className={b('description')}>{ description }</div>
    </div>
  );
};

export default TabResult;
