import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  TEMP_SOFTWARE_CATALOG_API,
  TEMP_SOFTWARE_CATALOG_API_V2,
} from 'models/catalog/api';
import {
  get as oGet,
  post as oPost,
  remove as oDelete,
  errorMessageCatcher,
} from 'utils/request';

import './style.less';
import { ButtonV2 } from 'components';
import {
  ArrowDownOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getPaginationAuthors,
  getAuthorsOrganization,
} from 'models/catalog/actions';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import { writeAuthorsReportFile } from 'utils/xlsx/writeXlsx';
import { useDebounce } from 'react-use';
import dayjs from 'dayjs';
import { writeAuthorReportFile } from '../../../../../../utils/xlsx/writeXlsx';
import AdminAuthorForm from './form';
import AdminAuthorsPaneTable from './table';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const roleRu = {
  admin: 'Администратор',
  author: 'Автор',
  user: 'Пользователь',
};

/* TEMP create functions */
export const createAuthor = (data) => {
  const url = '/authors';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const deleteAuthor = (id) => {
  const url = `/authors/${id}`;

  return oDelete(
    url,
    {
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
      accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const getAuthorsReport = () => {
  const url = '/authors';

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const getAuthorReport = (id) => {
  const url = `/authors/${id}/report`;

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API_V2
  );
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
};

const reportTableColumns = [
  {
    title: 'Вендор',
    dataIndex: 'vendor',
    key: 'vendor',
    render: (vendor) => vendor.name,
  },
  {
    title: 'Приложение',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Наличие дистрибутива',
    dataIndex: 'packages',
    key: 'packages',
    render: (packages) => (packages?.length ? 'Да' : 'Нет'),
  },
  {
    title: 'Дата публикации',
    dataIndex: 'publishedAt',
    key: 'publishedAt',
    render: (publishedAt, { stage }) => (stage === 'published' ? dayjs(publishedAt).format('DD.MM.YYYY') : '-'),
  },
];

const AdminAuthorsPane = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [searchTitle, setSearchTitle] = useState('');

  const [changesCounter, setChangesCounter] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeConfig, setRemoveConfig] = useState({ open: false });
  const [reportConfig, setReportConfig] = useState({ open: false });
  const [authorReport, setAuthorReport] = useState(null);
  const [authorsParams, setAuthorsParams] = useState(DEFAULT_PARAMS);
  const [skipDebounce, setSkipDebounce] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const { items, totalCount, loading } = useSelector(
    (state) => state.catalog.paginationAuthors
  );
  const authorsOrganization = useSelector(
    (state) => state.catalog.authorsOrganization
  );

  useEffect(() => {
    dispatch(getAuthorsOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (reportConfig.author?.idAuth) {
      getAuthorReport(reportConfig.author.idAuth).then((response) => {
        const result = {
          ...response,
          fio: `${response?.user.surname} ${response?.user.name} ${response?.user.patronymic}`,
        };
        setAuthorReport(result);
      });
    } else {
      setAuthorReport(null);
    }
  }, [reportConfig.author]);

  const organizationsList = useMemo(
    () => authorsOrganization.map((organization) => ({
      value: organization,
      label: organization,
    })),
    [authorsOrganization]
  );

  useEffect(() => {
    dispatch(getPaginationAuthors(authorsParams));
  }, [dispatch, changesCounter, authorsParams]);

  const onAddAuthorForm = async (model, form) => {
    setModalLoading(true);
    await createAuthor(model).then(() => {
      message.success({
        content: 'Автор добавлен',
        icon: <CheckOutlined />
      });
      form.resetFields();
      setChangesCounter((state) => state + 1);
      setModalIsOpen(false);
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const handleDeleteAuthorClick = async (id) => {
    setModalLoading(true);
    await deleteAuthor(id).then(() => {
      message.success({
        content: 'Пользователь исключен из списка авторов',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setRemoveConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setModalIsOpen(false);
  };

  const setVendorsSortHandler = (sort) => {
    setAuthorsParams((prev) => ({ ...prev, sort }));
  };

  const onPaginationChange = (params) => {
    setAuthorsParams((prev) => ({ ...prev, ...params }));
  };

  const handleReportAuthors = () => {
    getAuthorsReport().then((response) => writeAuthorsReportFile(response));
  };

  const handleChangeOrganization = (organization) => {
    setAuthorsParams((prev) => ({ ...prev, organization, page: 1 }));
  };

  const resetFields = () => {
    setAuthorsParams(DEFAULT_PARAMS);
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setAuthorsParams((prev) => ({ ...prev, fullname: searchTitle, page: 1 }));
    },
    500,
    [searchTitle]
  );

  const removeCancel = () => {
    if (modalLoading) {
      return;
    }
    setReportConfig({ open: false });
  };

  return (
    <div className="admin-form admin-authors-pane">
      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Form form={filterForm} className="filter">
          <Space>
            <Form.Item name="fullname" className="filter-item filter-search">
              <Input
                placeholder="Поиск по ФИО"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="organization"
              className="filter-item filter-select"
            >
              <Select
                placeholder="Выберите организацию"
                popupClassName="admin-select-dropdown"
                options={organizationsList}
                onChange={handleChangeOrganization}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                filterForm.resetFields();
                resetFields();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
        </Form>
        <Space>
          <ButtonV2
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleReportAuthors}
          >
            Сформировать отчёт
          </ButtonV2>
          <ButtonV2
            type="link"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Добавить новую запись
          </ButtonV2>

          {modalIsOpen && (
            <AdminAuthorForm
              open={modalIsOpen}
              onClose={closeModal}
              onFinish={onAddAuthorForm}
              requestLoading={modalLoading}
            />
          )}
        </Space>
      </Row>

      <Spin indicator={loadingIcon} spinning={loading}>
        <AdminAuthorsPaneTable
          items={items}
          params={authorsParams}
          totalCount={totalCount}
          onSortParams={setVendorsSortHandler}
          onChangeParams={onPaginationChange}
          setRemoveConfig={setRemoveConfig}
          setReportConfig={setReportConfig}
          roleRu={roleRu}
        />
      </Spin>

      <Modal
        open={removeConfig.open}
        okText="Изъять роль"
        onOk={() => handleDeleteAuthorClick(removeConfig.author.idAuth)}
        okButtonProps={{ loading: modalLoading }}
        cancelButtonProps={{ disabled: modalLoading }}
        onCancel={removeCancel}
        title="Изъять роль"
        width={723}
      >
        {removeConfig.author && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                Вы уверены, что хотите изъять роль
                {' '}
                {roleRu[removeConfig.author.role]}
                <br />
                у пользователя "
                {removeConfig.author.surname}
                {' '}
                {removeConfig.author.name}
                {' '}
                {removeConfig.author.patronymic}
                "?
              </p>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        className="report-modal"
        footer={(
          <ButtonV2
            onClick={() => writeAuthorReportFile(authorReport)}
            disabled={!authorReport?.applications?.length}
            className="report-author-btn"
          >
            Скачать отчет
          </ButtonV2>
        )}
        open={reportConfig.open}
        onCancel={() => setReportConfig({ open: false })}
        title="Отчет"
        width={1000}
        loading
      >
        {!authorReport ? (
          <div className="centred-full">
            <Spin />
          </div>
        ) : (
          <div>
            <h2 className="report-author">{authorReport.fio}</h2>
            <Table
              pagination={false}
              columns={reportTableColumns}
              dataSource={authorReport.applications.map((app) => ({
                ...app,
                key: app.id,
              }))}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdminAuthorsPane;
