import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PlusOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { getPaginationFeedbacks } from 'models/catalog/actions';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import { writeFeedbacksReportFile } from 'utils/xlsx/writeXlsx';
import { get as oGet } from 'utils/request';

import ButtonV2 from 'components/button-v2';

import Filters from './components/reviewFilters';
import ReviewModal from './components/reviewModal';
import ReviewTable from './components/reviewsTable';


const DEFAULT_REVIEW_MODAL_CONFIG = {
  review: null,
  edit: true
};

const DEFAULT_PAGINATION_PARAMS = {
  page: 1,
  limit: 20,
};


const getFeedbacksRecord = () => {
  const url = '/feedbacks';

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API,
    { stage: 'draft,published' }
  );
};

const AdminReviewsPane = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [changesCounter, setChangesCounter] = useState(0);

  const [dataParams, setDataParams] = useState(DEFAULT_PAGINATION_PARAMS);

  const [modalConfig, setModalConfig] = useState(DEFAULT_REVIEW_MODAL_CONFIG);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataParams || Object.keys(dataParams).length === 0) return;
    
    dispatch(getPaginationFeedbacks(dataParams));
  }, [dispatch, changesCounter, dataParams]);

  const updateCounter = () => {
    setChangesCounter((state) => state + 1);
  };

  const handleFiltersChange = (params) => {
    setDataParams((prev) => ({ ...prev, page: 1, ...params }));
  };

  const handlePaginationChange = (params) => {
    setDataParams((prev) => ({ ...prev, ...params }));
  };

  const handleSortChange = (sortParams) => {
    setDataParams((prev) => ({ ...prev, sort: sortParams }));
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  
  const handleReportFeedbacks = () => {
    getFeedbacksRecord().then((response) => writeFeedbacksReportFile(response));
  };

  const reportDownloadAction = {
    title: 'reportDownload',
    component: (
      <ButtonV2
        type="link"
        icon={<ArrowDownOutlined />}
        onClick={handleReportFeedbacks}
      >
        Сформировать отчёт
      </ButtonV2>
    )
  };

  const addReviewAction = {
    title: 'addReview',
    component: (
      <ButtonV2
        type="link"
        icon={<PlusOutlined />}
        onClick={() => {
          setModalConfig(DEFAULT_REVIEW_MODAL_CONFIG);
          setModalOpen(true);
        }}
      >
        Добавить новую запись
      </ButtonV2>
    )
  };

  return (
    <div className="admin-reviews-pane">
      <Filters
        onChange={handleFiltersChange}
        actions={[reportDownloadAction, addReviewAction]}
      />
      <ReviewTable
        modalActions={{ setModalConfig, setModalOpen }}
        onChange={handlePaginationChange}
        pagination={{ page: dataParams.page, limit: dataParams.limit }}
        onSortChange={handleSortChange}
        tableUpdater={updateCounter}
      />
      <ReviewModal
        review={modalConfig.review}
        mode={modalConfig.edit ? 'edit' : 'view'}
        open={isModalOpen}
        closeHandler={handleModalClose}
        tableUpdater={updateCounter}
      />
    </div>
  );
};

export default AdminReviewsPane;
