import { UserManager } from 'oidc-client';


const target = process.env.REACT_APP_SSO_TARGET;
const client = process.env.REACT_APP_SSO_CLIENT;
const clientId = process.env.REACT_APP_SSO_CLIENT_ID;

const config = {
  client_id: clientId,
  redirect_uri: `${client}/callback`,
  // response_type: 'id_token token',
  response_type: 'code',
  scope: 'openid profile api1',
  authority: `${target}/`,
  post_logout_redirect_uri: `${client}/index.html`,
  silent_redirect_uri: `${target}/silent-callback.html`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  filterProtocolClaims: true,
  accessTokenExpiringNotificationTime: 4
};

const userManager = new UserManager(config);

const login = () => userManager.signinRedirect();
const renewToken = () => userManager.signinSilent();
const logout = () => userManager.signoutCallback();

export default userManager;

export {
  login,
  renewToken,
  logout
};
