import React from 'react';
import { Link } from 'react-router-dom';
import bem from 'easy-bem';
import { useSelector, useDispatch } from 'react-redux';

import Logo from 'components/logo';
import NavProfile from 'components/nav-profile';

import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg';

import { setDrawerVisible } from 'models/common/actions';
import { reducers } from 'models';

import './style.less';


type RootState = ReturnType<typeof reducers>;

export const NavTop: React.FC = () => {
  const b = bem('nav-top');
  const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.common.isMobile);

  return (
    <div className={b()}>
      <Link to="/">
        <Logo />
      </Link>
      {isMobile ? <BurgerIcon onClick={() => dispatch(setDrawerVisible(true))} /> : <NavProfile />}
    </div>
  );
};
