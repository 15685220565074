/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import bem from 'easy-bem';
import { TabPane, Tabs } from 'components';

import { ReactComponent as VendorIcon } from 'assets/icons/admin-pane/vendors.svg';
import { ReactComponent as CategoryIcon } from 'assets/icons/admin-pane/category.svg';
import { ReactComponent as ApplicationIcon } from 'assets/icons/admin-pane/apps.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/admin-pane/dashboard.svg';
import { SettingOutlined } from '@ant-design/icons';
import AdminCategoriesPane from './components/admin-categories-pane';
import AdminVendorsPane from './components/admin-vendors-pane';
import AdminApplicationsPane from './components/admin-applications-pane';
import AdminDashboardPane from './components/admin-dashboard-pane';
import './style.less';
import AdminConfiguratorPane from './components/admin-configurator-pane';


const AdminDirectoryManagment = () => {
  const b = bem('admin-panels');
  const [activeTab, setActiveTab] = useState('category');

  return (
    <div className={b()}>
      <h2 className={b('title')}>Управление каталогом ПО</h2>
      <div className="catalog-content">
        <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <CategoryIcon />
                <span>Категории</span>
              </div>
            )}
            key="category"
          >
            {activeTab === 'category' && <AdminCategoriesPane />}
          </TabPane>
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <VendorIcon />
                <span>Вендоры</span>
              </div>
            )}
            key="vendor"
          >
            {activeTab === 'vendor' && <AdminVendorsPane />}
          </TabPane>
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <ApplicationIcon />
                <span>Приложения</span>
              </div>
            )}
            key="application"
          >
            {activeTab === 'application' && <AdminApplicationsPane />}
          </TabPane>
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <DashboardIcon />
                <span>Дашборд</span>
              </div>
            )}
            key="dashboard"
          >
            {activeTab === 'dashboard' && <AdminDashboardPane />}
          </TabPane>
          <TabPane
            tab={(
              <div>
                <SettingOutlined />
                <span>Конфигуратор</span>
              </div>
            )}
            key="configurator"
            icon={<SettingOutlined />}
          >
            {activeTab === 'configurator' && <AdminConfiguratorPane />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminDirectoryManagment;
