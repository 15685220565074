import React from 'react';

import { ButtonV2 } from 'components';
import { Link } from 'react-router-dom';

import bem from 'easy-bem';

import './style.less';


const NotFoundPage = () => {
  const b = bem('not-found');

  return (
    <div className={b('main-block')}>
      <div className="error-type">404</div>
      <div className="content">
        <h1>Ошибочка вышла</h1>
        <p>
          Данная страница устарела или просто не существует.
          <br />
          Перейдите на главную страницу по ссылке ниже
        </p>
        <ButtonV2 type="primary">
          <Link to="/catalog">
            На главную
          </Link>
        </ButtonV2>
      </div>
    </div>
  );
};

export default NotFoundPage;
