import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import bem from 'easy-bem';
import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import Button from 'components/button-v2';

import { removeToken } from 'utils/auth';
import userManager from 'utils/auth-oidc';

import {
  getPhotoUrl, getUserName, getAuthState, getUserId
} from 'models/user/selectors';
// import { useLimitedAccessModalContext } from '../limited-access-modal';


import './style.less';


const NavProfile = () => {
  const b = bem('nav-profile');

  // const { toggle: openRestrictionBanner } = useLimitedAccessModalContext();

  const photoUrl = useSelector(getPhotoUrl);
  const userName = useSelector(getUserName);
  const isAuth = useSelector(getAuthState);
  const userId = useSelector(getUserId);

  const onLogout = () => {
    userManager.signoutRedirect()
      .then(() => removeToken())
      .catch(() => {
        removeToken();
        message.error({
          content: 'Ошибка при входе',
          icon: <CloseOutlined />,
        });
      });
  };

  const onLogin = () => {
    userManager.signinRedirect().catch(() => message.error({
      content: 'Ошибка при входе',
      icon: <CloseOutlined />,
    }));
    // openRestrictionBanner();
  };

  React.useEffect(() => {
    if (isAuth && userId) {
      window.dataLayer.push({ user_id: `${userId}` });
    }
  }, [isAuth, userId]);

  return (
    <div className={b()}>
      <div className={b('items')}>
        {isAuth ? (
          <>
            <img src="icons/user-calendar.svg" alt="" />
            <img src="icons/user-notification.svg" alt="" />
          </>
        ) : (
          <>
            <img src="icons/eye.svg" alt="" />
          </>
        )}
      </div>
      <div className={b('wrapper')}>
        {isAuth ? (
          <>
            <NavLink
              to="/personal"
              className={b('user')}
            >
              <div className={b('user-avatar')}>
                {photoUrl ? (
                  <img src={photoUrl} alt="" />
                ) : (
                  <img src="icons/user-avatar.svg" alt="" />
                )}
              </div>
              <div className={b('user-name')}>
                {userName}
              </div>
            </NavLink>
            <Button
              className={b('exit-button')}
              onClick={onLogout}
              icon={<img src="icons/user-logout.svg" alt="" />}
            />
          </>
        ) : (
          <Button
            type="link"
            className={b('exit-button')}
            onClick={onLogin}
          >
            Вход
          </Button>
        ) }
      </div>
    </div>
  );
};

export default NavProfile;
