import React from 'react';
import bem from 'easy-bem';
import { Input } from 'antd';

import './style.less';


const { TextArea } = Input;

// TODO: deprecated, remove
const OOCInput = (props) => {
  const b = bem('ooc-input');
  const {
    className: classNameProp,
    textarea = false,
    ...rest
  } = props;

  const cn = classNameProp ? `${classNameProp} ${b()}` : b();

  if (textarea) {
    return (
      <TextArea className={`${cn} ${b('textarea')}`} {...rest} />
    );
  }

  return (
    <Input className={cn} {...rest} />
  );
};

export default OOCInput;
