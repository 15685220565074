
export const CATEGORIES_ICON_DEFAULT_SET = [
  'GeneralPurposeSoftware',
  'UrbanEconomy',
  'ExtractiveIndustry',
  'Healthcare',
  'InformationTechnology',
  'Education',
  'ManufacturingIndustry',
  'Agriculture',
  'Construction',
  'Transport',
  'Finance',
  'Energy',
];

export const CATEGORIES_ICON_DEFAULT_GET_DEPRECATED = [
  'SearchIcon',
  'MonitorIcon',
  'FlashIcon',
  'MortarboardIcon',
  'PodiumIcon',
  'ImageIcon',
  'ReduceIcon',
  'MedicalKitIcon',
  'CarIcon',
  'ToolIcon',
  'ConicalFlaskIcon',
  'BoltIcon',
];

export const CATEGORIES_ICON_DEFAULT_GET = [
  'GeneralPurposeSoftware',
  'UrbanEconomy',
  'ExtractiveIndustry',
  'Healthcare',
  'InformationTechnology',
  'Education',
  'ManufacturingIndustry',
  'Agriculture',
  'Construction',
  'Transport',
  'Finance',
  'Energy',
  ...CATEGORIES_ICON_DEFAULT_GET_DEPRECATED,
];
