import React from 'react';
import { Route } from 'react-router-dom';

import Page from './component';


export default <Route
  exact
  key="catalog"
  path={[
    '/', '/home', '/main', '/index.html', '/guest',
    '/catalog',
    '/catalog/admin', // temp route
    '/catalog/search',
    '/catalog/:vendorSlug',
    '/catalog/:vendorSlug/:appSlug'
  ]}
  component={Page}
/>;
