import React, {
  Fragment, useEffect, useMemo, useState
} from 'react';
import { ButtonV2 } from 'components';
import {
  Divider, Form, Modal, Select, Spin
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications } from 'models/catalog/actions';
import './style.less';
import bem from 'easy-bem';
import { errorMessageCatcher, patch as oPatch } from 'utils/request';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import { LoadingOutlined } from '@ant-design/icons';


export const categoryRemap = (data) => {
  const url = '/applications/categories-remap';

  return oPatch(
    url,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};
const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const RemoveModalWithChange = ({
  open,
  onCancel,
  category,
  handleDelete,
  requestLoading,
  startRequest,
}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const appList = useSelector((state) => state.catalog.applications);
  const applicationsLoading = useSelector(
    (state) => state.catalog.applicationsLoading
  );
  const categories = useSelector((state) => state.catalog.categories);
  const [applications, setApplications] = useState([]);
  const b = bem('remove-with-change-modal');

  useEffect(() => {
    setApplications(appList);
  }, [appList]);

  const appsOptions = useMemo(() => {
    const publishedCategories = categories
      .filter((el) => el.stage === 'published')
      .map((el) => ({ label: el.title, value: el.id }));
    if (!category?.id) {
      return publishedCategories;
    }
    return publishedCategories.filter((el) => el.value !== category.id);
  }, [categories, category]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [form, open]);

  const handleFinish = async (val) => {
    startRequest(true);
    const prepearedData = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(val)) {
      prepearedData.push({
        applicationId: applications[key].id,
        addCategoriesIds: [value],
        deleteCategoryId: category.id,
      });
    }
    await categoryRemap(prepearedData).then(() => {
      handleDelete(category.id);
    }, errorMessageCatcher);
    startRequest(false);
  };

  useEffect(() => {
    const categoryId = category?.id;
    if (!categoryId) {
      return;
    }
    dispatch(getApplications({ stage: 'draft,published', categoryId }));
  }, [dispatch, category]);

  return (
    <Modal
      width={710}
      open={open}
      okText="Удалить"
      onCancel={onCancel}
      title="Удалить запись"
      footer={[
        <>
          <ButtonV2 onClick={onCancel} disabled={requestLoading}>
            Отменить
          </ButtonV2>
          <ButtonV2
            disabled={applicationsLoading}
            loading={requestLoading}
            type="primary"
            onClick={form.submit}
          >
            Удалить с заменой
          </ButtonV2>
        </>,
      ]}
      maskClosable
    >
      {category && (
        <Form
          layout="vertical"
          form={form}
          validateMessages={{ required: 'Пожалуйста выберите новую категорию' }}
          onFinish={handleFinish}
        >
          <div className={b()}>
            <p className={b('text')}>
              С категорией
              {' '}
              <span className={b('category')}>{category.title}</span>
              {' '}
              связаны
              приложения, выберите категорию, которую необходимо связать с
              приложениями вместо удаляемой
            </p>
            <Spin
              indicator={loadingIcon}
              size="large"
              spinning={applicationsLoading}
            >
              {applications.map((app, index) => (
                <Fragment key={app.id}>
                  <Divider />
                  <div className={b('item')}>
                    <div className={b('left')}>
                      <div className={b('label')}>Наименование приложения</div>
                      <div className={b('item-app')}>{app.title}</div>
                    </div>
                    <Form.Item
                      rules={[{ type: 'string', required: true }]}
                      name={index}
                      label="Выберете новую категорию"
                      className={b('right')}
                    >
                      <Select
                        placeholder="Выберете новую категорию"
                        options={appsOptions}
                        disabled={requestLoading || applicationsLoading}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </div>
                </Fragment>
              ))}
            </Spin>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default RemoveModalWithChange;
