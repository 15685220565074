import Breadcrubms from 'components/breadcrubms';
import CatalogSidebar from 'components/catalog/components/catalog-sidebar';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import routes from 'utils/routes';

import './style.less';
import AdminLayout from '../admin-layout/component';


const HOME_PAGE = '/catalog';
const SEARCH_PAGE = '/catalog/search';
const ADMIN_PAGE = '/catalog-admin';

const PageWrapperMiddleware = () => {
  const location = useLocation();
  const isSidebarVisible = useMemo(
    () => location.pathname === HOME_PAGE || location.pathname === SEARCH_PAGE,
    [location.pathname]
  );

  const isAdminPage = location.pathname.startsWith(ADMIN_PAGE);

  if (isAdminPage) {
    return <AdminLayout />;
  }

  return (
    <div className={isSidebarVisible ? 'catalog-wrapper' : 'other-page'}>
      {isSidebarVisible && (
        <div className="sidebar">
          <CatalogSidebar />
        </div>
      )}
      <div className={isSidebarVisible ? 'content-wrapper' : 'content'}>
        <Breadcrubms />
        {routes}
      </div>
    </div>
  );
};

export default PageWrapperMiddleware;
