import React, { useState } from 'react';

import InputV3 from 'components/input-v3';
import {
  Checkbox, Form, Tooltip
} from 'antd';

import { ReactComponent as PartnerWindow } from 'assets/images/catalog/partner-form.svg';
import { ReactComponent as UserCardIcon } from 'assets/icons/partner/user-card.svg';
import { ReactComponent as ChainesIcon } from 'assets/icons/partner/chaines.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/partner/chat.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/partner/success-icon.svg';

import { emailValidation, nameValidation, phoneValidation } from 'utils/form';

import AcceptFile from 'assets/docs/accept.pdf';

import bem from 'easy-bem';
import ButtonV2 from 'components/button-v2';
import Modal from 'components/modal';

import './style.less';
import { errorMessageCatcher } from 'utils/request';


const validateMessages = {
  required: 'Поле "${label}"обязательно!',
  types: {
    email: false,
  },
};

const nameRules = {
  rules: [{ type: 'string', required: true },
    {
      pattern: nameValidation,
      message: 'Поле может содержать только текст'
    }],
};

const emailRules = {
  rules: [{ type: 'email', required: true }, { pattern: emailValidation, message: 'Введён некорректный E-mail' }],
};

const phoneRules = {
  rules: [{ pattern: phoneValidation, message: 'Введён некорректный номер телефона!' }],
};

const NeedConsultationModalForm = ({ isOpen, setIsOpen, onFinish }) => {
  const b = bem('need-consultation-form');
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleOnFinish = async (model) => {
    try {
      await onFinish(model);
      setIsOpen(false);
      setIsSuccess(true);
      setIsValid(false);
      form.resetFields();
    } catch {
      errorMessageCatcher({ message: 'Письмо не доставлено. Попробуйте позже' });
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const toHomePage = () => setIsSuccess(false);

  const handleOnChange = () => {
    form.validateFields().then(() => {
    }).catch((e) => (e.errorFields.length <= 0 ? setIsValid(true) : setIsValid(false)));
  };

  return (
    <>
      <Modal
        className={b('modal-width')}
        visible={isOpen}
        onCancel={handleOnClose}
        footer={false}
      >
        <div className={b('content-block')}>
          <p className="modal-title">Нужна консультация?</p>
          <Form
            onValuesChange={handleOnChange}
            autoComplete="off"
            initialValues={{ remember: false }}
            form={form}
            validateMessages={validateMessages}
            onFinish={(model) => handleOnFinish(model)}
          >
            <div className="main-content">

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Ваше имя
                  </span>
                </div>
                <Form.Item
                  className="full"
                  {...nameRules}
                  name="name"
                >
                  <InputV3
                    placeholder="Введите имя"
                  />
                </Form.Item>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Ваш email
                  </span>
                </div>
                <Form.Item
                  className="full"
                  {...emailRules}
                  name="email"
                >
                  <InputV3
                    placeholder="E-mail"
                  />
                </Form.Item>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Номер
                  </span>
                </div>
                <Form.Item
                  {...phoneRules}
                  className="full"
                  name="phone"
                >
                  <InputV3
                    mask="+7(000)000-00-00"
                    type="form"
                  />
                </Form.Item>
              </div>


              <div className="data-block">
                <div className="data-title">
                  <span>
                    Ваш вопрос
                  </span>
                </div>
                <Form.Item
                  name="text"
                >
                  <InputV3
                    textarea
                    autoSize={{ minRows: 3, maxRows: 4 }}
                    placeholder="Комментарий"
                  />
                </Form.Item>
              </div>

              <Form.Item
                rules={[
                  {
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Поле обязательно!'))),
                  },
                ]}
                className="checkbox-block"
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>
                  Согласен с условиями обработки
                  <a href={AcceptFile} target="_blank" rel="noreferrer">
                    персональных данных
                  </a>
                </Checkbox>
              </Form.Item>
            </div>

            <div className="footer-block">
              <ButtonV2 htmlType="submit" className={isValid ? 'button-valid' : 'button-disabled'} type="submit">
                Отправить
              </ButtonV2>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Каталог ПО"
        className={b('modal-success')}
        visible={isSuccess}
        footer={false}
        onCancel={toHomePage}
      >
        <div
          className={b('success-block')}
        >
          <i>
            <SuccessIcon />
          </i>
          <h4>Мы получили ваш запрос</h4>
          <span>В ближайшее время с Вами свяжется сотрудник Университета "Иннополис"</span>
          <div className="success-footer">
            <ButtonV2 onClick={toHomePage} type="primary">
              Отлично!
            </ButtonV2>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NeedConsultationModalForm;
