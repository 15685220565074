import React, { useEffect, useMemo, useState } from 'react';
import {
  Form, Modal, Select, Tooltip
} from 'antd';

import { InputV2, ButtonV2 } from 'components';

import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { getOneidUsers } from 'models/catalog/actions';

/* eslint-disable no-template-curly-in-string */
const stringRulesRequired = {
  rules: [{ type: 'string', required: true }],
};
const validateMessages = {
  required: 'Поле "${label}" обязательно!',
};
/* eslint-enable no-template-curly-in-string */

const DEBOUNCE_TIME = 300;
let debounce = null;

const AdminUserForm = ({
  initialValues: initialValuesProps,
  buttonText,
  onFinish: onFinishInput,
  open,
  onClose,
  requestLoading,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [disabledOkBtn, setDisableOkBtn] = useState(true);

  useEffect(() => {
    if (!open) {
      return;
    }
    dispatch(getOneidUsers({ page: 1, limit: 100 }));
  }, [open]);

  const [form] = Form.useForm();

  const oneidUsers = useSelector((state) => state.catalog.oneidUsers);
  const listFormattedOneidUsers = useMemo(
    () => oneidUsers.map((user) => ({
      value: user.id,
      key: user.id,
      label: `${user.sur_name} ${user.name} ${user.patronymic}`,
    })),
    [oneidUsers]
  );

  const handleOnUserSearch = (search) => {
    clearTimeout(debounce);

    debounce = setTimeout(() => {
      dispatch(getOneidUsers({ page: 1, limit: 100, email: search.trim() }));
    }, DEBOUNCE_TIME);
  };

  const handleOnChange = (fields) => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisableOkBtn(!!e.errorFields.length));
    if (!fields.user) {
      return;
    }

    const oneidUser = oneidUsers.find((user) => user.id === fields.user);
    form.setFields([{ name: 'email', value: oneidUser.email }]);
    form.setFields([{ name: 'workPlace', value: oneidUser?.work_place }]);
    form.setFields([{ name: 'role', value: 'Автор' }]);
  };

  const handleOnFinish = (model) => {
    onFinishInput(
      {
        user: model.user,
      },
      form
    );
  };

  return (
    <Modal
      title="Добавить автора"
      open={open}
      onCancel={onClose}
      footer={[
        <>
          <ButtonV2 onClick={onClose} disabled={requestLoading}>Отменить</ButtonV2>
          <Tooltip
            title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}
          >
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
                loading={requestLoading}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={initialValuesProps}
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        onValuesChange={handleOnChange}
        onFinish={handleOnFinish}
        {...rest}
      >
        <Form.Item {...stringRulesRequired} name="user" label="Пользователь">
          <Select
            className="form-select"
            size="large"
            showSearch
            filterOption={false}
            placeholder="Поиск по Email"
            options={listFormattedOneidUsers}
            onSearch={handleOnUserSearch}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <InputV2 disabled placeholder="Email" />
        </Form.Item>
        <Form.Item name="workPlace" label="Организация">
          <InputV2 disabled placeholder="Организация" />
        </Form.Item>
        <Form.Item name="role" label="Роль пользователя">
          <InputV2 disabled placeholder="Роль пользователя" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminUserForm;
