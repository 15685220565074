import { charactersValidation, valueValidation } from './form';


const rusCharCodesStart = 97;
const rusCharCodesEnd = 122;

const engCharCodesStart = 1072;
const engCharCodesEnd = 1103;

export const configuratorValueRegexp = (value) => {
  const someRusCharCodes = !value?.split('').map((x) => x.toLowerCase()).some((x) => (x.charCodeAt(0) >= rusCharCodesStart && x.charCodeAt(0) <= rusCharCodesEnd));
  const someEngCharCodes = !value?.split('').map((x) => x.toLowerCase()).some((x) => (x.charCodeAt(0) >= engCharCodesStart && x.charCodeAt(0) <= engCharCodesEnd));

  if (someRusCharCodes && someEngCharCodes) {
    return charactersValidation;
  }
  return valueValidation;
};

export const phoneMask = (phone) => {
  const mask = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
  return phone?.replace(mask, '+7 ($2) $3-$4-$5');
};
