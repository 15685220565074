import React, { useEffect, useMemo, useState } from 'react';
import {
  Form, Modal, Radio, Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import slug from 'slug';

import { ButtonV2, InputV2 } from 'components';

import { ICONS_CATEGORIES_MAP } from 'assets/icons/categories';
import { CATEGORIES_ICON_DEFAULT_SET } from 'constants/categories';
import { generateIconsList } from 'utils/generateIconsList';

import './style.less';
import { slugValidator } from 'utils/form';


const TITLE_LENGTH = 25;
const SLUG_LENGTH = 25;

const AdminCategoryForm = ({
  categories,
  buttonText,
  open,
  defaultValue,
  onClose,
  onFinish: onFinishInput,
  requestLoading,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [isSlugDisabled, setIsSlugDisabled] = useState(true);
  const [disabledOkBtn, setDisableOkBtn] = useState(!defaultValue);
  const iconsList = useMemo(
    () => generateIconsList(CATEGORIES_ICON_DEFAULT_SET, ICONS_CATEGORIES_MAP),
    []
  );

  useEffect(() => {
    if (defaultValue) {
      form.setFieldsValue({
        title: defaultValue.title,
        slug: defaultValue.slug,
        icon: defaultValue.icon,
      });
      form
        .validateFields()
        .then(() => {})
        .catch((e) => setDisableOkBtn(!!e.errorFields.length));
    }
  }, [form, defaultValue]);

  const handleTitleCheckChange = (e) => {
    if (!isSlugDisabled) {
      return;
    }

    const slugVlue = slug(e.target.value);
    form.setFieldsValue({
      slug: slugVlue,
    });
    form
      .validateFields(['slug'])
      .then(() => {})
      .catch((err) => setDisableOkBtn(!!err.errorFields.length));
  };

  const titleValidator = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Поле должно содержать текст'));
    }
    const similarCategories = categories.filter((x) => {
      if (value.trim() === '' || value.trim() === defaultValue?.title) {
        return false;
      }
      return x.title.trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase();
    });
    if (!/^(?=.*[A-Za-zА-Яа-я])([A-Za-zА-Яа-я0-9\W]+)$/.test(value)) {
      return Promise.reject(new Error('Поле может содержать только текст'));
    }
    if (similarCategories.length > 0) {
      return Promise.reject(
        new Error('Запись с таким наименованием уже создана')
      );
    }
    if (value.length > TITLE_LENGTH) {
      return Promise.reject(
        new Error(
          `Поле "название приложения" не должно содержать больше ${TITLE_LENGTH} символов`
        )
      );
    }
    return Promise.resolve();
  };

  const extraSlugValidator = (_, value) => {
    const similarCategories = categories.filter((x) => {
      if (value.trim() === '' || value.trim() === defaultValue?.title) {
        return false;
      }
      return x.slug.toLocaleLowerCase() === value.trim().toLocaleLowerCase();
    });

    if (similarCategories.length > 0) {
      return Promise.reject(
        new Error('Запись с таким наименованием уже создана')
      );
    }
    if (value.length > SLUG_LENGTH) {
      return Promise.reject(
        new Error(
          `Поле "технический псевдоним" не должно содержать больше ${SLUG_LENGTH} символов`
        )
      );
    }
    return Promise.resolve();
  };

  const handleOnFinish = (model) => {
    onFinishInput(model, form);
  };

  const handleCustomizeSlugClick = () => {
    setIsSlugDisabled(false);
  };

  const handleOnChange = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisableOkBtn(!!e.errorFields.length));
  };

  return (
    <Modal
      width={550}
      className="modal"
      title={`${defaultValue ? 'Редактировать' : 'Добавить'} категорию`}
      onCancel={onClose}
      open={open}
      footer={[
        <>
          <ButtonV2 onClick={onClose} disabled={requestLoading}>
            Отменить
          </ButtonV2>
          <Tooltip
            title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}
          >
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
                loading={requestLoading}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={defaultValue}
        layout="vertical"
        form={form}
        validateMessages={{ required: 'Обязательное поле' }}
        onValuesChange={handleOnChange}
        onFinish={handleOnFinish}
        {...rest}
      >
        <Form.Item
          rules={[{ required: true, validator: titleValidator }]}
          name="title"
          label="Название категории"
        >
          <InputV2
            maxLength={25}
            placeholder="Название"
            onChange={handleTitleCheckChange}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              validator: (...arg) => slugValidator(...arg, extraSlugValidator),
            },
          ]}
          name="slug"
          label={(
            <Tooltip title="Используется для URL адреса. Может содержать латиницу, цифры и тире">
              <span>
                Технический псевдоним&nbsp;
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          )}
        >
          <InputV2
            maxLength={SLUG_LENGTH}
            placeholder="example-name"
            disabled={isSlugDisabled}
            addonAfter={
              isSlugDisabled && (
                <ButtonV2 onClick={handleCustomizeSlugClick} type="link">
                  Задать свой
                </ButtonV2>
              )
            }
          />
        </Form.Item>
        <Form.Item
          rules={[{ type: 'string', required: true }]}
          name="icon"
          label="Выберите иконку"
        >
          <Radio.Group className="admin-categories-radio-group">
            {iconsList.map((item) => {
              const IconNew = item.icon;
              return (
                <Radio
                  className="admin-categories-radio-option"
                  value={item.value}
                  key={item.key}
                >
                  <IconNew />
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminCategoryForm;
