import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApplications,
  getApplicationsStatistic,
  getApplicationsStatisticOrganizations,
  getVendors,
} from 'models/catalog/actions';
import {
  Card, DatePicker, Form, Radio, Select, Space, Spin
} from 'antd';
import {
  ArrowDownOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import './style.less';
import { ButtonV2, Modal } from 'components';
import listFormatter from 'utils/antd-helpers/list-formatter';
import { getApplicationsStatisticReport } from 'models/catalog/api';
import { writeReportFile } from 'utils/xlsx/writeXlsx';
import moment from 'moment';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const AdminDashboardPane = () => {
  const dispatch = useDispatch();

  const [datesForm] = Form.useForm();
  const [reportForm] = Form.useForm();

  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isReportCustomDates, setIsReportCustomDates] = useState(true);

  const applicationsStatistic = useSelector(
    (state) => state.catalog.applicationsStatistic
  );
  const applicationsStatisticOrganizations = useSelector(
    (state) => state.catalog.applicationsStatisticOrganizations
  );
  const vendors = useSelector((state) => state.catalog.vendors);
  const applications = useSelector((state) => state.catalog.applications);

  const vendorsList = useMemo(() => listFormatter(vendors), [vendors]);
  const applicationsList = useMemo(
    () => listFormatter(applications),
    [applications]
  );
  const organizationsList = useMemo(
    () => applicationsStatisticOrganizations.map((organization) => ({
      value: organization.data.inn,
      key: organization.data.inn,
      label: organization.value,
    })),
    [applicationsStatisticOrganizations]
  );

  useEffect(() => {
    dispatch(getApplicationsStatistic());
    dispatch(getApplicationsStatisticOrganizations());
    dispatch(getVendors());
    dispatch(getApplications());
  }, [dispatch]);

  if (!applicationsStatistic) {
    return (
      <div
        className="admin-dashboard-pane__row"
        style={{ justifyContent: 'center' }}
      >
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  const handleOnDatesChange = () => {
    const { dateStart, dateEnd } = datesForm.getFieldsValue();

    dispatch(
      getApplicationsStatistic({
        dateStart: dateStart?.format('YYYY-MM-DD'),
        dateEnd: dateEnd?.format('YYYY-MM-DD'),
      })
    );
  };

  const handleOnReportChange = () => {
    const { isCustomDates } = reportForm.getFieldsValue();

    setIsReportCustomDates(isCustomDates);
  };

  const handleOnReportSubmit = async () => {
    const { isCustomDates, ...filter } = reportForm.getFieldsValue();
    filter.dateStart = isCustomDates
      ? filter.dateStart?.format('YYYY-MM-DD')
      : null;
    filter.dateEnd = isCustomDates
      ? filter.dateEnd?.format('YYYY-MM-DD')
      : null;

    const report = await getApplicationsStatisticReport(filter);

    filter.organization = organizationsList.find(
      (item) => item.value === filter.organizationId
    )?.label;
    filter.application = applicationsList.find(
      (item) => item.value === filter.applicationId
    )?.label;
    filter.vendor = vendorsList.find(
      (item) => item.value === filter.vendorId
    )?.label;
    writeReportFile(filter, report);
  };

  const handleOnReportClose = () => {
    reportForm.resetFields();
    setIsReportOpen(false);
  };

  const isDatesDisabled = (startDate, endDate, current) => {
    if (!startDate || !endDate) {
      return current > moment().endOf('day');
    }
    return startDate > endDate || current > moment().endOf('day');
  };

  return (
    <div className="admin-dashboard-pane">
      <div
        className="admin-dashboard-pane__row"
        style={{ justifyContent: 'space-between' }}
      >
        <Form
          form={datesForm}
          onValuesChange={handleOnDatesChange}
          className="filter"
        >
          <Space>
            <Form.Item name="dateStart" className="filter-item">
              <DatePicker
                disabledDate={(current) => isDatesDisabled(
                  current,
                  datesForm.getFieldsValue().dateEnd,
                  current
                )}
              />
            </Form.Item>
            <Form.Item name="dateEnd" className="filter-item">
              <DatePicker
                disabledDate={(current) => isDatesDisabled(
                  datesForm.getFieldsValue().dateStart,
                  current,
                  current
                )}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                datesForm.resetFields();
                handleOnDatesChange();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
        </Form>

        <ButtonV2
          type="link"
          icon={<ArrowDownOutlined />}
          onClick={() => setIsReportOpen(true)}
        >
          Сформировать отчёт
        </ButtonV2>
      </div>

      <div className="admin-dashboard-pane__row">
        <Card className="admin-dashboard-pane__row-item admin-dashboard-pane__card">
          <p className="admin-dashboard-pane__subtitle">
            Количество уникальных скачиваний приложений
          </p>
          <p className="admin-dashboard-pane__number">
            {applicationsStatistic?.uniqueDownloads}
          </p>
        </Card>
        <Card className="admin-dashboard-pane__row-item admin-dashboard-pane__card">
          <p className="admin-dashboard-pane__subtitle">Всего скачиваний ПО</p>
          <p className="admin-dashboard-pane__number">
            {applicationsStatistic?.totalDownloads}
          </p>
        </Card>
      </div>

      <div className="admin-dashboard-pane__row">
        <Card className="admin-dashboard-pane__row-item admin-dashboard-pane__card">
          <p className="admin-dashboard-pane__title">
            Наиболее востребованные вендоры (топ 10)
          </p>
          <div className="admin-dashboard-pane__table">
            <div className="admin-dashboard-pane__table-row">
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Наименование вендора
              </div>
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Количество приложений
              </div>
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Количество скачиваний
              </div>
            </div>
            {applicationsStatistic?.vendors?.map(({ vendor, count }) => (
              <div key={vendor?.id} className="admin-dashboard-pane__table-row">
                <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                  {vendor?.name}
                </div>
                <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                  {vendor?.applications?.length}
                </div>
                <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                  {count}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      <div className="admin-dashboard-pane__row">
        <Card className="admin-dashboard-pane__row-item admin-dashboard-pane__card">
          <p className="admin-dashboard-pane__title">
            Наиболее востребованное ПО (топ 5)
          </p>
          <div className="admin-dashboard-pane__table">
            <div className="admin-dashboard-pane__table-row">
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Наименование ПО
              </div>
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Наименование вендора
              </div>
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Количество скачиваний
              </div>
            </div>
            {applicationsStatistic?.applications?.map(
              ({ application, count }) => (
                <div
                  key={application?.id}
                  className="admin-dashboard-pane__table-row"
                >
                  <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                    {application?.title}
                  </div>
                  <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                    {application?.vendor?.name}
                  </div>
                  <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                    {count}
                  </div>
                </div>
              )
            )}
          </div>
        </Card>
      </div>

      <div className="admin-dashboard-pane__row">
        <Card className="admin-dashboard-pane__row-item admin-dashboard-pane__card">
          <p className="admin-dashboard-pane__title">
            Наиболее активные пользователи (ВУЗ, топ3)
          </p>
          <div className="admin-dashboard-pane__table">
            <div className="admin-dashboard-pane__table-row">
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Наименование ВУЗа
              </div>
              <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__subtitle">
                Количество скачиваний
              </div>
            </div>
            {applicationsStatistic?.organizations?.map((organization) => (
              <div
                key={organization.data.inn}
                className="admin-dashboard-pane__table-row"
              >
                <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                  {organization.value}
                </div>
                <div className="admin-dashboard-pane__table-row-item admin-dashboard-pane__table-body">
                  {}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      <Modal
        title="Сформировать отчет"
        visible={isReportOpen}
        onCancel={() => setIsReportOpen(false)}
        footer={[
          <>
            <ButtonV2 onClick={handleOnReportClose}>Отменить</ButtonV2>
            <ButtonV2 type="primary" onClick={handleOnReportSubmit}>
              Скачать отчет
            </ButtonV2>
          </>,
        ]}
        maskClosable
      >
        <div className="content">
          <Form
            layout="vertical"
            initialValues={{ isCustomDates: true }}
            form={reportForm}
            style={{ width: '100%' }}
            onValuesChange={handleOnReportChange}
          >
            <Form.Item name="isCustomDates">
              <Radio.Group>
                <Radio value>Выбрать даты</Radio>
                <Radio value={false}>За все время</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="admin-dashboard-pane__row" style={{ margin: 0 }}>
              <Form.Item
                name="dateStart"
                label="Дата начала"
                style={{ flex: '1', marginRight: '10px' }}
              >
                <DatePicker
                  disabled={!isReportCustomDates}
                  style={{ width: '100%' }}
                  disabledDate={(current) => isDatesDisabled(
                    current,
                    reportForm.getFieldsValue().dateEnd,
                    current
                  )}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
              <Form.Item
                name="dateEnd"
                label="Дата окончания"
                style={{ flex: '1' }}
              >
                <DatePicker
                  disabled={!isReportCustomDates}
                  style={{ width: '100%' }}
                  disabledDate={(current) => isDatesDisabled(
                    reportForm.getFieldsValue().dateStart,
                    current,
                    current
                  )}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </div>

            <Form.Item name="organizationId" label="Организация">
              <Select
                className="form-select"
                size="large"
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Выберите организацию"
                options={organizationsList}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>

            <Form.Item name="vendorId" label="Вендор">
              <Select
                className="form-select"
                size="large"
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Выберите вендора"
                options={vendorsList}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>

            <Form.Item name="applicationId" label="Приложение">
              <Select
                className="form-select"
                size="large"
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Выберите приложение"
                options={applicationsList}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AdminDashboardPane;
