import React from 'react';
import { Modal } from 'antd';
import bem from 'easy-bem';

import './style.less';


const OOCModal = ({ className, children, ...rest }) => {
  const b = bem('ooc-modal');

  const cn = className ? `${b()} ${className}` : b();

  return (
    <Modal className={cn} width="100%" {...rest}>
      {children}
    </Modal>
  );
};

export default OOCModal;
