import React from 'react';

import bem from 'easy-bem';
import moment from 'moment';

import './style.less';


const monthAgo = moment().subtract(1, 'month').unix();

const ApplicationTags = (props) => {
  const b = bem('info-block');

  const {
    date,
    badge,
    paymentType,
    promo,
    className: classNameProp,
  } = props;

  const dateUnix = moment(date).unix();
  const cn = classNameProp ? `${classNameProp} ${b()}` : b();

  const allBadges = [
    { type: 'free', condition: paymentType === 'free', text: 'бесплатно' },
    { type: 'special', condition: badge === 'special', text: 'специальное предложение' },
    { type: 'discount', condition: badge === 'discount', text: 'скидка' },
    { type: 'academic', condition: badge === 'academic', text: 'академические лицензии' },
    { type: 'new', condition: dateUnix >= monthAgo, text: 'новый' }
  ];

  const activeBadges = allBadges.filter((badge) => badge.condition);
  const badgeCount = activeBadges.length;
  const anotherBadgeCount = badgeCount - 2;
  const firstTwoBadges = activeBadges.slice(0, 2);

  return (
    <div className={cn}>
      {firstTwoBadges.map((badge, index) => (
        <div key={index} className={`${b(`${badge.type} tag`)}`}>
          <span>{badge.text}</span>
        </div>
      ))}
      {promo && (
        <div key={promo.key} className={`${b(`${promo?.type?.key} tag`)}`}>
          <span>{promo.type.value}</span>
        </div>
      )}
      {anotherBadgeCount > 0 && (
        <span className="badge-count">
          +
          {anotherBadgeCount}
        </span>
      )}
    </div>
  );
};

export default ApplicationTags;
