import React, { useMemo, useState, useEffect } from 'react';
import { Dropdown, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ICONS_CATEGORIES_MAP } from 'assets/icons/categories';
import { stageRu, tablePaginationOptions } from 'utils/consts';
import { getSortField } from 'utils/get-sort-field';
import { SortDropdown, getSortMenu } from 'components/sort-dropdown';


const AdminCategoriesPaneTable = ({
  items,
  params,
  getItems,
  totalCount,
  onSortParams,
  onChangeParams,
}) => {
  const [sortParams, setSortParams] = useState({});

  useEffect(() => {
    onSortParams(getSortField(sortParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParams]);

  const setSortParamsHandler = (field, sort) => {
    setSortParams((prev) => {
      const sortResult = prev[field] !== sort ? sort : null;

      return { [field]: sortResult };
    });
  };

  const onPaginationChange = ({ current: page, pageSize: limit }) => {
    onChangeParams({ page, limit });
  };

  const showTotal = (total, range) => {
    const allItemsText = `Всего записей: ${total}`;
    const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
    return (
      <div className="pagination-showTotal">
        <div>{allItemsText}</div>
        <div>{rangeItemsText}</div>
      </div>
    );
  };

  const paginationParams = useMemo(
    () => ({
      showTotal,
      total: totalCount,
      current: params.page,
      pageSize: params.limit,
      pageSizeOptions: tablePaginationOptions,
      showSizeChanger: true,
    }),
    [params.limit, params.page, totalCount]
  );

  const TITLE_SORT_MENU = getSortMenu({
    field: 'title',
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });

  const PUBLISHED_AT_SORT_MENU = getSortMenu({
    field: 'publishedAt',
    ascendLabel: 'Сначала старые',
    descendLabel: 'Сначала новые',
    onSort: setSortParamsHandler,
  });

  const APP_COUNT_SORT_MENU = getSortMenu({
    field: 'applicationsCount',
    ascendLabel: 'Сначала меньше',
    descendLabel: 'Сначала больше',
    onSort: setSortParamsHandler,
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Иконка',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => {
        const Icon = ICONS_CATEGORIES_MAP[icon];
        return <Icon className="gray" />;
      },
    },
    {
      title: () => (
        <div className="table-sort-header">
          Название категории
          <SortDropdown items={TITLE_SORT_MENU} order={sortParams.title} />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      key: 'stage',
      render: (stage) => {
        const cn = stage === 'published' ? 'green' : 'gray';
        return <span className={cn}>{stageRu[stage]}</span>;
      },
    },
    {
      title: () => (
        <div className="table-sort-header">
          Дата публикации
          <SortDropdown
            items={PUBLISHED_AT_SORT_MENU}
            order={sortParams.publishedAt}
          />
        </div>
      ),
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      render: (publishedAt, { stage }) => (stage === 'published' ? dayjs(publishedAt).format('DD.MM.YYYY') : '-'),
    },
    {
      title: () => (
        <div className="table-sort-header">
          Связанные приложения
          <SortDropdown
            items={APP_COUNT_SORT_MENU}
            order={sortParams.applicationsCount}
          />
        </div>
      ),
      dataIndex: 'applicationsCount',
      key: 'applicationsCount',
    },
    {
      width: 30,
      key: 'actions',
      render: (record, id) => (
        <Dropdown
          key={id}
          menu={{ items: getItems(record) }}
          trigger={['click']}
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const tableItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [items]);

  return (
    <Table
      columns={columns}
      total={totalCount}
      dataSource={tableItems}
      pagination={paginationParams}
      onChange={onPaginationChange}
    />
  );
};

export default AdminCategoriesPaneTable;
