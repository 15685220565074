import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
import bem from 'easy-bem';

import './style.less';


const OOCPaginationV2 = (props) => {
  const b = bem('ooc-pagination-v2');
  const { t } = useTranslation('pagination');

  const {
    total = 30,
    pageSize = 10,
    defaultPageSize = 10,
    currentPage = 1,
    onChangePage = () => {},
    data = [],
    setDataCallback = () => {},
    pageSizeOptions = ['20', '50', '100'],
    ...rest
  } = props;

  const getPageSizeOptions = () => {
    if (pageSizeOptions.length) {
      return pageSizeOptions;
    }

    if (total < defaultPageSize) {
      return [defaultPageSize];
    }

    const pageCount = Math.ceil(total / defaultPageSize);

    let step;
    const options = [];

    for (step = 0; step < pageCount; step += 1) {
      // варианты размеров страницы
      options.push(`${defaultPageSize * (step + 1)}`);
    }

    return options;
  };

  useEffect(() => {
    if (!data) return;

    let offset = currentPage > 0
      ? pageSize * (currentPage - 1)
      : currentPage;

    let end = offset + pageSize;
    if (end > data.length) {
      end = data.length;
    }

    const newData = [];

    for (offset; offset < end; offset += 1) {
      newData.push({ ...data[offset], key: offset });
    }

    setDataCallback(newData);
  }, [currentPage, data, pageSize, setDataCallback]);

  return (
    <div className={b()}>
      <span className={b('left-total')}>
        <span>
          {`Всего записей: ${total}`}
        </span>
      </span>

      <Pagination
        data-options-label={`${t('on-a-page')}:`}
        total={total}
        current={currentPage}
        pageSize={pageSize}
        showSizeChanger
        showTotal={
          (totalRec, range) => (
            `${range[0]}-${range[1]} ${t('from')} ${totalRec}`
          )
        }
        pageSizeOptions={getPageSizeOptions()}
        size="small"
        locale={{ items_per_page: '' }}
        onChange={onChangePage}
        {...rest}
      />
    </div>
  );
};

export default OOCPaginationV2;
