import buildQuery from 'odata-query';
import { get as oGet, post as oPost } from '../../utils/request';
import { removeToken } from '../../utils/auth';


const HEADERS = { 'Content-Type': 'application/json' };
const API = '/odata/Persons';


/** OData */

export const logoutUser = () => removeToken();

export const removeUserOrgOData = () => {
  const url = `${API}/RemoveOrganization`;

  return oPost(url, null, HEADERS);
};

export const getUserInfoOdata = () => {
  const url = `${API}/Current()`;

  const select = ['Id', 'Name', 'Surname', 'MiddleName'];

  const expand = {
    PersonDocuments: { select: ['DocumentType', 'Data'] },
    PersonContacts: {
      select: ['Data', 'ContactType'],
      expand: { ContactType: { select: ['Name'] } }
    },
    PersonPositionOrganizations: {
      select: ['Position', 'Organization'],
      expand: { Position: { select: ['Id', 'Name'] }, Organization: [] },
    }
  };

  const query = buildQuery({ select, expand });

  return oGet(`${url}${query}`);
};

export const setUserOrgOData = (orgId, positionId) => {
  const url = `${API}/SetOrganization`;

  const payload = `{
        "OrganizationId": "${orgId}",
        "PositionId": "${positionId}"
    }`;

  return oPost(url, payload, HEADERS);
};
