import React, { useMemo } from 'react';
import bem from 'easy-bem';

import './style.less';


const MAX_LETTERS = 2;

const modificatorClassNames = [
  'blue-light',
  'blue',
  'turquoise',
  'orange',
  'green',
];

const CardLogoBordered = ({ className: classNameProp, label }) => {
  const b = bem('card-logo-bordered');
  // const cn = classNameProp ? `${classNameProp} ${b()}` : b();

  const initials = useMemo(() => {
    if (!label) {
      return '';
    }

    const letters = label.split(' ').splice(0, MAX_LETTERS);
    return letters.map((item) => item[0]).join('').toUpperCase();
  }, [label]);

  const modificatorClass = useMemo(() => {
    const index = Math.floor(Math.random() * modificatorClassNames.length);
    return modificatorClassNames[index];
  }, []);

  return (
    <div className={`${b('inner')} ${b(`inner--${modificatorClass}`)}`}>
      <div className={`${b('circle')} ${b(`circle--${modificatorClass}`)}`}>
        {initials}
      </div>
    </div>
  );
};

export default CardLogoBordered;
