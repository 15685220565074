import React, { useContext, useEffect } from 'react';

import PrivateRoute from '../../components/private-route';
import { ACCESS_RIGHTS } from '../../utils/auth';
import { LimitedAccessModalContext } from '../../components/limited-access-modal';

import Page from './component';


const NoAccessComp = () => {
  const modal = useContext(LimitedAccessModalContext);

  useEffect(() => {
    modal.toggle();
  }, []);

  return null;
};


export default <PrivateRoute
  exact
  key="catalog-admin"
  path={[
    '/catalog-admin',
    '/catalog-admin/directory-managment',
    '/catalog-admin/user-managment',
    '/catalog-admin/promo-managment',
  ]}
  accessToRoles={ACCESS_RIGHTS['catalog-admin']}
  // noAccessFunc={() => message.error('У вас нет прав для просмотра этой страницы!', 10)}
  noAccessComponent={NoAccessComp}
  // noAccessComponent={<LoginForm />}
  component={Page}
/>;
