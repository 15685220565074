import bem from 'easy-bem';
import React from 'react';

import './style.less';
import { Modal } from 'antd';
import { ReactComponent as StarIcon } from 'assets/icons/starIconFilled.svg';


function* stepColorsGen() {
  const colors = ['#0071CE', '#7B61FF', '#40BA21'];
  const bgColors = ['#E5F5FF', '#E9E4FF', '#D7F4D0'];

  let index = 0;

  while (true) {
    yield { color: colors[index], backgroundColor: bgColors[index] };
    index = (index + 1) % colors.length;
  }
}

export const stepColors = stepColorsGen();

const FeedbackInfo = ({ feedback, open, onCancel }) => {
  const b = bem('feedback-info');

  if (!feedback || !open) return null;

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      title="Просмотр отзыва"
      width={548}
    >
      <div className={b()}>
        <div className={b('rating')}>
          {Array.from({ length: 5 }, (_, index) => {
            const starClass = (index < feedback.rating)
              ? 'star-filled'
              : 'star-empty';

            return (
              <StarIcon
                key={index}
                className={`star ${starClass}`}
              />
            );
          })}
        </div>
        <div className={b('authorName')}>
          <div className={b('infoField')}>
            <div className={b('infoField-label')}>Фамилия</div>
            <div className={b('infoField-value infoFieldInput')}>{feedback.authorLastname}</div>
          </div>
          <div className={b('infoField')}>
            <div className={b('infoField-label')}>Имя</div>
            <div className={b('infoField-value infoFieldInput')}>{feedback.authorFirstname}</div>
          </div>
        </div>
        {feedback.authorAvatarUrl && (
          <div className={b('avatar')}>
            <div className="img-wrapper">
              <img className="img" src={feedback.authorAvatarUrl} alt="avatar" />
            </div>
            <div className="img-info">
              <div className={b('infoField')}>
                <div className={b('infoField-label')}>Фото пользователя</div>
                <div className={b('infoField-value')}>{feedback.authorAvatar}</div>
              </div>
            </div>
          </div>
        )}
        {feedback.organization && (
          <div className={b('infoField')}>
            <div className={b('infoField-label')}>Организация</div>
            <div className={b('infoField-value infoFieldInput')}>{feedback.organization}</div>
          </div>
        )}
        {feedback.position && (
          <div className={b('infoField')}>
            <div className={b('infoField-label')}>Должность</div>
            <div className={b('infoField-value infoFieldInput')}>{feedback.position}</div>
          </div>
        )}
        <div className={b('infoField')}>
          <div className={b('infoField-label')}>Достоинства приложения</div>
          <div className={b('infoField-value infoFieldInput')}>{feedback.positiveQualities}</div>
        </div>
        {feedback.negativeQualities && (
          <div className={b('infoField')}>
            <div className={b('infoField-label')}>Недостатки приложения</div>
            <div className={b('infoField-value infoFieldInput')}>{feedback.negativeQualities}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackInfo;
