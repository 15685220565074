/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import userManager from '../../utils/auth-oidc';
import { actions } from '../../models';
import { setToken } from '../../utils/auth';


class CallbackComponent extends React.PureComponent {
  componentDidMount() {
    userManager.signinRedirectCallback()
      .then((user) => this.onRedirectSuccess(user))
      .catch((error) => this.onRedirectError(error));
  }
    
    onRedirectSuccess = (user) => {
      if (user) {
        this.props.saveOidcInfo(user);
        setToken(user.access_token);
        this.props.setUserData();

        userManager.startSilentRenew();
      }
      this.props.history.push('/');
    };
    
    onRedirectError = (error) => {
      console.error('auth callback error ', error);
      this.props.history.push('/');
    };
    
    render() {
      return (<div>Redirecting...</div>);
    }
}

const mdtp = ({
  saveOidcInfo: actions.saveOidcUserData,
  setUserData: actions.getUserInfoOdata
});

export default withRouter(connect(null, mdtp)(CallbackComponent));
