import React, { useMemo, useState, useEffect } from 'react';
import { Dropdown, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { stageRu, tablePaginationOptions } from 'utils/consts';
import { ROLE_ADMIN } from 'utils/auth';
import { getSortField } from 'utils/get-sort-field';
import { SortDropdown, getSortMenu } from 'components/sort-dropdown';
import dayjs from 'dayjs';


const AdminAdvertisingPaneTable = ({
  items,
  params,
  getItems,
  totalCount,
  onSortParams,
  onChangeParams,
  userRole,
}) => {
  const [sortParams, setSortParams] = useState({});

  useEffect(() => {
    onSortParams(getSortField(sortParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParams]);

  const setSortParamsHandler = (field, sort) => {
    setSortParams((prev) => {
      const sortResult = prev[field] !== sort ? sort : null;

      return { [field]: sortResult };
    });
  };

  const onPaginationChange = ({ current: page, pageSize: limit }) => {
    onChangeParams({ page, limit });
  };

  const showTotal = (total, range) => {
    const allItemsText = `Всего записей: ${total}`;
    const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
    return (
      <div className="pagination-showTotal">
        <div>{allItemsText}</div>
        <div>{rangeItemsText}</div>
      </div>
    );
  };

  const paginationParams = {
    showTotal,
    total: totalCount,
    current: params.page,
    pageSize: params.limit,
    pageSizeOptions: tablePaginationOptions,
    showSizeChanger: true,
  };

  const TITLE_SORT_MENU = getSortMenu({
    field: 'title',
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });

  const CREATED_AT_SORT_MENU = getSortMenu({
    field: 'publishedAt',
    ascendLabel: 'Сначала старые',
    descendLabel: 'Сначала новые',
    onSort: setSortParamsHandler,
  });

  const columns = [
    {
      title: 'ID записи',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: () => (
        <div className="table-sort-header">
          Название подборки
          <SortDropdown items={TITLE_SORT_MENU} order={sortParams.title} />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип спец. предложения',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type?.value,
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      key: 'stage',
      render: (stage) => {
        const cn = stage === 'published' ? 'green' : 'gray';
        return <span className={cn}>{stageRu[stage]}</span>;
      },
    },
    {
      title: () => (
        <div className="table-sort-header">
          Дата пубилкации
          <SortDropdown
            items={CREATED_AT_SORT_MENU}
            order={sortParams.publishedAt}
          />
        </div>
      ),
      dataIndex: 'publishedAt',
      key: 'publishedAt',
      render: (publishedAt, { stage }) => (stage === 'published' ? dayjs(publishedAt).format('DD.MM.YYYY') : '-'),
    },
    {
      title: 'Связанные приложения',
      dataIndex: 'publishedAppsCount',
      key: 'publishedAppsCount',
    },
  ];

  if (userRole === ROLE_ADMIN) {
    columns.push({
      width: 30,
      render: (record) => (
        <Dropdown
          menu={{
            items: getItems(record),
          }}
          trigger={['click']}
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    });
  }

  const tableItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [items]);

  return (
    <Table
      columns={columns}
      total={totalCount}
      dataSource={tableItems}
      pagination={paginationParams}
      onChange={onPaginationChange}
    />
  );
};

export default AdminAdvertisingPaneTable;
