import CryptoJS from 'crypto-js';


const ACCESS_TOKEN = 'utfa_access_token';
const REFRESH_TOKEN = 'utfa_refresh_token';
const ACCESS_DATA = 'utfa_data';


export const ROLE_ADMIN = 'admin';
export const ROLE_AUTHOR = 'author';
export const ROLE_USER = 'user';

export const ROLES_LIST = [
  {
    value: ROLE_ADMIN,
    label: 'Администратор'
  },
  {
    value: ROLE_AUTHOR,
    label: 'Автор'
  },
  {
    value: ROLE_USER,
    label: 'Пользователь'
  }
];

export const ROLES = [
  ROLE_ADMIN,
  ROLE_AUTHOR,
  ROLE_USER,
];

export const ACCESS_RIGHTS = {
  'catalog-admin': [ROLE_ADMIN, ROLE_AUTHOR]
};

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const setToken = (token) => localStorage.setItem(ACCESS_TOKEN, token);
export const setRefreshToken = (token) => (
  localStorage.setItem(REFRESH_TOKEN, token)
);
export const removeToken = () => localStorage.removeItem(ACCESS_TOKEN);

const SLT = '1654031068423';

export const setAccessData = (userInfo) => {
  try {
    const cryptString = CryptoJS.AES.encrypt(JSON.stringify(userInfo), SLT).toString();

    localStorage.setItem(ACCESS_DATA, cryptString);

    return cryptString;
  } catch (e) {
    console.error(e);
  }
};

export const getAccessData = () => {
  const cryptString = localStorage.getItem(ACCESS_DATA);

  if (cryptString) {
    try {
      const decryptString = CryptoJS.AES.decrypt(cryptString, SLT);

      const decryptedData = JSON.parse(decryptString.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

export const removeAccessData = () => localStorage.removeItem(ACCESS_DATA);

export const checkToken = () => {
  const user = localStorage.getItem(ACCESS_TOKEN);

  if (user !== '') {
    return user;
  }
  return null;
};

export const getUserRole = () => {
  const userInfo = getAccessData();

  if (userInfo && userInfo.role && ROLES.includes(userInfo.role)) {
    return userInfo.role;
  }

  return ROLE_USER;
};

export const checkUserRole = (role) => {
  if (role) {
    const userInfo = getUserRole();

    if (userInfo) {
      return userInfo.role === role;
    }
  }

  return false;
};


export const checkUserRoles = (roles) => {
  if (roles) {
    const userRole = getUserRole();

    if (userRole) {
      return roles.includes(userRole);
    }
  }

  return false;
};


