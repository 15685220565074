import React, { useMemo } from 'react';
import { Form, Space, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { getVendors, getCategories } from 'models/catalog/actions';
import { stageOptions } from 'utils/consts';
import { ButtonV2 } from 'components';
import { DebounceSelect } from 'components/debounce-select';


const AdminAppsPaneFilters = ({ vendors, categories, filterForm, onFilter, onReset }) => {
  const dispatch = useDispatch();

  const handleSearchVendor = (name) => {
    dispatch(getVendors({ name }));
  };

  const handleSearchCategories = (title) => {
    dispatch(getCategories({ title }));
  };

  const onSelectChange = (name, option) => {
    const value = option?.value ? option.value : option ?? null;

    onFilter({ page: 1, [name]: value });
  };

  const vendorsOptions = useMemo(() => {
    if (!vendors) {
      return [];
    }

    return vendors.map((vendor) => ({ label: vendor.name, value: vendor.id }));
  }, [vendors]);

  const categoriesOptions = useMemo(() => {
    if (!categories) {
      return [];
    }

    return categories.map((category) => ({ label: category.title, value: category.id }));
  }, [categories]);

  return (
    <Space className="w-full">
      <Form.Item name="vendorId" className="filter-item filter-select">
        <DebounceSelect
          placeholder="Выберите вендора"
          fetchOptions={handleSearchVendor}
          onChange={(val) => onSelectChange('vendorId', val)}
          options={vendorsOptions}
        />
      </Form.Item>
      <Form.Item name="categoryId" className="filter-item filter-select">
        <DebounceSelect
          placeholder="Выберите категорию"
          fetchOptions={handleSearchCategories}
          onChange={(val) => onSelectChange('categoryId', val)}
          options={categoriesOptions}
        />
      </Form.Item>
      <Form.Item name="stage" className="filter-item filter-select">
        <Select
          popupClassName="admin-select-dropdown"
          placeholder="Выберите статус"
          options={stageOptions}
          onChange={(val) => onSelectChange('stage', val)}
        />
      </Form.Item>
      <ButtonV2
        icon={<CloseOutlined />}
        type="link"
        className="filter-resetButton"
        onClick={() => {
          filterForm.resetFields();
          onReset();
        }}
      >
        Сбросить фильтр
      </ButtonV2>
    </Space>
  );
};

export default AdminAppsPaneFilters;
