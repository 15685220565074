import {
  Form, message, Select, Tooltip
} from 'antd';
import { ButtonV2, InputV2 } from 'components';
import React, { useState } from 'react';
import { configuratorKeysOptions } from 'utils/consts';
import { post as oPost } from 'utils/request';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';

import { useDispatch } from 'react-redux';
import { getConfiguratorOptions } from 'models/catalog/actions';

import './style.less';
import { CheckOutlined } from '@ant-design/icons';


export const createConfigurator = (data) => {
  const url = '/configurator';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const stringRulesRequired = {
  rules: [
    {
      type: 'string',
      required: true,
      validator: (_, value) => {
        if (value?.trim()) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Поле может содержать только текст'));
      }
    },
  ],
};

const AdminConfiguratorForm = ({
  setIsModalVisible, form, update, requestLoading, startRequest
}) => {
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const handleOnFinish = async (model) => {
    try {
      startRequest(true);
      await createConfigurator(model);
      message.success({
        content: 'Запись добавлена',
        icon: <CheckOutlined />,
      });
      form.resetFields();
      setIsModalVisible(false);
      update();
      dispatch(getConfiguratorOptions());
      startRequest(false);
    } catch (e) {
      setErrorMessage(e.message);
      startRequest(false);
    }
  };

  const handleOnChange = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setIsValid(!!e.errorFields.length));
    setErrorMessage('');
  };

  return (
    <Form
      onValuesChange={handleOnChange}
      autoComplete="off"
      form={form}
      onFinish={handleOnFinish}
    >
      <Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Поле "Ключ" обязательно!',
            },
          ]}
          name="key"
          label="Ключ"
        >
          <Select
            className="form-select"
            size="large"
            showSearch
            placeholder="Выберите ключ"
            options={configuratorKeysOptions}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>

        <Form.Item {...stringRulesRequired} name="value" label="Значение">
          <InputV2
            className={errorMessage && 'ant-input-status-error'}
            type="string"
            placeholder="Введите значение"
            maxLength={30}
          />
        </Form.Item>
        <span className="similar-error">{errorMessage}</span>
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={isValid || errorMessage ? 'Заполните все обязательные поля' : ''}>
          <span>
            <ButtonV2
              type="primary"
              htmlType="submit"
              disabled={isValid || errorMessage}
              className={isValid || errorMessage ? 'invalid-button' : ''}
              loading={requestLoading}
            >
              Сохранить
            </ButtonV2>
          </span>
        </Tooltip>
      </div>
    </Form>
  );
};

export default AdminConfiguratorForm;
