export const configuratorKeys = {
  territoryActivation: 'Территория активации',
  operationSystem: 'Операционная система',
  language: 'Язык',
  nomination: 'Наименование компонента поставки',
  complectsType: 'Тип носителя',
  unit: 'Единица измерения времени',
  special: 'Специальное предложение',
  tag: 'Тег'
};

export const configuratorKeysOptions = Object.entries(configuratorKeys).map(([value, label]) => ({ label, value }));

export const REACT_APP_BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST || 'https://apps-back-shareplace.unionepro.ru';

export const stageRu = {
  published: 'Опубликован',
  draft: 'Черновик',
  archived: 'В архиве',
};

export const stageOptions = [
  { label: stageRu.published, value: 'published' },
  { label: stageRu.draft, value: 'draft' },
  // { label: stageRu.archived, value: 'archived' },
];

export const tablePaginationOptions = [20, 50, 100];
