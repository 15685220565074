import React from 'react';
import { CloseOutlined } from '@ant-design/icons';


const ResetOption = () => (
  <div>
    <CloseOutlined
      style={{
        width: '12px',
        height: '12px,'
      }}
    />
    {' '}
    Сбросить
  </div>
);

export const getSortMenu = ({
  field,
  onSort,
  ascendLabel,
  descendLabel,
}) => [
  {
    key: '1',
    label: ascendLabel,
    onClick: () => onSort(field, 'ascend')
  },
  {
    key: '2',
    label: descendLabel,
    onClick: () => onSort(field, 'descend')
  },
  {
    key: '3',
    label: ResetOption(),
    onClick: () => onSort(field, null)
  },
];
