import bem from 'easy-bem';
import React, { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import { FeedbackInfo } from 'components';

import './style.less';


function* stepColorsGen() {
  const colors = ['#0071CE', '#7B61FF', '#40BA21'];
  const bgColors = ['#E5F5FF', '#E9E4FF', '#D7F4D0'];

  let index = 0;

  while (true) {
    yield { color: colors[index], backgroundColor: bgColors[index] };
    index = (index + 1) % colors.length;
  }
}

export const stepColors = stepColorsGen();

const Review = ({ review }) => {
  const [open, setOpen] = useState(false);
  const b = bem('review');

  const {
    authorAvatarUrl: avatar, authorFirstname, authorLastname, publishedAt, positiveQualities, negativeQualities
  } = review;

  const author = `${authorLastname} ${authorFirstname}`;

  const getAvatarDummy = useCallback(
    (author) => author.split(' ').slice(0, 2).map((str) => str.slice(0, 1).toUpperCase()).join(''),
    []
  );

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const getStepColor = useMemo(() => {
    if (avatar) {
      return {};
    }
    return stepColors.next().value;
  }, [avatar]);

  const positiveClamp = negativeQualities ? 'clamp3' : 'clamp4';
  return (
    <>
      <div className={b()} onClick={openModal}>
        <div className={b('content')}>
          <div className={b('content__header')} style={getStepColor}>
            {avatar
              ? <img className="avatar" src={avatar} alt="avatar" />
              : <div className="avatar-empty">{getAvatarDummy(author)}</div>}
          </div>
          <div className={b('content__body')}>
            <h5 className="author">{author}</h5>
            <div className={`review-text positive ${positiveClamp}`}>{positiveQualities}</div>
            <div className="review-text negative">{negativeQualities || ''}</div>
            <div className="date">{moment(publishedAt).format('DD MMMM YYYY')}</div>
          </div>
        </div>
      </div>
      <FeedbackInfo feedback={review} open={open} onCancel={closeModal} />
    </>
  );
};

export default Review;
