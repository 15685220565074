/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import bem from 'easy-bem';
import { TabPane, Tabs } from 'components';

import { getUserRole, ROLE_ADMIN } from 'utils/auth';

import './style.less';
import { ReactComponent as UserIcon } from 'assets/icons/admin-pane/user.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/admin-pane/chat.svg';
import AdminAuthorsPane from './components/admin-authors-pane';
import AdminReviewsPane from './components/admin-reviews-pane';


const AdminUserManagment = () => {
  const b = bem('admin-panels');
  const [activeTab, setActiveTab] = useState('authors');

  const userRole = getUserRole();

  useEffect(() => {
    if (userRole !== ROLE_ADMIN) {
      setActiveTab('feedback');
    }
  }, [userRole]);

  return (
    <div className={b()}>
      <h2 className={b('title')}>Управление пользователями</h2>
      <div className="catalog-content">
        <Tabs onChange={setActiveTab}>
          {userRole === ROLE_ADMIN && (
            <TabPane
              tab={(
                <div className="tabWithIcon">
                  <UserIcon />
                  <span>Авторы</span>
                </div>
              )}
              key="authors"
            >
              {activeTab === 'authors' && <AdminAuthorsPane />}
            </TabPane>
          )}
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <ChatIcon />
                <span>Отзывы</span>
              </div>
            )}
            key="feedback"
          >
            {activeTab === 'feedback' && <AdminReviewsPane />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminUserManagment;
