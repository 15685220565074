import React from 'react';
import bem from 'easy-bem';
import Input from '../input';

import './style.less';


const OOCFormItem = (props) => {
  const b = bem('ooc-form-item');
  const {
    label,
    value,
    ...rest
  } = props;

  return (
    <div className={b()}>
      <div className="label">{label}</div>
      <div className="value">
        <Input value={value} {...rest} />
      </div>
    </div>
  );
};

export default OOCFormItem;
