import React, { useEffect, useState } from 'react';
import { Form, Upload, message } from 'antd';
import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import { getToken } from 'utils/auth';
import { post as oPost } from 'utils/request';


const token = getToken();
const { Dragger } = Upload;

export const uploadFile = (data) => {
  const url = '/upload';

  return oPost(
    url,
    data,
    {
      'content-type': 'application/json',
      accept: 'application/json',
      Authorization: token,
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const FileUploader = ({
  initialSrc, maxSize, onChange, accept = '.jpg,.png', type = 'picture', format = '.jpg,.png', ...formProps
}) => {
  const [localFile, setFile] = useState();
  const [localFileList, setFileList] = useState([]);

  useEffect(() => {
    onChange && onChange(localFile);
  }, [localFile]);

  useEffect(() => {
    const data = initialSrc
      ? [
        {
          uid: '1',
          name: type === 'picture' ? 'Изображение' : 'Файл',
          status: 'done',
          url: initialSrc,
          thumbUrl: initialSrc,
        },
      ]
      : [];

    setFileList(data);
  }, [initialSrc]);

  const handleUploadChange = ({ file, fileList }) => {
    if (file.status === 'error') {
      setFileList([]);
      setFile('');
      return;
    }
    if (file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        localStorage.setItem('uploadedFile', e.target.result);
      };
      reader.readAsDataURL(file.originFileObj);
      setFile(file.response[0]?.url);
    }
    if (file.status === 'removed') {
      setFile('');
    }
    if (file.status) {
      setFileList(fileList);
    }
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < maxSize;
    const acceptArray = accept.split(',').map((el) => el.trim());
    const isAcceptedType = acceptArray.some((el) => {
      if (el.startsWith('.')) {
        return file.name.endsWith(el);
      }
      return file.type === el;
    });
  
    if (!isAcceptedType) {
      message.error({
        content: `Файл должен быть одного из следующих типов: ${accept}`,
        icon: <CloseOutlined />,
      });
      return false;
    }
  
    if (maxSize && !isLt2M) {
      message.error({
        content: `Файл не должен превышать ${maxSize} МБ`,
        icon: <CloseOutlined />,
      });
      return false;
    }
  };

  const customUpload = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('uploadFile', file);

    fetch(`${TEMP_SOFTWARE_CATALOG_API}/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((result) => {
        if (response.status === 200) {
          onSuccess(result);
        } else {
          onError(result?.message);
        }
      });
    });
  };

  return (
    <Form.Item {...formProps}>
      <Dragger
        customRequest={customUpload}
        headers={{
          Authorization: token,
        }}
        fileList={localFileList}
        onChange={handleUploadChange}
        beforeUpload={beforeUpload}
        accept={accept}
        maxCount={1}
        listType={type}
      >
        <div className="file_wrapper">
          <div className="file_icon">
            <PaperClipOutlined />
          </div>
          <div className="file_info">
            <div>
              Переместите файл сюда или
              {' '}
              <span style={{ color: '#0071CE' }}>выберите файл</span>
            </div>
            <p>
              Формат
              {' '}
              {format}
              {maxSize && ` не более ${maxSize} MB`}
            </p>
          </div>
        </div>
      </Dragger>
    </Form.Item>
  );
};

export default FileUploader;
