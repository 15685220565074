import { getConstant } from 'utils/constant-factory';

// TODO: getUsers - move to separate admin model
export const getUsers = getConstant('GET_USERS');

export const getVendors = getConstant('GET_VENDORS');
export const getPaginationVendors = getConstant('GET_PAGINATION_VENDORS');
export const getVendorById = getConstant('GET_VENDOR_BY_ID');
export const getApplications = getConstant('GET_APPLICATIONS');
export const getPaginationApplications = getConstant('GET_PAGINATION_APPLICATION');
export const getApplicationById = getConstant('GET_APPLICATION_BY_ID');
export const getApplicationsByVendorId = getConstant('GET_APPLICATION_BY_VENDOR_ID');
export const getPaginationApplicationsByVendorId = getConstant('GET_PAGINATION_APPLICATION_BY_VENDOR');
export const getCategories = getConstant('GET_CATEGORIES');
export const getPaginationCategories = getConstant('GET_PAGINATION_CATEGORIES');
export const getCategoryById = getConstant('GET_CATEGORY_BY_ID');
export const getFeedbacks = getConstant('GET_FEEDBACKS');
export const getAuthors = getConstant('GET_AUTHORS');
export const getPaginationAuthors = getConstant('GET_PAGINATION_AUTHORS');
export const getPaginationFeedbacks = getConstant('GET_PAGINATION_FEEDBACKS');
export const getOneidUsers = getConstant('GET_ONEID_USERS');
export const getApplicationsStatistic = getConstant('GET_APPLICATIONS_STATISTIC');
export const getApplicationsStatisticOrganizations = getConstant('GET_APPLICATIONS_STATISTIC_ORGANIZATIONS');
export const getApplicationsWithAnalog = getConstant('GET_APPLICATIONS_WITH_ANALOG');
export const getApplicationsWithAnalogSet = getConstant('GET_APPLICATIONS_WITH_ANALOG_SET');
export const getApplicationsTypes = getConstant('GET_APPLICATIONS_TYPES');
export const getSidebarVisible = getConstant('GET_SIDEBAR_VISIBLE');
export const getApplicationsCount = getConstant('GET_APPLICATIONS_COUNT');
export const getConfigurator = getConstant('GET_CONFIGURATOR');
export const getPaginationConfigurator = getConstant('GET_PAGINATION_CONFIGURATOR');
export const getConfiguratorOptions = getConstant('GET_CONFIGURATOR_OPTIONS');
export const getPromos = getConstant('GET_PROMOS');
export const getPaginationPromos = getConstant('GET_PAGINATION_PROMOS');
export const getPromosById = getConstant('GET_PROMOS_BY_ID');
export const getAuthorsOrganization = getConstant('GET_AUTHORS_ORGANIZATION');
export const getVendorRating = getConstant('GET_VENDOR_RATING');
export const getApplicationFeedbacks = getConstant('GET_APPLICATION_FEEDBACKS');
