import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import bem from 'easy-bem';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as MarkedItemsIcon } from 'assets/icons/widget-alt-light.svg';
import { ICONS_CATEGORIES_MAP } from 'assets/icons/categories';

import {
  getApplicationsCount,
} from 'models/catalog/actions';

import TagComponent from '../tag-component/components';
import CatalogSidebarItemsSkeleton from './skeleton';

import './style.less';


const CatalogSidebar = () => {
  const b = bem('catalog-sidebar');
  const { t } = useTranslation('catalog');
  const categories = useSelector((state) => state.catalog.categories);
  const isSidebarVisible = useSelector(
    (state) => state.catalog.isSidebarVisible
  );
  const appCount = useSelector((state) => state.catalog.applicationsCount);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const searchCategory = new URLSearchParams(location.search).get('category');

  useEffect(() => {
    dispatch(getApplicationsCount());
  }, []);

  const showDetails = (category) => history.push(`/catalog/search?category=${category.slug}`);

  if (!categories?.length) {
    return <CatalogSidebarItemsSkeleton />
  }

  const sidebarItemCn = (category) => `${
    isSidebarVisible
      ? b('categories-menu-item')
      : b('categories-menu-item-hidden')
  } ${category === searchCategory ? b('categories-menu-item-active') : ''}`;

  return (
    <div className={b('categories')}>
      <div>
        {isSidebarVisible && (
          <h2 className={b('subtitle')}>{t('industry-categories')}</h2>
        )}

        <Menu className={b('categories-menu')} mode="vertical" theme="light">
          <div
            onClick={() => history.push('/catalog/search?category=all')}
            className={sidebarItemCn('all')}
          >
            <a>
              <i>
                <MarkedItemsIcon />
              </i>
              {isSidebarVisible && <span>Все приложения</span>}
            </a>
            {isSidebarVisible && <TagComponent>{appCount}</TagComponent>}
          </div>
          {categories.map((category) => {
            const CategoryIcon = category
              ? ICONS_CATEGORIES_MAP[category.icon]
              : SearchIcon;
            return (
              <div
                key={category.id}
                onClick={() => showDetails(category)}
                className={sidebarItemCn(category.slug)}
              >
                <a>
                  {CategoryIcon && (
                    <i>
                      <CategoryIcon />
                    </i>
                  )}
                  {isSidebarVisible && (
                    <span className={b('categories-menu-item-title')}>
                      {category.title}
                    </span>
                  )}
                </a>
                {isSidebarVisible && (
                  <TagComponent>{category.publishedAppsCount}</TagComponent>
                )}
              </div>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default CatalogSidebar;
