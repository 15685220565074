import React from 'react';
import bem from 'easy-bem';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import './style.less';


const RatingPanel = ({ rating }) => {
  const b = bem('rating-panel');


  const stars = Array.from({ length: 5 }, (_, index) => {
    const starClass = index < Math.floor(rating) ? 'star-filled' : 'star-empty';
    return <StarIcon key={index} className={starClass} />;
  });

  return (
    <div className={b()}>
      {stars}
      {rating > 0 && <p className={b('amount')}>{rating.toFixed(2)}</p>}
    </div>
  );
};

export default RatingPanel;
