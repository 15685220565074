import React from 'react';
import { Drawer } from 'antd';
import {
  ButtonV2
} from 'components';

import './styles.less';


const FilterDrawer = ({ children, onClose, visible }) => (
  <Drawer
    title="Фильтры"
    onClose={onClose}
    className="filter-drawer"
    destroyOnClose={true}
    width={266}
    visible={visible}
    placement="right"
  >
    {children}
    <ButtonV2
      className="filter-btn"
      type="primary"
      onClick={onClose}
    >
      Применить фильтры
    </ButtonV2>
  </Drawer>
);

export default FilterDrawer;
