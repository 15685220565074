import React from 'react';
import { ReactComponent as SortNone } from 'assets/icons/sort-none.svg';
import { ReactComponent as SortAsc } from 'assets/icons/sort-asc.svg';
import { ReactComponent as SortDesc } from 'assets/icons/sort-desc.svg';


const SortIcon = ({ order }) => {
  if (order === 'ascend') {
    return <SortAsc />;
  }

  if (order === 'descend') {
    return <SortDesc />;
  }

  return <SortNone />;
};

export default SortIcon;
