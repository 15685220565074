import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useSelector } from 'react-redux';

import {
  Dropdown, Modal, Spin, Table,
  message
} from 'antd';
import { CheckOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { getSortField } from 'utils/get-sort-field';
import { SortDropdown, getSortMenu } from 'components/sort-dropdown';
import { FeedbackInfo } from 'components';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import { stageRu, tablePaginationOptions } from 'utils/consts';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';

import { remove as oDelete, patch as oPatch, errorMessageCatcher } from 'utils/request';
import { getAccessData, getUserRole, ROLE_ADMIN } from 'utils/auth';


export const removeReview = (id) => {
  const url = `/feedbacks/${id}`;

  return oDelete(`${url}`, {
    accept: 'application/json',
  }, TEMP_SOFTWARE_CATALOG_API);
};

export const changeReviewStage = (id, stage) => {
  const formData = JSON.stringify({ stage });
  const url = `/feedbacks/${id}/stage`;
  
  return oPatch(`${url}`, formData, {
    'content-type': 'application/json',
    accept: 'application/json',
  }, TEMP_SOFTWARE_CATALOG_API);
};


const DEFAULT_CONFIRM_MODAL_CONFIG = {
  open: false,
  action: null,
  okText: 'Подтвердить',
  question: 'Вы уверены, что хотите совершить это действие?'
};

const DEFAULT_SHOW_MODAL_CONFIG = {
  open: false,
  feedback: null,
};

const APPLICATION_SORT_PARAM = 'application';
const AUTHOR_SORT_PARAM = 'fullName';
const REVIEW_DATE_SORT_PARAM = 'publishedAt';

const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const ReviewTable = ({
  modalActions: { setModalConfig, setModalOpen },
  onChange,
  onSortChange,
  pagination,
  tableUpdater
}) => {
  const [sortParams, setSortParams] = useState({});
  const [confirmModalConfig, setConfirmModalConfig] = useState(DEFAULT_CONFIRM_MODAL_CONFIG);
  const [showModalConfig, setShowModalConfig] = useState(DEFAULT_SHOW_MODAL_CONFIG);
  const [modalLoading, setModalLoading] = useState(false);

  const userRole = getUserRole();
  const user = getAccessData();

  const { loading, items: reviews, totalCount } = useSelector((state) => (state.catalog.paginationFeedbacks));

  // ** PAGINATION **//

  const showTotal = (total, range) => {
    const allItemsText = `Всего записей: ${total}`;
    const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
    return (
      <div className="pagination-showTotal">
        <div>{allItemsText}</div>
        <div>{rangeItemsText}</div>
      </div>
    );
  };

  const paginationOptions = useMemo(() => ({
    showTotal,
    total: totalCount,
    current: pagination.page,
    pageSize: pagination.limit,
    pageSizeOptions: tablePaginationOptions,
    showSizeChanger: true,
  }), [pagination.limit, pagination.page, totalCount]);

  const onPaginationChange = ({ current: page, pageSize: limit }) => {
    onChange({ page, limit });
  };

  // ** SORTING **//

  useEffect(() => {
    onSortChange(getSortField(sortParams));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParams]);

  const setSortParamsHandler = (field, sort) => {
    setSortParams((prev) => {
      const sortResult = prev[field] !== sort ? sort : null;

      return {
        ...prev,
        [field]: sortResult,
      };
    });
  };

  const TITLE_SORT_MENU = getSortMenu({
    field: APPLICATION_SORT_PARAM,
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });
  const AUTHOR_SORT_MENU = getSortMenu({
    field: AUTHOR_SORT_PARAM,
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });
  const CREATED_AT_SORT_MENU = getSortMenu({
    field: REVIEW_DATE_SORT_PARAM,
    ascendLabel: 'Сначала старые',
    descendLabel: 'Сначала новые',
    onSort: setSortParamsHandler,
  });

  // ** ACTIONS **//

  const changeStage = useCallback(async (reviewId, stage) => {
    setModalLoading(true);
    await changeReviewStage(reviewId, stage).then(() => {
      message.success({
        content: stage === 'draft' ? 'Отзыв снят с публикации' : 'Отзыв опубликован',
        icon: <CheckOutlined />
      });
      setConfirmModalConfig(DEFAULT_CONFIRM_MODAL_CONFIG);
      tableUpdater?.();
    }, errorMessageCatcher);
    setModalLoading(false);
  }, [tableUpdater]);

  const closeShowModal = () => {
    setShowModalConfig(DEFAULT_SHOW_MODAL_CONFIG);
  };

  // ** COLUMNS SETTINGS **//

  const getItems = (review) => {
    const isDraft = review.stage === 'draft';
    const hasAccess = userRole === ROLE_ADMIN || review.userCreator === user.id;

    const changeReviewStageRecordAction = () => {
      if (isDraft) {
        changeStage(review.id, 'published');
      } else {
        setConfirmModalConfig({
          open: true,
          action: () => changeStage(review.id, 'draft'),
          review,
          okText: 'Снять c публикации',
          question: 'Вы уверены, что хотите снять данный отзыв с публикации?'
        });
      }
    };

    const removeReviewRecordAction = () => {
      setConfirmModalConfig({
        open: true,
        action: async () => {
          setModalLoading(true);
          await removeReview(review.id).then(() => {
            message.success({
              content: 'Отзыв удалён',
              icon: <CheckOutlined />
            });
            setConfirmModalConfig(DEFAULT_CONFIRM_MODAL_CONFIG);
            tableUpdater?.();
          }, errorMessageCatcher);
          setModalLoading(false);
        },
        review,
        okText: 'Удалить',
        question: 'Вы уверены, что хотите удалить данный отзыв?'
      });
    };

    const showFeedbackInfo = () => {
      setShowModalConfig({
        open: true,
        feedback: review,
      });
    };

    const dropdownItems = [
      {
        key: '0',
        label: <div>Посмотреть</div>,
        onClick: showFeedbackInfo,
      },
      {
        key: '1',
        label: <div>Редактировать</div>,
        onClick: () => {
          setModalConfig({ edit: hasAccess, review });
          setModalOpen(true);
        },
        disabled: !hasAccess,
      },
      {
        key: '2',
        label: <div>{isDraft ? 'Опубликовать' : 'Снять с публикации'}</div>,
        onClick: changeReviewStageRecordAction,
        disabled: !hasAccess,
      },
      {
        key: '3',
        label: <div>Удалить</div>,
        onClick: removeReviewRecordAction,
        disabled: !hasAccess,
      }
    ];

    return dropdownItems;
  };

  const reviewsTableColumns = [
    {
      title: 'ID записи ',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: () => (
        <div className="table-sort-header">
          Приложения
          <SortDropdown
            items={TITLE_SORT_MENU}
            order={sortParams[APPLICATION_SORT_PARAM]}
          />
        </div>
      ),
      key: 'application',
      dataIndex: 'application',
      render: (app) => app.title,
    },
    {
      title: () => (
        <div className="table-sort-header">
          Автор отзыва
          <SortDropdown
            items={AUTHOR_SORT_MENU}
            order={sortParams[AUTHOR_SORT_PARAM]}
          />
        </div>
      ),
      key: 'author',
      render: (review) => `${review.authorLastname} ${review.authorFirstname.slice(0, 1)}.`,
    },
    {
      title: () => (
        <div className="table-sort-header">
          Дата отзыва
          <SortDropdown
            items={CREATED_AT_SORT_MENU}
            order={sortParams[REVIEW_DATE_SORT_PARAM]}
          />
        </div>
      ),
      key: 'createdAt',
      render: (item) => (item.stage === 'published' && item[REVIEW_DATE_SORT_PARAM]
        ? dayjs(item[REVIEW_DATE_SORT_PARAM]).format('DD.MM.YYYY')
        : '-'
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      key: 'stage',
      render: (stage) => {
        const cn = stage === 'published' ? 'green' : 'gray';
        return <span className={cn}>{stageRu[stage]}</span>;
      },
    },
    {
      title: 'Кем внесён',
      key: 'createdBy',
      render: (review) => `
        ${review?.creator?.user_info.passport.sur_name} ${review?.creator?.user_info.passport.name.slice(0, 1)}.
      `,
    },
    {
      title: '',
      key: 'reviewActions',
      render: (review) => (
        <Dropdown
          menu={{ items: getItems(review) }}
          trigger={['click']}
        >
          <EllipsisOutlined />
        </Dropdown>
      )
    }
  ];

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setConfirmModalConfig(DEFAULT_CONFIRM_MODAL_CONFIG);
  };

  return (
    <>
      <Spin indicator={loadingIcon} spinning={loading}>
        <Table
          columns={reviewsTableColumns}
          total={totalCount}
          dataSource={reviews}
          pagination={paginationOptions}
          onChange={onPaginationChange}
        />
        <Modal
          open={confirmModalConfig.open}
          okText={confirmModalConfig.okText || 'Подтвердить'}
          onOk={() => { confirmModalConfig?.action?.(); }}
          okButtonProps={{ loading: modalLoading }}
          cancelButtonProps={{ disabled: modalLoading }}
          onCancel={closeModal}
          title={confirmModalConfig.title || confirmModalConfig.okText}
          width={723}
        >
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                {confirmModalConfig.question}
              </p>
            </div>
          </div>
        </Modal>
        <FeedbackInfo {...showModalConfig} onCancel={closeShowModal} />
      </Spin>
    </>
  );
};

export default ReviewTable;
