/* eslint-disable max-len */
import moment from 'moment'; // TODO: remove moment
import jwtDecode from 'jwt-decode';


export const authToClient = ({
  AccessToken,
  // ExpiresIn,
  // IdToken,
  // NotBeforePolicy,
  // RefreshExpiresIn,
  // RefreshToken,
  // Scope,
  // SessionState,
  // TokenType,
}) => ({
  accessToken: AccessToken
});

export const userDataToClient = (jsonData, token) => {
  const {
    Id, Name, Surname, MiddleName, DateOfBirth, Gender,
    PassportId, SNILS, Telegram, Address, Email, Phone,
    // OrganizationId
    PersonPositionOrganizations // list
  } = jsonData;

  let userRole;
  let OrganizationId;
  try {
    const result = jwtDecode(token);
    userRole = result['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    const [org] = PersonPositionOrganizations; // first record
    OrganizationId = org.OrganizationId;
  } catch (e) {
    console.error(e);
  }

  return {
    id: Id,
    lastName: Surname,
    firstName: Name,
    middleName: MiddleName,
    dateOfBirth: DateOfBirth ? moment(DateOfBirth).format('DD.MM.YYYY') : null,
    gender: Gender,
    passportId: PassportId,
    snils: SNILS,
    telegram: Telegram,
    address: Address,
    email: Email,
    phone: Phone,
    organizationId: OrganizationId,
    userRole
  };
};

export const userDataToClientGQ = ({
  data: {
    users: {
      list: [{
        id,
        surname,
        name,
        familyName,
        organization,
        snils,
        email,
        phone,
        position,
        userroleSet
      }]
    }
  }
}) => ({
  id,
  lastName: surname,
  firstName: name,
  middleName: familyName,
  snils,
  email,
  phone,
  position,
  organization,
  orgParent: organization?.child?.length ? organization?.child[0].parent : '', // shows only one organization for now
  orgApp: organization?.consortiumApplicationOrganization ?? null,
  userroleSet
});

/** oData conversions */
const parseDocumentData = (record) => {
  let result;
  try { result = JSON.parse(record.Data); } catch (e) { result = record; }
  return result.Number ? result.Number : result;
};

export const adaptODataUserContactsList = (list) => (
  list.reduce((previous, current) => (
    { ...previous, [current.ContactType?.Name]: current.Data }), {})
);

export const adaptODataUserDocumentsList = (list) => (
  list.reduce((previous, current) => (
    { ...previous, [current.DocumentType]: parseDocumentData(current) }), {})
);

const adaptOdataUserPosition = ({ Id, Name, Description }) => ({
  id: Id,
  name: Name,
  description: Description
});

const adaptOdataUserOrganizations = ({
  Id, OGRN,
  FullName, ShortName, OrganizationType,
  InstitutionType, INN, KPP, OKTMO,
  OKTMOCode, BudgetLevel, OrganizationLevel,
  EditDate, SubjectId, ActualAddress,
  LegalAddress, ConsortiumId
}) => ({
  id: Id,
  ogrn: OGRN,
  fullName: FullName,
  shortName: ShortName,
  organizationType: OrganizationType,
  institutionType: InstitutionType,
  inn: INN,
  kpp: KPP,
  oktmo: OKTMO,
  oktmoCode: OKTMOCode,
  budgetLevel: BudgetLevel,
  organizationLevel: OrganizationLevel,
  editDate: EditDate,
  subjectId: SubjectId,
  actualAddress: ActualAddress,
  legalAddress: LegalAddress,
  consortiumId: ConsortiumId
});

const adaptOdataUserPosOrgsList = (list) => list.map((item) => ({
  position: adaptOdataUserPosition(item.Position),
  organization: adaptOdataUserOrganizations(item.Organization),
}));

export const adaptOdataUser = ({
  Id, Name, Surname, MiddleName,
  PersonDocuments, PersonContacts,
  PersonPositionOrganizations
}) => ({
  id: Id,
  name: Name,
  surname: Surname,
  middleName: MiddleName,
  personDocuments: adaptODataUserDocumentsList(PersonDocuments),
  personContacts: adaptODataUserContactsList(PersonContacts),
  personPositionOrganization: adaptOdataUserPosOrgsList(
    PersonPositionOrganizations
  ),
});

// export const userDataToClientOdata = (rawData) => rawData.map(adaptOdataUser);
