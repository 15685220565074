import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import MaskedInput from 'antd-mask-input';
import bem from 'easy-bem';

import './style.less';


const OOCInput = (props) => {
  const b = bem('ooc-input-v2');
  const {
    className: classNameProp,
    textarea = false,
    type = undefined,
    label = undefined,
    withPassword = false,
    name,
    required = false,
    validateStatus,
    help,
    mask,
    rules = [],
    ...rest
  } = props;

  const cn = classNameProp ? `${classNameProp} ${b()}` : b();

  if (textarea && label) {
    return (
      <div className={`${cn} ${b('textarea')}`}>
        <span>
          {label}
        </span>
        <Input.TextArea {...rest} />
      </div>
    );
  }

  if (textarea) {
    return (
      <Input.TextArea className={`${cn} ${b('textarea')}`} {...rest} />
    );
  }

  if (type === 'form' && label && !mask) {
    return (
      <div className={`${cn} ${b('form-input')} `}>
        <Form.Item
          name={name}
          label={label}
          required={required}
          validateStatus={validateStatus}
          help={help}
          rules={rules}
        >
          {
            withPassword
              ? <Input {...rest} type="password" />
              : <Input {...rest} />
          }
        </Form.Item>
      </div>
    );
  }

  if (type === 'form' && label && mask) {
    return (
      <div className={`${cn} ${b('form-input')} `}>
        <Form.Item
          name={name}
          label={label}
          required={required}
          validateStatus={validateStatus}
          help={help}
          rules={rules}
        >
          <MaskedInput mask={mask} {...rest} />
        </Form.Item>
      </div>
    );
  }

  if (label) {
    return (
      <div className={cn}>
        {label}
        <Input {...rest} />
      </div>
    );
  }

  if (type === 'number') {
    return (
      <InputNumber className={cn} {...rest} />
    );
  }

  return (
    <Input className={cn} {...rest} />
  );
};

export default OOCInput;
