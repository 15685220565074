let fileUrl;

export const getCroppedImg = (image, cropInput, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = cropInput.width;
  canvas.height = cropInput.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    cropInput.x * scaleX,
    cropInput.y * scaleY,
    cropInput.width * scaleX,
    cropInput.height * scaleY,
    0,
    0,
    cropInput.width,
    cropInput.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        // reject(new Error('Canvas is empty'));
        console.error('Canvas is empty');
        return;
      }
      // eslint-disable-next-line no-param-reassign
      blob.name = fileName;
      window.URL.revokeObjectURL(fileUrl);
      fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg');
  });
};
