import React from 'react';
import bem from 'easy-bem';
import CatalogAdmin from '../../components/catalog-admin';
import './style.less';


const CatalogAdminPage = () => {
  const b = bem('catalog-admin-page');

  return (
    <div className={b()}>
      <div className={b('content')}>
        <CatalogAdmin />
      </div>
    </div>
  );
};

export default CatalogAdminPage;
