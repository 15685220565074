import * as actions from './actions';
import * as constants from './constants';
import reducers from './reducers';
import options from './options';


const model = {
  actions,
  constants,
  reducers,
  options,
};

export default model;
