import React, { useRef, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import bem from 'easy-bem';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';

import { setIsMobile } from 'models/common/actions';
import { post as oPost } from 'utils/request';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import ScrollToTop from 'components/scroll-to-top';
import { LimitedAccessModalProvider } from 'components/limited-access-modal';
import PageWrapperMiddleware from './pageWrapperMiddleware';
import {
  removeToken,
  setToken,
  setAccessData,
  removeAccessData,
} from '../../utils/auth';

import './style.less';


export const authUser = (data) => {
  const url = '/auth';

  return oPost(
    `${url}`,
    data,
    {
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const PageWrapper = () => {
  const b = bem('page-wrapper');
  const contentRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMedia('(max-width: 768px)');


  /* Set local token and user BY global token
  or remove if global token not exist */
  React.useEffect(() => {
    if (window.unione) {
      window.unione.getUnioneToken().then((token) => {
        if (token) {
          setToken(token);
          authUser().then((resp) => {
            if (resp.user) {
              const {
                _id,
                role,
                status,
                name,
                patronymic,
                surname,
                email,
                moderation,
                phone,
              } = resp.user;

              setAccessData({
                id: _id,
                role,
                status,
                name,
                patronymic,
                surname,
                email,
                isModerated: !!moderation?.is_moderated,
                phone,
              });
            }
          });
        } else {
          removeToken();
          removeAccessData();
        }
      });
    }
  }, []);

  useEffect(() => dispatch(setIsMobile(isMobile)), [isMobile, dispatch]);

  return (
    <div className={b()}>
      <LimitedAccessModalProvider>
        <Router>
          <div className={b('container')}>
            <div ref={contentRef} className={b('main-content')}>
              <ScrollToTop />
              <PageWrapperMiddleware />
            </div>
          </div>
        </Router>
      </LimitedAccessModalProvider>
    </div>
  );
};

export default PageWrapper;
