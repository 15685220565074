import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Select, Spin
} from 'antd';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { post as oPost } from 'utils/request';
import {
  getVendors,
  getCategories,
  getApplications,
  getPromos,
  getPaginationVendors,
} from 'models/catalog/actions';

import { ReactComponent as BadgeCheck } from 'assets/icons/badge-check.svg';
import { ReactComponent as MarkedItemsIcon } from 'assets/icons/widget-alt-light.svg';
import { ReactComponent as SelectDownIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ArrowBottom } from 'assets/icons/arrow-bottom.svg';

import { DropdownSearch, PromoCard, ButtonV2 } from 'components';

import { ICONS_CATEGORIES_MAP } from 'assets/icons/categories';
import DeconstructedMobile from 'assets/images/catalog/deconstructed-mobile.png';
import RusAnalogImg from 'assets/images/catalog/rus-analog.png';
import AcademicLicensesImg from 'assets/images/catalog/academic-licenses.png';
import PaperClips from 'assets/images/catalog/paperclips.png';
import CardLogo from 'components/card-logo';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import VendorCard from '../vendor-card';
import VendorsCatalogForm from './form';

import './style.less';


const LOCAL_STORAGE_SEARCH_KEY = 'vendorsCatalog';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const searchTypes = {
  apps: {
    value: 'apps',
    label: 'Программное обеспечение',
  },
  vendors: {
    value: 'vendors',
    label: 'Вендоры',
  },
};

export const createStatement = (data) => {
  const url = '/consultation/send-statement';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const VendorsCatalog = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState(
    searchTypes.apps.value
  );
  const { Option } = Select;

  const history = useHistory();
  const dispatch = useDispatch();

  const allVendors = useSelector((state) => state.catalog.vendors);
  const { items, totalCount, loading } = useSelector((state) => state.catalog.paginationVendors);
  const applications = useSelector((state) => state.catalog.applications);
  const categories = useSelector((state) => state.catalog.categories);
  const appCount = useSelector((state) => state.catalog.applicationsCount);
  const promos = useSelector((state) => state.catalog.promos);
  const isMobile = useSelector((state) => state.common.isMobile);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1100);
  const [vendorsParams, setVendorsParams] = useState({ page: 1, limit: 10, stage: 'published' });
  const [vendors, setVendors] = useState([]);

  const promoBanners = useMemo(() => {
    const promosArr = [...promos].reverse().map((promo) => ({
      ...promo,
      imgUrl: isMobile ? promo.mobileBanner : promo.desktopBanner,
    }));
    const top = promosArr.slice(0, 2);
    const bottom = promosArr.slice(2, 4);
    const topCount = top.length + 1;
    const bottomCount = bottom.length + 1;
    return {
      top,
      bottom,
      topCount,
      bottomCount,
    };
  }, [promos, isMobile]);

  useEffect(() => {
    dispatch(getPaginationVendors(vendorsParams));
  }, [dispatch, vendorsParams]);

  useEffect(() => {
    if (vendorsParams.page > 1) {
      setVendors((prev) => ([...prev, ...items]));
    } else {
      setVendors(items);
    }
  }, [items]);

  useEffect(() => {
    dispatch(getPromos({ stage: 'published' }));
    dispatch(getVendors());
    dispatch(getApplications());
    dispatch(getCategories());
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1100);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const { t } = useTranslation('catalog');
  const b = bem('vendors-catalog');

  const applicationsListed = useMemo(
    () => applications.map((application) => ({
      value: application.slug,
      label: application.title,
      logo: application.mainImageUrl,
      vendor: application.vendor,
      analogs: application.analogs,
    })),
    [applications]
  );

  const vendorsListed = useMemo(
    () => allVendors.map((vendor) => ({
      value: vendor.slug,
      label: vendor.name,
      logo: vendor.logoUrl && !vendor.logoUrl.endsWith('null') ? vendor.logoUrl : null,
    })),
    [allVendors]
  );

  const handleSelectItem = (slug) => {
    if (selectedSearchType !== searchTypes.vendors.value) {
      const application = applications.find(
        (application) => application.slug === slug
      );
      history.push(`/catalog/${application.vendor.slug}/${slug}`);
    } else {
      const vendor = allVendors.find((vendor) => vendor.slug === slug);
      history.push(`/catalog/${vendor.slug}`);
    }
  };

  const handleSearchFilter = (input, { vendor, analogs }) => {
    const isVendorMatched = vendor?.name
      ?.toLowerCase()
      ?.includes(input.toLowerCase());
    const isAnalogsMatched = analogs?.find((analog) => analog.name.toLowerCase().includes(input.toLowerCase()));
    return isVendorMatched || isAnalogsMatched;
  };

  const handleChangeSearchValue = (searchValue) => {
    setSearchValue(searchValue);
  };

  const showPartnerModal = () => setIsOpen(true);

  const onAddStatementForm = async (model) => {
    await createStatement(model);
  };

  const handleCategoryChange = (value) => {
    categories.map((item) => {
      if (item.title === value) {
        history.push(`/catalog/search?category=${item.slug}`);
      }
    });
  };

  const searchOptions = useMemo(() => {
    if (selectedSearchType === searchTypes.vendors.value) {
      return vendorsListed;
    }
    return applicationsListed;
  }, [vendorsListed, selectedSearchType, applicationsListed]);

  return (
    <div className={b()}>
      <div className="catalog-content">
        <div className={b('search-block')}>
          <h2 className="title">Каталог программного обеспечения</h2>

          <div className="categories-mobile-block">
            <Select
              suffixIcon={<ArrowBottom />}
              className="category-select-mobile"
              defaultValue="Категория приложений"
              style={{ width: 120 }}
              onChange={handleCategoryChange}
            >
              <Option value="Все приложения">
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => history.push('/catalog/search?category=all')}
                >
                  <i className="icon-category-block">
                    <MarkedItemsIcon className="gray" />
                  </i>
                  <span className="select-title">
                    Все приложения
                    {' '}
                    <span className="app-category-count">{`(${appCount})`}</span>
                  </span>
                </div>
              </Option>
              {categories.map((category) => {
                const CategoryIcon = ICONS_CATEGORIES_MAP[category.icon];
                return (
                  <Option key={category.id} value={category.title}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="icon-category-block">
                        <CategoryIcon className="gray" />
                      </i>
                      <span className="select-title">
                        {category.title}
                        {' '}
                        <span className="app-category-count">
                          {`(${category.applicationsCount})`}
                        </span>
                      </span>
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>

          <div className="search-input">
            <Select
              suffixIcon={isLargeScreen ? <SelectDownIcon /> : <ArrowBottom />}
              size="large"
              className="search-type"
              onChange={setSelectedSearchType}
              defaultValue={selectedSearchType}
              popupClassName="search-input__dropdown"
            >
              <Option value={searchTypes.apps.value} className="search-input__options">
                {searchTypes.apps.label}
              </Option>
              <Option value={searchTypes.vendors.value} className="search-input__options">
                {searchTypes.vendors.label}
              </Option>
            </Select>

            <div className="divide-border" />

            <DropdownSearch
              size="large"
              localStorageKey={LOCAL_STORAGE_SEARCH_KEY}
              options={searchOptions}
              onFilter={handleSearchFilter}
              onChangeSearchValue={handleChangeSearchValue}
              onSelectItem={handleSelectItem}
              placeholder="Поиск по названию"
            >
              {searchOptions.map(({
                value, label, logo, vendor, analogs
              }) => (
                <Select.Option
                  key={value}
                  value={value}
                  label={label}
                  vendor={vendor}
                  analogs={analogs}
                >
                  {logo ? (
                    <img
                      alt="option-logo"
                      src={logo}
                      className="ooc-dropdown-search__option-img"
                    />
                  ) : (
                    <CardLogo
                      className="ooc-dropdown-search__option-img"
                      label={label}
                    />
                  )}
                  <div className="software-search__option-label">
                    <span>{label}</span>
                    <span className="software-search__option-label__subtite">
                      {vendor?.name}
                    </span>
                  </div>
                  {analogs?.find((analog) => analog.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) && (
                    <div className="software-search__option-suffix">
                      <BadgeCheck />
                      Аналог
                    </div>
                  )}
                </Select.Option>
              ))}
            </DropdownSearch>
          </div>

          <img className="search-img" src={DeconstructedMobile} alt="" />
        </div>

        <div className={b('promos')}>
          {promoBanners.top.map((banner) => (
            <Link
              key={banner.id}
              className="banner-link"
              to={`/catalog/search?category=all&special=true&specialOfferId=${banner.type.id}`}
            >
              <div className="banner-wrapper">
                <img src={banner.imgUrl} alt={banner.type.value} />
              </div>
            </Link>
          ))}
          <Link to="/catalog/search?category=all&academic=true">
            <PromoCard
              label="Академические лицензии"
              icon={<img src={AcademicLicensesImg} alt="" style={{ width: '100%' }} />}
              type="gradient-blue"
            />
          </Link>
          <Link to="/catalog/analogs">
            <PromoCard
              label="Российские аналоги"
              icon={<img src={RusAnalogImg} alt="" style={{ width: '100%' }} />}
              type="gradient-blue-light"
            />
          </Link>
        </div>

        <div className={b('list')}>
          <div className={b('list-header')}>
            <h2 className="subtitle">{t('vendors')}</h2>
          </div>

          <Spin spinning={loading} indicator={loadingIcon}>
            {vendors.length > 0 ? (
              <>
                <Row gutter={[{
                  xl: 32, lg: 16, md: 16, sm: 16, xs: 16
                }, {
                  xl: 32, lg: 16, md: 16, sm: 16, xs: 16
                }]}
                >
                  {vendors.map((vendor) => (
                    <Col
                      key={vendor.name}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                      xl={{ span: 12 }}
                    >
                      <VendorCard {...vendor} />
                    </Col>
                  ))}
                </Row>
              
                {vendors?.length < totalCount && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 24,
                    }}
                  >
                    <ButtonV2
                      onClick={() => setVendorsParams((prev) => ({
                        ...prev,
                        page: prev.page + 1,
                      }))}
                      type="link"
                      style={{
                        padding: '7px 16px',
                        borderRadius: 10,
                        background: '#FFFFFF',
                      }}
                    >
                      <RedoOutlined
                        style={{ transform: 'rotate(-100deg)' }}
                      />
                      Показать еще 10 вендоров из
                      {' '}
                      {totalCount}
                    </ButtonV2>
                  </div>
                )}
              </>
            ) : (
              <p>Вендоров не найдено</p>
            )}
          </Spin>
        </div>

        <div className={b('footer-promos')}>
          <h2 className={b('footer-promos__subtitle')}>
            Специальные предложения
          </h2>
          <div className={b('promos')}>
            {promoBanners.bottom.map((banner) => (
              <Link
                className="banner-link"
                to={`/catalog/search?category=all&specialOfferId=${banner.type.id}`}
              >
                <div className="banner-wrapper">
                  <img src={banner.imgUrl} alt={banner.type.value} />
                </div>
              </Link>
            ))}
            <div
              style={{ flexGrow: 1, flexBasis: '25%' }}
              className={b('special-block special-block__specialColorPartner')}
              onClick={showPartnerModal}
            >
              <div className="description-block">
                <p>Как стать партнёром каталога ПО?</p>
              </div>
              <div className="special-block-icon">
                <img className="app-laptop" src={PaperClips} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={b('mod')}>
          <VendorsCatalogForm
            onFinish={onAddStatementForm}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorsCatalog;
