import { ReactComponent as GeneralPurposeSoftware } from 'assets/icons/categories/generalPurposeSoftware.svg';
import { ReactComponent as UrbanEconomy } from 'assets/icons/categories/urbanEconomy.svg';
import { ReactComponent as ExtractiveIndustry } from 'assets/icons/categories/extractiveIndustry.svg';
import { ReactComponent as Healthcare } from 'assets/icons/categories/healthcare.svg';
import { ReactComponent as InformationTechnology } from 'assets/icons/categories/informationTechnology.svg';
import { ReactComponent as Education } from 'assets/icons/categories/education.svg';
import { ReactComponent as ManufacturingIndustry } from 'assets/icons/categories/manufacturingIndustry.svg';
import { ReactComponent as Agriculture } from 'assets/icons/categories/agriculture.svg';
import { ReactComponent as Construction } from 'assets/icons/categories/construction.svg';
import { ReactComponent as Transport } from 'assets/icons/categories/transport.svg';
import { ReactComponent as Finance } from 'assets/icons/categories/finance.svg';
import { ReactComponent as Energy } from 'assets/icons/categories/energy.svg';

// DEPRECATED ICONS
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as MonitorIcon } from 'assets/icons/monitor.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { ReactComponent as MortarboardIcon } from 'assets/icons/mortarboard.svg';
import { ReactComponent as PodiumIcon } from 'assets/icons/home-light.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { ReactComponent as ReduceIcon } from 'assets/icons/wallet-alt-light.svg';
import { ReactComponent as MedicalKitIcon } from 'assets/icons/medical-kit.svg';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as ToolIcon } from 'assets/icons/tool.svg';
import { ReactComponent as ConicalFlaskIcon } from 'assets/icons/conical-flask.svg';
import { ReactComponent as BoltIcon } from 'assets/icons/bolt.svg';
import { ReactComponent as MarkedItemsIcon } from 'assets/icons/widget-alt-light.svg';
import DeconstructedMobile from 'assets/images/catalog/deconstructed-mobile.png';


export const ICONS_CATEGORIES_MAP_DEPRECATED = {
  SearchIcon,
  MonitorIcon,
  FlashIcon,
  MortarboardIcon,
  PodiumIcon,
  ImageIcon,
  ReduceIcon,
  MedicalKitIcon,
  CarIcon,
  ToolIcon,
  ConicalFlaskIcon,
  BoltIcon,
  MarkedItemsIcon,
  DeconstructedMobile,
};

export const ICONS_CATEGORIES_MAP = {
  GeneralPurposeSoftware,
  UrbanEconomy,
  ExtractiveIndustry,
  Healthcare,
  InformationTechnology,
  Education,
  ManufacturingIndustry,
  Agriculture,
  Construction,
  Transport,
  Finance,
  Energy,
  ...ICONS_CATEGORIES_MAP_DEPRECATED,
};
