import {
  Form, Input, Select, Space
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { getApplications, getVendors } from 'models/catalog/actions';

import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import { stageOptions } from 'utils/consts';
import ButtonV2 from '../../../../../../button-v2';

import '../style.less';


const DEFAULT_FILTERS = {
  fullName: '',
  application: '',
  vendor: '',
  stage: 'draft,published'
};

const Filters = ({ onChange, actions }) => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filterSearch, setFilterSearch] = useState();
  const [skipDebounce, setSkipDebounce] = useState(true);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getApplications());
    dispatch(getVendors());
  }, [dispatch]);

  useEffect(() => {
    if (!filters) return;

    onChange?.(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const applications = useSelector(
    (state) => state.catalog.applications
  );
  const vendors = useSelector(
    (state) => state.catalog.vendors
  );

  const applicationsList = useMemo(
    () => applications.map((app) => ({
      value: app.id,
      label: app.title,
    })),
    [applications]
  );

  const vendorsList = useMemo(
    () => vendors.map((vendor) => ({
      value: vendor.id,
      label: vendor.name,
    })),
    [vendors]
  );

  const resetForm = () => {
    form.resetFields();
    setFilters(DEFAULT_FILTERS);
  };

  const filtersChange = (filterName, filterValue) => {
    setFilters({
      ...filters,
      [filterName]: filterValue
    });
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      filtersChange('fullName', filterSearch);
    },
    500,
    [filterSearch]
  );


  return (
    <div className="reviews-filters-wrapper">
      <div className="actions">
        <Space>
          {actions.map((action) => <span key={action.title}>{action.component}</span>)}
        </Space>
      </div>

      <Form form={form} className="reviews-filters">
        <Form.Item name="fullName" className="filter-search-name">
          <Input
            className="name-search"
            placeholder="Поиск по ФИО"
            suffix={<SearchIcon className="gray" />}
            onChange={(e) => setFilterSearch(e.target.value)}
          />
        </Form.Item>

        <div className="row">
          <Form.Item
            name="app"
            className="filter-item filter-select"
          >
            <Select
              placeholder="Приложение"
              popupClassName="admin-select-dropdown"
              options={applicationsList}
              onChange={(value) => filtersChange('application', value)}
            />
          </Form.Item>

          <Form.Item
            name="vendor"
            className="filter-item filter-select"
          >
            <Select
              placeholder="Вендор"
              popupClassName="admin-select-dropdown"
              options={vendorsList}
              onChange={(value) => filtersChange('vendor', value)}
            />
          </Form.Item>

          <Form.Item
            name="status"
            className="filter-item filter-select"
          >
            <Select
              placeholder="Статус"
              popupClassName="admin-select-dropdown"
              options={stageOptions}
              onChange={(value) => filtersChange('stage', value)}
            />
          </Form.Item>

          <ButtonV2
            icon={<CloseOutlined />}
            type="link"
            className="filter-resetButton"
            onClick={resetForm}
          >
            Сбросить фильтр
          </ButtonV2>
        </div>
      </Form>
    </div>
  );
};

export default Filters;
