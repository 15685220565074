/* eslint-disable no-underscore-dangle */
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  message,
  Modal,
  Row,
  Input,
  Spin,
  Space,
  Select,
  Form,
} from 'antd';
import {
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';

import { ButtonV2 } from 'components';
import { getPaginationPromos, getConfiguratorOptions } from 'models/catalog/actions';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import { ROLE_ADMIN, getAccessData, getUserRole } from 'utils/auth';
import {
  post as oPost,
  remove as oDelete,
  put as oPut,
  errorMessageCatcher,
} from 'utils/request';
import { useDebounce } from 'react-use';
import { stageOptions } from 'utils/consts';
import AdminPromosForm from './form';
import AdminAdvertisingPaneTable from './table';

import './style.less';

/* TEMP create functions */
export const createPromo = (data) => {
  const url = '/promos';

  return oPost(
    url,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const updatePromo = (data, id) => {
  const url = `/promos/${id}`;

  return oPut(
    url,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const deletePromo = (id) => {
  const url = `/promos/${id}`;

  return oDelete(
    url,
    {
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
      accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
  stage: 'draft,published',
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const AdminAdvertisingPane = () => {
  const dispatch = useDispatch();

  const [filterForm] = Form.useForm();
  const userRole = getUserRole();
  const user = getAccessData();

  const [modalConfig, setModalConfig] = useState({ modalOpen: false });
  const [removeConfig, setRemoveConfig] = useState({ open: false });
  const [changesCounter, setChangesCounter] = useState(0);
  const [searchTitle, setSearchTitle] = useState('');
  const [promosParams, setPromosParams] = useState(DEFAULT_PARAMS);
  const [skipDebounce, setSkipDebounce] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const { items, loading, totalCount } = useSelector((state) => state.catalog.paginationPromos);
  const configuratorOptions = useSelector(
    (state) => state.catalog.configuratorOptions
  );

  useEffect(() => {
    dispatch(getConfiguratorOptions());
  }, [dispatch]);

  const selectOptions = useMemo(() => {
    const options = {};
    configuratorOptions?.map((option) => {
      option.value.reduce((initialArray, configurator) => {
        initialArray?.push({
          value: configurator.id,
          label: configurator.value,
        });
        options[option.key] = initialArray;
        return initialArray;
      }, []);
    });
    return options;
  }, [configuratorOptions]);

  useEffect(() => {
    dispatch(getPaginationPromos(promosParams));
  }, [dispatch, changesCounter, promosParams]);

  const onAddPromosForm = async (model) => {
    setModalLoading(true);
    await createPromo(model).then(() => {
      message.success({
        content: 'Запись добавлена',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setModalConfig({ openModal: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onEditPromosForm = async (model, id) => {
    setModalLoading(true);
    await updatePromo(model, id).then(() => {
      message.success({
        content: 'Запись обновлена',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setModalConfig({ openModal: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const handleDeletePromoClick = async (id) => {
    setModalLoading(true);
    await deletePromo(id).then(() => {
      message.success({
        content: 'Запись удалена',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setRemoveConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const openModal = (defaultValue) => {
    setModalConfig({ modalOpen: true, defaultValue });
  };

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setModalConfig({ modalOpen: false });
  };
  

  const changeStage = useCallback(({
    id,
    desktopBanner,
    mobileBanner,
    title,
    type,
    stage
  }) => {
    const data = {
      desktopBanner,
      mobileBanner,
      title,
      type: type.id,
      stage: stage === 'draft' ? 'published' : 'draft',
    };
    onEditPromosForm(data, id);
  }, []);

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setPromosParams((prev) => ({ ...prev, page: 1, title: searchTitle }));
    },
    500,
    [searchTitle]
  );

  const getItems = useCallback(
    (item) => {
      const publishItemText = item.stage === 'draft' ? 'Опубликовать' : 'Снять с публикации';
      const hasAccess = userRole === ROLE_ADMIN || item.userCreator === user.id;
      const dropdownItems = [
        {
          key: '1',
          label: <div>Редактировать</div>,
          disabled: !hasAccess,
          onClick: () => openModal(item),
        },
        {
          key: '2',
          label: <div>{publishItemText}</div>,
          disabled: !hasAccess,
          onClick: () => changeStage(item),
        },
      ];

      if (item.stage !== 'published') {
        dropdownItems.push({
          key: '4',
          label: <div>Удалить</div>,
          disabled: !hasAccess,
          onClick: () => setRemoveConfig({ open: true, promo: item }),
        });
      }
      return dropdownItems;
    },
    [changeStage, user.id, userRole],
  );

  const setPromosSortHandler = (sort) => {
    setPromosParams((prev) => ({ ...prev, sort }));
  };

  const onPaginationChange = (params) => {
    setPromosParams((prev) => ({ ...prev, ...params }));
  };

  const onStageChange = (stage) => {
    setPromosParams((prev) => ({ ...prev, stage, page: 1 }));
  };

  const resetFields = () => {
    setPromosParams(DEFAULT_PARAMS);
  };

  const onFinish = (model) => {
    if (modalConfig.defaultValue) {
      const preparedData = { ...model, stage: modalConfig.defaultValue.stage };
      onEditPromosForm(preparedData, modalConfig.defaultValue.id);
    } else {
      const preparedData = { ...model, stage: 'draft' };
      onAddPromosForm(preparedData);
    }
  };

  const removeCancel = () => {
    if (modalLoading) {
      return;
    }
    setRemoveConfig({ open: false });
  };

  return (
    <div className="admin-form admin-vendors-pane">
      <Row justify="space-between" align="middle" gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Form form={filterForm} className="promo-filter filter">
          <Space>
            <Form.Item name="title" className="filter-item filter-search">
              <Input
                placeholder="Поиск по названию"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item name="stage" className="filter-item filter-select">
              <Select
                placeholder="Выберите статус"
                popupClassName="admin-select-dropdown"
                options={stageOptions}
                onChange={onStageChange}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                filterForm.resetFields();
                resetFields();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
          {userRole === ROLE_ADMIN && (
            <ButtonV2
              type="link"
              icon={<PlusOutlined />}
              onClick={() => openModal()}
            >
              Добавить новую запись
            </ButtonV2>
          )}
        </Form>

        {modalConfig.modalOpen && (
          <AdminPromosForm
            specialOptions={selectOptions.special}
            {...modalConfig}
            onClose={closeModal}
            onFinish={onFinish}
            requestLoading={modalLoading}
          />
        )}
      </Row>

      <Spin spinning={loading} indicator={loadingIcon}>
        <AdminAdvertisingPaneTable
          items={items}
          params={promosParams}
          getItems={getItems}
          totalCount={totalCount}
          onSortParams={setPromosSortHandler}
          onChangeParams={onPaginationChange}
          userRole={userRole}
        />
      </Spin>

      <Modal
        open={removeConfig.open}
        okText="Удалить"
        onCancel={removeCancel}
        title="Удалить запись"
        footer={[
          <>
            <ButtonV2 onClick={removeCancel} disabled={modalLoading}>
              Отменить
            </ButtonV2>
            <ButtonV2
              type="primary"
              onClick={() => handleDeletePromoClick(removeConfig.promo.id)}
              loading={modalLoading}
            >
              Удалить
            </ButtonV2>
          </>,
        ]}
      >
        {removeConfig.promo && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                Вы уверены, что хотите удалить подборку "
                {removeConfig.promo.title}
                "?
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdminAdvertisingPane;
