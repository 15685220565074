import React from 'react';
import bem from 'easy-bem';
import './style.less';
import { Link } from 'react-router-dom';
import { CardLogo } from 'components';
import TagComponent from '../tag-component/components';


const VendorCard = ({
  name,
  shortDescription,
  logo,
  logoUrl,
  slug,
  ...other
}) => {
  const b = bem('vendor-card');

  return (
    <Link to={`/catalog/${slug}`} className={b()}>
      <div className="card-preview">
        {logo && logoUrl && !logoUrl.endsWith('null') ? (
          <img className="preview-image" loading="lazy" crossOrigin="anonymous" alt="Превью" src={logoUrl} />
        ) : (
          <CardLogo className="preview-logo" label={name} />
        )}
      </div>
      <div className="card-content">
        <div className="card-content__wrap">
          <div className="card-content__header">
            <h5 className="content-name">
              {name}
            </h5>
            <TagComponent>{other.publishedAppsCount}</TagComponent>
          </div>
          <div className="content-description">
            <p
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: shortDescription }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default VendorCard;
