import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Modal, Upload, message
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as PaperClipIcon } from 'assets/icons/paper-clip.svg';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useDebounce } from 'react-use';
import { canvasPreview } from 'components/upload-cropper';

import './style.less';


const { Dragger } = Upload;

const FileCrop = ({
  onChange,
  defaultFileList,
  completedCrop,
  setCompletedCrop,
  setImageRealSize,
  refresh,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [fileList, setFileList] = useState(defaultFileList);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [preparedList, setPreparedList] = useState([]);

  useEffect(() => {
    onChange && onChange(fileList);
  }, [fileList]);

  useEffect(() => {
    if (refresh !== 0) {
      setFileList([]);
    }
  }, [refresh]);

  const handleModalOk = () => {
    setIsModalOpened(false);
    setImgSrc('');
    setFileList(preparedList);
  };

  const handleModalCancel = () => {
    setIsModalOpened(false);
    setImgSrc('');
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsModalOpened(true);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageSrcLoaded = (e) => {
    imgRef.current = e.currentTarget;

    const {
      width, height, naturalWidth, naturalHeight
    } = e.currentTarget;

    const scaleX = naturalWidth / width;
    const scaleY = naturalHeight / height;

    setImageRealSize({
      scaleX,
      scaleY,
      naturalWidth,
      naturalHeight,
    });

    // This is to demonstate how to make and center a % aspect crop
    // which is a bit trickier so we use some helper functions.
    const cropParams = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 50,
          height: 50,
        },
        1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(cropParams);
  };

  useDebounce(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imgRef.current
        && previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < rest.maxSize;
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error({
        content: 'Файл должен быть изображением',
        icon: <CloseOutlined />,
      });
      setFileList([]);
    }
    if (rest.maxSize && !isLt2M) {
      message.error({
        content: `Файл не должен превышать ${rest.maxSize}MB`,
        icon: <CloseOutlined />,
      });
      setFileList([]);
    }
    if (isImage && (isLt2M || !rest.maxSize)) {
      onSelectFile({ target: { files: [file] } });
    }
    return false;
  };

  return (
    <>
      <Form.Item {...rest}>
        <Dragger
          fileList={fileList}
          accept="image/*"
          maxCount={1}
          beforeUpload={beforeUpload}
          listType="picture"
          onChange={(e) => {
            if (e.file.status === 'removed') {
              setFileList(e.fileList);
            } else {
              setPreparedList(e.fileList);
            }
          }}
        >
          <div className="file_wrapper">
            <div className="file_icon">
              <PaperClipIcon />
            </div>
            <div className="file_info">
              <div>
                Переместите файл сюда или
                {' '}
                <span style={{ color: '#0071CE' }}>выберите файл</span>
              </div>
              <p>
                Формат .jpg,.png
                {' '}
                {rest.maxSize && `не более ${rest.maxSize} MB`}
              </p>
            </div>
          </div>
        </Dragger>
      </Form.Item>

      <Modal
        maskClosable={false}
        visible={isModalOpened}
        okText="Применить"
        cancelText="Закрыть"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {Boolean(imgSrc) && (
          <ReactCrop
            crop={crop}
            ruleOfThirds
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img alt="Crop me" src={imgSrc} onLoad={onImageSrcLoaded} />
          </ReactCrop>
        )}
        <div>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop?.width || 0,
              height: completedCrop?.height || 0,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default FileCrop;
