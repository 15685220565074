import bem from 'easy-bem';

import './style.less';


const SelectCatalog = ({ options, handleChange, selectedItem }) => {
  const b = bem('select-catalog');

  return (
    <div className={b('catalog-content')}>
      {options.map((item) => {
        const isActive = item.value === selectedItem.value;
        return (
          <button
            key={item.value}
            onClick={() => handleChange(item)}
            className={
              isActive
                ? b('catalog-content-button', { selected: true })
                : b('catalog-content-button')
            }
          >
            <span>{item.label}</span>
          </button>
        );
      })}
    </div>
  );
};

export default SelectCatalog;
