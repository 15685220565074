import React from 'react';
import { Typography, Dropdown } from 'antd';
import SortIcon from './sortIcon';
import './style.less';


export const SortDropdown = ({ items, order }) => (
  <Dropdown
    menu={{ items }}
    dropdownRender={(menu) => (
      <div className="admin-applications-pane-sort-dropdown">
        <p className="admin-applications-pane-sort-dropdown__title">
          Сортировать
        </p>
        {React.cloneElement(menu, { className: 'admin-applications-pane-sort-dropdown__menu' })}
      </div>
    )}
  >
    <Typography.Link>
      <SortIcon order={order} />
    </Typography.Link>
  </Dropdown>
);
