import React, { useEffect, useMemo, useState } from 'react';
import bem from 'easy-bem';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/main-search-icon.svg';
import { ReactComponent as SearchIconNotFound } from 'assets/icons/search-alt-light.svg';

import { CardLogo } from 'components';

import './style.less';


const START_SEARCH_COUNT = 0;
const SAVED_OPTION_VALUE = 'SAVED_OPTION_VALUE';
const LOCAL_STORAGE_SAVED_OPTIONS_KEY = 'savedOptions';
const MAX_SAVED_OPTIONS_COUNT = 3;
const CONTAINER_CLASS = 'container-class';

const OOCDropdownSearch = ({
  className: classNameProp,
  options,
  localStorageKey,
  children,
  onFilter,
  onChangeSearchValue,
  onSelectItem,
  ...rest
}) => {
  const b = bem('ooc-dropdown-search');
  const cn = b();

  const [searchValue, setSearchValue] = useState('');
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [savedOptions, setSavedOptions] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1100);

  const isSearchStarted = useMemo(() => searchValue.length > START_SEARCH_COUNT, [searchValue]);

  useEffect(() => {
    if (onChangeSearchValue) {
      onChangeSearchValue(searchValue);
    }

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1100);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [searchValue]);

  const optionNodes = useMemo(() => {
    if (!isSearchStarted) {
      return [];
    }

    return children || options.map(({ value, label, logo }) => (
      <Select.Option key={value} value={value} label={label}>
        {logo ? (
          <img alt="option-logo" src={logo} className={`${b('option-img')}`} />
        ) : (
          <CardLogo className={`${b('option-img')}`} label={label} />
        )}
        {label}
      </Select.Option>
    ));
  }, [options, isSearchStarted, b]);

  const getSavedOptionsFromStorage = () => {
    const response = localStorage.getItem(LOCAL_STORAGE_SAVED_OPTIONS_KEY + localStorageKey);
    return response ? JSON.parse(response) : [];
  };

  const setSavedOptionsToStorage = (savedOptions) => {
    localStorage.setItem(LOCAL_STORAGE_SAVED_OPTIONS_KEY + localStorageKey, JSON.stringify(savedOptions));
  };

  const saveOption = (value) => {
    let savedOptions = getSavedOptionsFromStorage();

    savedOptions.unshift(value);
    savedOptions = [...new Set(savedOptions)];
    savedOptions = savedOptions.slice(0, MAX_SAVED_OPTIONS_COUNT);

    setSavedOptionsToStorage(savedOptions);
  };

  const deleteSavedOption = (value) => {
    const savedOptions = getSavedOptionsFromStorage();

    const deletingIndex = savedOptions.indexOf(value);
    savedOptions.splice(deletingIndex, 1);

    setSavedOptionsToStorage(savedOptions);
  };

  const handleDeleteSavedOption = (event, value) => {
    event.stopPropagation();

    deleteSavedOption(value);
    setSavedOptions(getSavedOptionsFromStorage());
  };

  const savedOptionNodes = useMemo(() => (
    savedOptions.map((value) => (
      <Select.Option key={value} value={value} label={SAVED_OPTION_VALUE}>
        <SearchIcon style={{ margin: '0 10px 0 0' }} />
        {value}
        <Button
          size="small"
          type="text"
          shape="circle"
          style={{ marginLeft: 'auto' }}
          icon={<CloseIcon className={`${b('close-icon')}`} />}
          onClick={(event) => handleDeleteSavedOption(event, value)}
        />
      </Select.Option>
    ))
  ), [savedOptions, b]);

  useEffect(() => {
    setSavedOptions(getSavedOptionsFromStorage());
  }, []);

  const handleFilter = (input, option) => {
    const isSavedOption = option.label === SAVED_OPTION_VALUE;
    const isMatched = option.label.toLowerCase().includes(input.toLowerCase());

    return isSavedOption || isMatched || (onFilter && onFilter(input, option));
  };

  const filteredOptions = useMemo(() => (
    options.filter((option) => handleFilter(searchValue, option))
  ), [options, searchValue]);

  const handleSearch = (input) => {
    setSearchValue(input);
  };

  const handleChange = (input, { value, label }) => {
    const isSavedOption = label === SAVED_OPTION_VALUE;

    if (isSavedOption) {
      setSearchValue(value);
    } else {
      onSelectItem(value);
      saveOption(searchValue);
      setSavedOptions(getSavedOptionsFromStorage());
    }
  };

  const handleBlur = () => {
    setIsOptionsOpen(false);
    setTimeout(() => {
      setSearchValue(searchValue);
    });
  };

  const handleClear = () => {
    setSearchValue('');
  };

  return (
    <>
      <div className={cn}>
        <Select
          value={null}
          showSearch
          open={(!!optionNodes.length || !!savedOptionNodes.length) && isOptionsOpen}
          searchValue={searchValue}
          dropdownClassName={cn}
          optionLabelProp="label"
          optionFilterProp="children"
          getPopupContainer={() => document.querySelector(`.${CONTAINER_CLASS}`)}
          filterOption={handleFilter}
          onSearch={handleSearch}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={() => setIsOptionsOpen(true)}
          {...rest}
          suffixIcon={!isLargeScreen ? <SearchIcon /> : (
            <>
              <PlusOutlined
                style={{ transform: 'rotate(45deg)', marginRight: '10px', fontSize: '18px' }}
                onClick={handleClear}
              />
              {/* <SearchIcon style={{ fontSize: '18px' }} /> */}
            </>
          )}
          dropdownRender={(menu) => (
            <>
              {!filteredOptions.length && (
                <div className={b('menu-item-block')}>
                  <SearchIconNotFound />
                  <span className={`${cn} ${b('menu-item-title')}`}>
                    По вашему запросу ничего не найдено
                  </span>
                </div>
              )}
              {menu}
            </>
          )}
        >
          {optionNodes}
          {savedOptionNodes.length && (
            <Select.OptGroup label="Мои запросы">
              {savedOptionNodes}
            </Select.OptGroup>
          )}
        </Select>
      </div>

      <div className={CONTAINER_CLASS} />
    </>
  );
};


export default OOCDropdownSearch;
