import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFoundPage from 'components/not-found/component';
import * as Pages from '../pages';


export default (
  <>
    <Switch>
      { Object.values(Pages) }
      <Route exact path="*">
        <NotFoundPage />
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  </>
);
