import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  message, Form, Modal, Select, Tooltip
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { InputV2, ButtonV2 } from 'components';
import FileCrop from 'components/file-crop';
import { calcCropParams } from 'components/upload-cropper';

import getFileBinary from 'utils/antd-helpers/get-file-binary';
import jsonToFormData from 'utils/json-to-form-data';
import listFormatter from 'utils/antd-helpers/list-formatter';

import { post as oPost, put as oPut, errorMessageCatcher } from 'utils/request';

import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';

import Rating from './rating';

import '../style.less';


export const updateReview = (formData, id) => {
  const url = `/feedbacks/${id}`;

  return oPut(
    `${url}`,
    formData,
    {
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const createReview = (formData) => {
  const url = '/feedbacks';

  return oPost(
    `${url}`,
    formData,
    {
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const baseRequiredValidation = {
  type: 'string',
  required: true,
  whitespace: true,
  message: 'Поле должно содержать текст',
};

const textOnlyValidationRule = {
  pattern: /^(?=.*[A-Za-zА-Яа-я])([A-Za-zА-Яа-я0-9\W]+)$/,
  message: 'Поле может содержать только текст',
};

const qualitiesValidator = (value, length, label, required) => {
  if (!value && required) {
    return Promise.reject(new Error(textOnlyValidationRule.message));
  }
  if (!!value && !textOnlyValidationRule.pattern.test(value)) {
    return Promise.reject(new Error(textOnlyValidationRule.message));
  }
  if (!!value && value.length > length) {
    return Promise.reject(new Error(
      `Поле "${label}" не должно содержать больше ${length} символов`
    ));
  }
  return Promise.resolve();
};

const ReviewModal = ({
  open, review, closeHandler, tableUpdater
}) => {
  const [avatar, setAvatar] = useState(review?.authorAvatar);
  const [completedCrop, setCompletedCrop] = useState();
  const [imageRealSize, setImageRealSize] = useState();
  const [disabledOkBtn, setDisabledOkBtn] = useState(!review);
  const [modalLoading, setModalLoading] = useState(false);

  const [appsList, setAppsList] = useState([]);

  const [form] = Form.useForm();

  const handleOnChange = useCallback(() => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisabledOkBtn(!!e.errorFields.length));
  }, [form]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      return;
    }
    if (review) {
      form.setFieldsValue({
        authorLastname: review?.authorLastname,
        authorFirstname: review?.authorFirstname,
        negativeQualities: review?.negativeQualities,
        positiveQualities: review?.positiveQualities,
        avatarCropParams: review?.authorAvatarSets?.cropParameters,
        application: review?.application.id,
        rating: review?.rating,
      });
      handleOnChange();
    }

    setAvatar(review?.authorAvatar);
    setCompletedCrop(review?.authorAvatarSets?.cropParameters);

    setDisabledOkBtn(!review);
  }, [form, review, open, handleOnChange]);

  const applications = useSelector((state) => state.catalog.applications);
  useEffect(() => {
    if (applications) {
      setAppsList(listFormatter(applications));
    }
  }, [applications]);

  const onFileCropChange = (e) => {
    if (e[0]?.originFileObj) {
      setAvatar(e[0]?.originFileObj ? e[0]?.originFileObj : e[0]?.name);
    }
  };

  const cancelEditReview = useCallback(() => {
    if (modalLoading) {
      return;
    }
    closeHandler();
  }, [closeHandler, modalLoading]);

  const addNewReview = useCallback(
    async (formValues) => {
      setModalLoading(true);
      const avatarCropParams = avatar
        ? calcCropParams({ ...completedCrop, ...imageRealSize })
        : null;

      const data = {
        ...formValues,
        avatarCropParams,
        authorAvatar: getFileBinary(avatar),
      };

      const formData = jsonToFormData(data);

      await createReview(formData).then(() => {
        message.success({
          content: 'Отзыв добавлен',
          icon: <CheckOutlined />,
        });

        cancelEditReview();
        tableUpdater?.();
      }, errorMessageCatcher);
      setModalLoading(false);
    },
    [completedCrop, imageRealSize, avatar, cancelEditReview, tableUpdater]
  );

  const editReview = useCallback(
    async (formValues) => {
      setModalLoading(true);
      const hasNewAvatar = Boolean(avatar) && typeof avatar !== 'string';
      const authorAvatar = hasNewAvatar ? getFileBinary(avatar) : undefined;
      const avatarCropParams = hasNewAvatar
        ? calcCropParams({
          ...completedCrop,
          ...imageRealSize,
        })
        : undefined;

      const data = {
        ...formValues,
        authorAvatar,
        avatarCropParams,
      };

      const formData = jsonToFormData(data);

      await updateReview(formData, review.id).then(() => {
        message.success({
          content: 'Отзыв обновлён',
          icon: <CheckOutlined />,
        });

        cancelEditReview();
        tableUpdater?.();
      }, errorMessageCatcher);
      setModalLoading(false);
    },
    [
      completedCrop,
      imageRealSize,
      review,
      cancelEditReview,
      tableUpdater,
      avatar,
    ]
  );

  const onFormFinish = useCallback(
    (formValues) => {
      if (!review) {
        addNewReview(formValues);
      } else {
        editReview(formValues);
      }
    },
    [review, addNewReview, editReview]
  );

  if (!open) return null;
  return (
    <Modal
      open={open}
      onCancel={cancelEditReview}
      footer={[
        <ButtonV2 onClick={cancelEditReview} disabled={modalLoading}>
          Отменить
        </ButtonV2>,
        <Tooltip title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}>
          <span style={{ marginLeft: 8 }}>
            <ButtonV2
              className={disabledOkBtn ? 'invalid-button' : ''}
              disabled={disabledOkBtn}
              type="primary"
              onClick={form.submit}
              loading={modalLoading}
            >
              Сохранить
            </ButtonV2>
          </span>
        </Tooltip>,
      ]}
      title={review ? 'Редактировать запись' : 'Добавить новую запись'}
      width={548}
    >
      <Form
        initialValues={review}
        form={form}
        className="reviewModalForm"
        layout="vertical"
        onValuesChange={handleOnChange}
        onFinish={onFormFinish}
      >
        <Form.Item
          name="rating"
          rules={[
            {
              ...baseRequiredValidation,
              message: 'Нужно поставить оценку',
              type: 'number',
            },
          ]}
        >
          <Rating
            edit
            rating={review?.rating}
            onChange={(rating) => {
              form.setFieldValue('rating', rating);
            }}
          />
        </Form.Item>
        <div className="row">
          <Form.Item
            rules={[baseRequiredValidation]}
            name="authorLastname"
            label="Фамилия"
          >
            <InputV2 placeholder="Введите фамилию" />
          </Form.Item>
          <Form.Item
            rules={[baseRequiredValidation]}
            name="authorFirstname"
            label="Имя"
          >
            <InputV2 placeholder="Введите имя" />
          </Form.Item>
        </div>

        <FileCrop
          name="authorAvatar"
          maxSize={10}
          label="Фото пользователя"
          key={review?.id}
          defaultFileList={
            review?.authorAvatar
              ? [
                {
                  uid: '1',
                  name: review.authorAvatar,
                  url: review.authorAvatarUrl,
                  thumbUrl: review.authorAvatarUrl,
                  status: 'done',
                },
              ]
              : []
          }
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          setImageRealSize={setImageRealSize}
          onChange={onFileCropChange}
        />

        {!review && (
          <>
            <Form.Item name="organization" label="Организация">
              <InputV2
                placeholder="Введите организацию"
                showCount
                maxLength={25}
              />
            </Form.Item>

            <Form.Item name="position" label="Должность">
              <InputV2
                placeholder="Введите должность"
                showCount
                maxLength={50}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          rules={[
            { ...baseRequiredValidation, message: 'Нужно выбрать приложение' },
          ]}
          name="application"
          label="Приложение"
        >
          <Select
            className="form-select"
            size="large"
            showSearch
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder="Начните вводить название"
            options={appsList}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              type: 'string',
              required: true,
              validator: (_, val) => qualitiesValidator(val, 250, 'Достоинства', true),
            },
          ]}
          name="positiveQualities"
          label="Достоинства"
        >
          <InputV2
            placeholder="Введите достоинства"
            showCount
            maxLength={250}
            textarea
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              validator: (_, val) => qualitiesValidator(val, 250, 'Недостатки', false),
            },
          ]}
          name="negativeQualities"
          label="Недостатки"
        >
          <InputV2
            placeholder="Введите недостатки"
            showCount
            maxLength={250}
            textarea
            autoSize={{
              minRows: 2,
              maxRows: 4,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReviewModal;
