import bem from 'easy-bem';
import Catalog from '../../components/catalog';

import './style.less';


const CatalogPage = () => {
  const b = bem('catalog-page');


  return (
    <div className={b()}>
      <div className={b('content')}>
        <Catalog />
      </div>
    </div>
  );
};

export default CatalogPage;
