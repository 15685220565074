import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import bem from 'easy-bem';

import './style.less';


const OOCTable = (props) => {
  const { t } = useTranslation('ooc-table');
  const b = bem('ooc-table');

  const { ...rest } = props;

  const locale = {
    triggerDesc: t('press-for-desc-sort'),
    triggerAsc: t('press-for-asc-sort'),
    cancelSort: t('press-for-cancel-sort'),
  };

  return (
    <div className={b()}>
      <Table locale={locale} {...rest} />
    </div>
  );
};

export default OOCTable;
