import * as c from './constants';


export const getUsers = () => ({
  type: c.getUsers.tries
});

export const getApplicationsTypes = (id) => ({
  type: c.getApplicationsTypes.tries,
  payload: id,
});

export const getVendors = (query) => ({
  type: c.getVendors.tries,
  payload: query,
});

export const getVendorById = (id) => ({
  type: c.getVendorById.tries,
  payload: id
});

export const getApplicationsByVendorId = (id) => ({
  type: c.getApplicationsByVendorId.tries,
  payload: id,
});

export const getPaginationApplicationsByVendorId = (id, query) => ({
  type: c.getPaginationApplicationsByVendorId.tries,
  payload: { id, query },
});

export const getApplications = (query) => ({
  type: c.getApplications.tries,
  payload: query
});

export const getApplicationById = (id) => ({
  type: c.getApplicationById.tries,
  payload: id
});

export const getCategories = (query) => ({
  type: c.getCategories.tries,
  payload: query,
});

export const getFeedbacks = () => ({
  type: c.getFeedbacks.tries
});

export const getAuthors = () => ({
  type: c.getAuthors.tries
});

export const getOneidUsers = (query) => ({
  type: c.getOneidUsers.tries,
  payload: query,
});

export const getApplicationsStatistic = (query) => ({
  type: c.getApplicationsStatistic.tries,
  payload: query,
});

export const getApplicationsStatisticOrganizations = () => ({
  type: c.getApplicationsStatisticOrganizations.tries,
});

export const getApplicationsWithAnalog = (query) => ({
  type: c.getApplicationsWithAnalog.tries,
  payload: query,
});

export const getApplicationsWithAnalogSet = (query) => ({
  type: c.getApplicationsWithAnalogSet.tries,
  payload: query,
});

export const getSidebarVisible = (query) => ({
  type: c.getSidebarVisible.tries,
  payload: query,
});

export const getApplicationsCount = (query) => ({
  type: c.getApplicationsCount.tries,
  payload: query,
});

export const getConfigurator = (query) => ({
  type: c.getConfigurator.tries,
  payload: query,
});

export const getConfiguratorOptions = (query) => ({
  type: c.getConfiguratorOptions.tries,
  payload: query,
});

export const getPromos = (query) => ({
  type: c.getPromos.tries,
  payload: query,
});

export const getPaginationApplications = (query) => ({
  type: c.getPaginationApplications.tries,
  payload: query,
});

export const getPaginationCategories = (query) => ({
  type: c.getPaginationCategories.tries,
  payload: query,
});

export const getPaginationVendors = (query) => ({
  type: c.getPaginationVendors.tries,
  payload: query,
});

export const getPaginationAuthors = (query) => ({
  type: c.getPaginationAuthors.tries,
  payload: query,
});

export const getPaginationConfigurator = (query) => ({
  type: c.getPaginationConfigurator.tries,
  payload: query,
});

export const getPaginationPromos = (query) => ({
  type: c.getPaginationPromos.tries,
  payload: query,
});

export const getPaginationFeedbacks = (query) => ({
  type: c.getPaginationFeedbacks.tries,
  payload: query,
});

export const getAuthorsOrganization = () => ({
  type: c.getAuthorsOrganization.tries,
});

export const getVendorRating = (id) => ({
  type: c.getVendorRating.tries,
  payload: id,
});

export const getApplicationFeedbacks = (id) => ({
  type: c.getApplicationFeedbacks.tries,
  payload: id
});
