/* eslint-disable max-len */
import {
  call, put, takeLatest
} from 'redux-saga/effects';
import { serverErrorsLogger } from 'utils/server-errors-logger';
import * as c from './constants';
import {
  getUsers,
  getVendors,
  getVendorById,
  getApplications,
  getApplicationsByVendorId,
  getPaginationApplicationsByVendorId,
  getApplicationById,
  getCategories,
  getFeedbacks,
  getAuthors,
  getOneidUsers,
  getApplicationsStatistic,
  getApplicationsStatisticOrganizations,
  getApplicationsWithAnalog,
  getApplicationsWithAnalogSet,
  getApplicationsCount,
  getConfigurator,
  getConfiguratorOptions,
  getPromos,
  getPromosById,
  getAuthorsOrganization,
  getPaginationApplications,
  getPaginationCategories,
  getPaginationVendors,
  getPaginationAuthors,
  getPaginationFeedbacks,
  getPaginationConfigurator,
  getPaginationPromos,
  getApplicationsTypes,
  getVendorRating,
  getApplicationFeedbacks,
} from './api';


function* getApplicationTypesSaga({ payload }) {
  const { success, failure, before } = c.getApplicationsTypes;

  try {
    yield put({ type: before });
    const rawData = yield call(getApplicationsTypes, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationApplicationsSaga({ payload }) {
  const { success, failure, before } = c.getPaginationApplications;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationApplications, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationCategoriesSaga({ payload }) {
  const { success, failure, before } = c.getPaginationCategories;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationCategories, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationVendorsSaga({ payload }) {
  const { success, failure, before } = c.getPaginationVendors;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationVendors, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationAuthorsSaga({ payload }) {
  const { success, failure, before } = c.getPaginationAuthors;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationAuthors, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationFeedbacksSaga({ payload }) {
  const { success, failure, before } = c.getPaginationFeedbacks;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationFeedbacks, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getUsersSaga({ payload }) {
  const { success, failure } = c.getUsers;

  try {
    const rawData = yield call(getUsers, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getVendorsSaga({ payload }) {
  const { success, failure, before } = c.getVendors;

  try {
    yield put({ type: before });
    const rawData = yield call(getVendors, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getVendorByIdSaga({ payload }) {
  const { success, failure, before } = c.getVendorById;

  try {
    yield put({ type: before });
    const rawData = yield call(getVendorById, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { error: e } });
  }
}

function* getApplicationsSaga({ payload }) {
  const { success, failure, before } = c.getApplications;

  try {
    yield put({ type: before });
    const rawData = yield call(getApplications, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsByVendorIdSaga({ payload }) {
  const { success, failure } = c.getApplicationsByVendorId;

  try {
    const rawData = yield call(getApplicationsByVendorId, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { error: e } });
  }
}

function* getPaginationApplicationsByVendorIdSaga({ payload }) {
  const { success, failure, before } = c.getPaginationApplicationsByVendorId;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationApplicationsByVendorId, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { error: e } });
  }
}

function* getApplicationByIdSaga({ payload }) {
  const { success, failure, before } = c.getApplicationById;

  try {
    yield put({ type: before });
    const rawData = yield call(getApplicationById, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { error: e } });
  }
}

function* getCategoriesSaga({ payload }) {
  const { success, failure } = c.getCategories;

  try {
    const rawData = yield call(getCategories, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getFeedbacksSaga({ payload }) {
  const { success, failure } = c.getFeedbacks;

  try {
    const rawData = yield call(getFeedbacks, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getAuthorsSaga({ payload }) {
  const { success, failure } = c.getAuthors;

  try {
    const rawData = yield call(getAuthors, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getOneidUsersSaga({ payload }) {
  const { success, failure } = c.getOneidUsers;

  try {
    const rawData = yield call(getOneidUsers, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsStatisticSaga({ payload }) {
  const { success, failure } = c.getApplicationsStatistic;

  try {
    const rawData = yield call(getApplicationsStatistic, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsStatisticOrganizationsSaga({ payload }) {
  const { success, failure } = c.getApplicationsStatisticOrganizations;

  try {
    const rawData = yield call(getApplicationsStatisticOrganizations, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsWithAnalogSaga({ payload }) {
  const { success, failure } = c.getApplicationsWithAnalog;

  try {
    const rawData = yield call(getApplicationsWithAnalog, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsWithAnalogSetSaga({ payload }) {
  const { success, failure } = c.getApplicationsWithAnalogSet;

  try {
    const rawData = yield call(getApplicationsWithAnalogSet, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getSidebarVisibleSaga({ payload }) {
  const { success, failure } = c.getSidebarVisible;

  try {
    yield put({ type: success, payload });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationsCountSaga({ payload }) {
  const { success, failure } = c.getApplicationsCount;

  try {
    const rawData = yield call(getApplicationsCount, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getConfiguratorSaga({ payload }) {
  const { success, failure } = c.getConfigurator;

  try {
    const rawData = yield call(getConfigurator, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationConfiguratorSaga({ payload }) {
  const { success, failure, before } = c.getPaginationConfigurator;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationConfigurator, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getConfiguratorOptionsSaga({ payload }) {
  const { success, failure } = c.getConfiguratorOptions;

  try {
    const rawData = yield call(getConfiguratorOptions, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPromosSaga({ payload }) {
  const { success, failure } = c.getPromos;

  try {
    const rawData = yield call(getPromos, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPaginationPromosSaga({ payload }) {
  const { success, failure, before } = c.getPaginationPromos;

  try {
    yield put({ type: before });
    const rawData = yield call(getPaginationPromos, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getPromosByIdSaga({ payload }) {
  const { success, failure } = c.getPromosById;

  try {
    const rawData = yield call(getPromosById, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getAuthorsOrganizationSaga({ payload }) {
  const { success, failure } = c.getAuthorsOrganization;

  try {
    const rawData = yield call(getAuthorsOrganization, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getVendorRatingSaga({ payload }) {
  const { success, failure, before } = c.getVendorRating;

  try {
    yield put({ type: before });
    const rawData = yield call(getVendorRating, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { message: e.message } });
  }
}

function* getApplicationFeedbacksSaga({ payload }) {
  const { success, failure } = c.getApplicationFeedbacks;

  try {
    const rawData = yield call(getApplicationFeedbacks, payload);
    yield put({ type: success, payload: rawData });
  } catch (e) {
    serverErrorsLogger(e);
    yield put({ type: failure, payload: { error: e } });
  }
}

export default function* rootSaga() {
  yield takeLatest(c.getUsers.tries, getUsersSaga);
  yield takeLatest(c.getVendors.tries, getVendorsSaga);
  yield takeLatest(c.getVendorById.tries, getVendorByIdSaga);
  yield takeLatest(c.getApplications.tries, getApplicationsSaga);
  yield takeLatest(c.getApplicationById.tries, getApplicationByIdSaga);
  yield takeLatest(c.getCategories.tries, getCategoriesSaga);
  yield takeLatest(c.getFeedbacks.tries, getFeedbacksSaga);
  yield takeLatest(c.getAuthors.tries, getAuthorsSaga);
  yield takeLatest(c.getOneidUsers.tries, getOneidUsersSaga);
  yield takeLatest(c.getApplicationsStatistic.tries, getApplicationsStatisticSaga);
  yield takeLatest(c.getApplicationsStatisticOrganizations.tries, getApplicationsStatisticOrganizationsSaga);
  yield takeLatest(c.getApplicationsWithAnalog.tries, getApplicationsWithAnalogSaga);
  yield takeLatest(c.getApplicationsWithAnalogSet.tries, getApplicationsWithAnalogSetSaga);
  yield takeLatest(c.getSidebarVisible.tries, getSidebarVisibleSaga);
  yield takeLatest(c.getApplicationsCount.tries, getApplicationsCountSaga);
  yield takeLatest(c.getConfigurator.tries, getConfiguratorSaga);
  yield takeLatest(c.getPaginationConfigurator.tries, getPaginationConfiguratorSaga);
  yield takeLatest(c.getConfiguratorOptions.tries, getConfiguratorOptionsSaga);
  yield takeLatest(c.getPaginationApplications.tries, getPaginationApplicationsSaga);
  yield takeLatest(c.getPromos.tries, getPromosSaga);
  yield takeLatest(c.getPaginationPromos.tries, getPaginationPromosSaga);
  yield takeLatest(c.getPromosById.tries, getPromosByIdSaga);
  yield takeLatest(c.getApplicationsByVendorId.tries, getApplicationsByVendorIdSaga);
  yield takeLatest(c.getApplicationsTypes.tries, getApplicationTypesSaga);
  yield takeLatest(c.getPaginationApplicationsByVendorId.tries, getPaginationApplicationsByVendorIdSaga);
  yield takeLatest(c.getPaginationCategories.tries, getPaginationCategoriesSaga);
  yield takeLatest(c.getPaginationVendors.tries, getPaginationVendorsSaga);
  yield takeLatest(c.getPaginationAuthors.tries, getPaginationAuthorsSaga);
  yield takeLatest(c.getPaginationFeedbacks.tries, getPaginationFeedbacksSaga);
  yield takeLatest(c.getAuthorsOrganization.tries, getAuthorsOrganizationSaga);
  yield takeLatest(c.getVendorRating.tries, getVendorRatingSaga);
  yield takeLatest(c.getApplicationFeedbacks.tries, getApplicationFeedbacksSaga);
}
