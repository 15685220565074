import {
  Dropdown, Form, Input, message, Modal, Row, Select, Space, Spin, Table
} from 'antd';
import { ButtonV2 } from 'components';
import bem from 'easy-bem';
import React, { useEffect, useMemo, useState } from 'react';
import { remove as oDelete } from 'utils/request';

import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import {
  getPaginationConfigurator,
  getConfiguratorOptions,
} from 'models/catalog/actions';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';

import {
  CheckOutlined, CloseOutlined, EllipsisOutlined, LoadingOutlined, PlusOutlined
} from '@ant-design/icons';
import { configuratorKeys, tablePaginationOptions, configuratorKeysOptions } from 'utils/consts';
import { getAccessData, getUserRole, ROLE_ADMIN } from 'utils/auth';
import AdminConfiguratorForm from './form';

import './style.less';


export const deleteConfigurator = (id) => {
  const url = `/configurator/${id}`;

  return oDelete(
    url,
    {
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
      accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const DEFAULT_PARAMS = { page: 1, limit: 20 };

const AdminConfiguratorPane = () => {
  const b = bem('admin-table-modal');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const userRole = getUserRole();
  const user = getAccessData();
  const { items, loading, totalCount } = useSelector((state) => state.catalog.paginationConfigurator);

  const [searchTitle, setSearchTitle] = useState('');
  const [skipDebounce, setSkipDebounce] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configuratorParams, setConfiguratorParams] = useState(DEFAULT_PARAMS);
  const [isDeleteErrorModalOpen, setIsDeleteErrorModalOpen] = useState({
    canDelete: false,
    cantDelete: false,
    configuratorId: '',
  });
  const [modalLoading, setModalLoading] = useState(false);

  const handleCanDeleteConfiguratorClick = (configurator) => {
    setIsDeleteErrorModalOpen({
      canDelete: true,
      cantDelete: false,
      configuratorId: configurator.id,
    });
  };

  const getItems = (configurator) => {
    const hasAccess = userRole === ROLE_ADMIN || configurator.userCreator === user.id;
    return [
      {
        key: '3',
        label: <div>Удалить</div>,
        disabled: !hasAccess,
        onClick: () => handleCanDeleteConfiguratorClick(configurator),
      },
    ];
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Ключ',
      dataIndex: 'keyValue',
      key: 'keyValue',
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Связанные приложения',
      dataIndex: 'publishedAppsCount',
      key: 'publishedAppsCount',
    },
    {
      width: 30,
      render: (record) => (
        <Dropdown menu={{ items: getItems(record) }} trigger={['click']}>
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const dataSource = useMemo(() => {
    const configuratorCopy = [...items];
    configuratorCopy.map((item) => {
      for (const key in configuratorKeys) {
        if (item.key === key) {
          item.key = item.value;
          item.keyValue = configuratorKeys[key];
        }
      }
    });
    return configuratorCopy;
  }, [items]);

  useEffect(() => {
    dispatch(getPaginationConfigurator(configuratorParams));
  }, [dispatch, configuratorParams]);

  const handleModalVisibleClick = () => {
    setIsModalVisible(!isModalVisible);
    if (!isModalVisible) {
      form.resetFields();
    }
  };

  const updateList = () => {
    dispatch(getPaginationConfigurator(configuratorParams));
  };

  const handleDeleteConfiguratorClick = async () => {
    try {
      setModalLoading(true);
      await deleteConfigurator(isDeleteErrorModalOpen.configuratorId);
      message.success({
        content: 'Запись конфигуратора удалена',
        icon: <CheckOutlined />
      });
      updateList();
      dispatch(getConfiguratorOptions());
      setIsDeleteErrorModalOpen({
        ...isDeleteErrorModalOpen,
        canDelete: false,
      });
      setModalLoading(false);
    } catch (error) {
      const { message: errorMessage } = error;
      message.error({
        content: errorMessage,
        icon: <CloseOutlined />,
      });
      if (error.status === 403) {
        setIsDeleteErrorModalOpen({
          ...isDeleteErrorModalOpen,
          canDelete: false,
          cantDelete: true,
        });
      }
      setModalLoading(false);
    }
  };

  const handleCloseDeleteConfiguratorModals = () => {
    if (modalLoading) {
      return;
    }
    setIsDeleteErrorModalOpen({
      ...isDeleteErrorModalOpen,
      canDelete: false,
      cantDelete: false,
    });
  };
  
  const onPaginationChange = ({ current: page, pageSize }) => {
    setConfiguratorParams((prev) => ({ ...prev, page, limit: pageSize }));
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setConfiguratorParams((prev) => ({ ...prev, value: searchTitle, page: 1 }));
    },
    500,
    [searchTitle]
  );
  
  const resetFields = () => {
    setConfiguratorParams(DEFAULT_PARAMS);
  };

  const onConfiguratorKeyChange = (key) => {
    setConfiguratorParams((prev) => ({ ...prev, key, page: 1 }));
  };

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setIsModalVisible(false);
  };

  return (
    <div className={b('modal-block')}>
      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Form form={filterForm} className="filter">
          <Space>
            <Form.Item name="value" className="filter-item filter-search">
              <Input
                placeholder="Поиск по значению"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="key" className="filter-item filter-select">
              <Select
                placeholder="Выберите ключ"
                popupClassName="admin-select-dropdown"
                options={configuratorKeysOptions}
                onChange={onConfiguratorKeyChange}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                filterForm.resetFields();
                resetFields();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
        </Form>
        <ButtonV2
          type="link"
          icon={<PlusOutlined />}
          onClick={handleModalVisibleClick}
        >
          Добавить новую запись
        </ButtonV2>
        <Modal
          className="main-content"
          title="Добавить запись"
          open={isModalVisible}
          footer={false}
          onCancel={closeModal}
          maskClosable
        >
          <AdminConfiguratorForm
            update={updateList}
            setIsModalVisible={setIsModalVisible}
            requestLoading={modalLoading}
            startRequest={setModalLoading}
            form={form}
          />
        </Modal>
      </Row>
      
      <Spin indicator={loadingIcon} spinning={loading}>
        <Table
          onChange={onPaginationChange}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            showTotal: (total, range) => {
              const allItemsText = `Всего записей: ${total}`;
              const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
              return (
                <div className="pagination-showTotal">
                  <div>{allItemsText}</div>
                  <div>{rangeItemsText}</div>
                </div>
              );
            },
            total: totalCount,
            current: configuratorParams.page,
            pageSize: configuratorParams.limit,
            pageSizeOptions: tablePaginationOptions,
            showSizeChanger: true,
          }}
        />
      </Spin>

      <Modal
        title="Удалить запись"
        open={isDeleteErrorModalOpen.cantDelete}
        onCancel={handleCloseDeleteConfiguratorModals}
        footer={[
          <ButtonV2
            type="primary"
            onClick={handleCloseDeleteConfiguratorModals}
          >
            Закрыть
          </ButtonV2>,
        ]}
        maskClosable
      >
        <div className="warning-block">
          <div className="removeIcon">
            <WarningIcon />
          </div>
          <p>
            Запись справочника используется в опубликованных приложениях.
            Отредактируйте карточки связанных приложений.
          </p>
        </div>
      </Modal>

      <Modal
        title="Удалить запись"
        open={isDeleteErrorModalOpen.canDelete}
        onCancel={handleCloseDeleteConfiguratorModals}
        footer={[
          <>
            <ButtonV2 onClick={handleCloseDeleteConfiguratorModals} disabled={modalLoading}>
              Отменить
            </ButtonV2>
            <ButtonV2
              type="primary"
              onClick={() => handleDeleteConfiguratorClick(columns)}
              loading={modalLoading}
            >
              Удалить
            </ButtonV2>
          </>,
        ]}
        maskClosable
      >
        <div className="warning-block">
          <div className="removeIcon">
            <WarningIcon />
          </div>
          <p>Вы уверены, что хотите удалить запись справочника?</p>
        </div>
      </Modal>
    </div>
  );
};

export default AdminConfiguratorPane;
