/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import bem from 'easy-bem';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';

import './style.less';


const { Item } = Breadcrumb;

const OOCBreadcrumbs = () => {
  const b = bem('ooc-breadcrumbs');
  const { t } = useTranslation('breadcrumbs');
  const location = useLocation();
  const [pathObject, setPathObject] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [application, setApplication] = useState(null);

  //FIX IT temp hack for breadcrumb name
  const appSelector = useSelector((state) => state.catalog.application);

  useEffect(() => {
    getPathArray();
  }, [location.pathname]);

  useEffect(() => {
    if (appSelector) {
      setVendor(appSelector.vendor)
      setApplication(appSelector)
    }
  }, [appSelector]);

  const getPathArray = () => {
    const { pathname } = location;
    const path = pathname
      .split('/')
      .filter((el) => el.length);
    path.shift();
    setPathObject(path);
  };

  const getBreadcrumbText = (pathId) => {
    let breadcrumbText = pathId;
    let entity;

    if (application && application.id === pathId) {
      entity = application;
    }

    if (vendor && vendor.id === pathId) {
      entity = vendor;
    }

    if (entity) {
      breadcrumbText = entity.title || entity.name;
    }
    return breadcrumbText;
  }

  const constructBreadcrumbs = () => {
    if(application){
      return pathObject.map((pathName, index, array) => (
        <Item key={pathName}>
        <Link to={`/catalog/${array.slice(0, index + 1).join('/')}`} className={b('backspace--links')}>
          {getBreadcrumbText(t(pathName))}
        </Link>
      </Item>
    ));
    } else {
      return (
        <Item>
          <Link to='/catalog' className={b('backspace--links')}>
            {getBreadcrumbText(t(pathObject[0]))}
          </Link>
        </Item>
      );
    }
  };

  return (
    <div className={b()}>
      {
        pathObject.length === 1
          ? (
            <div className={b('backspace')}>
              <Link to="/catalog" className={b('backspace--button')}>
                <ArrowIcon className={b('backspace--icon')} />
                <span className='title'>Назад к каталогу ПО</span>
              </Link>
            </div>
          )
          : pathObject.length > 1
            ? (
              <div className={b('backspace')}>
                <Breadcrumb separator={<span className={b('backspace--separator')}>/</span>}>
                  <Link className={b('backspace--links')} to='/catalog'>
                    <div className='catalog-title'>
                      <span className='laptop'>Каталог программного обеспечения</span>
                      <span className='mobile'>Каталог ПО</span>
                      <span className={b('backspace--separator')}>/</span>
                    </div>
                  </Link>
                  { constructBreadcrumbs() }
                </Breadcrumb>
              </div>
            )
            : ''
      }
    </div>
  );
};

export default OOCBreadcrumbs;
