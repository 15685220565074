import bem from 'easy-bem';
import React from 'react';

import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import UserReview from './user-review';

import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import './style.less';


const UserReviews = () => {
  const b = bem('user-reviews');

  const applicationFeedbacks = useSelector((state) => state.catalog.applicationFeedbacks);

  if (!applicationFeedbacks?.length) {
    return null;
  }

  return (
    <div className={b()}>
      <h4 className="reviews-title">Отзывы пользователей</h4>

      <Swiper
        spaceBetween={16}
        modules={[Navigation, Scrollbar, Mousewheel, FreeMode, Pagination]}
        mousewheel={{ sensitivity: 1, forceToAxis: true, thresholdDelta: 100 }}
        roundLengths
        slidesPerView="auto"
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          type: 'custom',
          el: '.swiper-pagination',
          renderCustom: (
            _swiper,
            current,
            total
          ) => `<span class="swiper-custom-current">
          ${current}</span> / <span class="swiper-custom-total">${total}</span>`,
        }}
        scrollbar={{
          el: '.swiper-scrollbar',
          draggable: true,
        }}
        direction="horizontal"
        breakpoints={{
          540: { spaceBetween: 18 },
          665: { spaceBetween: 20 },
          1000: { spaceBetween: 22 },
          1280: { spaceBetween: 24 },
        }}
      >
        {applicationFeedbacks.map((review) => (
          <SwiperSlide>
            <UserReview review={review} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-custom-systems">
        <div className="swiper-scrollbar swiper-custom-scroll" />
        <div className="swiper-custom-navigation">
          <div className="swiper-button-prev swiper-custom-prev" />
          <div className="swiper-pagination swiper-custom-pagination" />
          <div className="swiper-button-next swiper-custom-next" />
        </div>
      </div>
    </div>
  );
};

export default UserReviews;
