import React from 'react';
import {
  Switch, Route, Redirect
} from 'react-router-dom';

import VendorsCatalog from './components/vendors-catalog';
import ApplicationsCatalog from './components/applications-catalog';
import SoftwareSearch from './components/software-search';
import AnalogsCatalog from './components/analogs-catalog';
import ApplicationOverview from './components/application-overview';


const Catalog = () => (
  <Route>
    <Switch>
      <Route exact path="/">
        <Redirect to="/catalog" />
      </Route>
      <Route exact path="/catalog">
        <VendorsCatalog />
      </Route>
      <Route exact path="/catalog/search">
        <SoftwareSearch />
      </Route>
      <Route exact path="/catalog/analogs">
        <AnalogsCatalog />
      </Route>
      <Route exact path="/catalog/:vendorSlug">
        <ApplicationsCatalog />
      </Route>
      <Route exact path="/catalog/:vendorSlug/:appSlug">
        <ApplicationOverview />
      </Route>
    </Switch>
  </Route>
);

export default Catalog;
