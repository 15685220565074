import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Menu } from 'antd';
import bem from 'easy-bem';
import { useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as HomeIcon } from 'assets/icons/nav/nav-home.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/nav/nav-education.svg';
import { ReactComponent as MonitoringIcon } from 'assets/icons/nav/nav-monitoring.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/nav/nav-users.svg';
import { ReactComponent as AdminLockIcon } from 'assets/icons/nav/nav-admin-lock.svg';
import { ReactComponent as AboutIcon } from 'assets/icons/nav/nav-about.svg';
import { ReactComponent as ComputerIcon } from 'assets/icons/nav/nav-computer.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/nav/nav-phone.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/nav/nav-location.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/nav/nav-email.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/nav/nav-arrow.svg';

import { setDrawerVisible } from 'models/common/actions';
import { ButtonV2 } from 'components';
import { useSelector } from 'store/use-selector';

import { NavSubMenu } from './components/NavSubMenu';
import { NavItem } from './components/NavItem';

import './style.less';


export const NavSideMenu = () => {
  const b = bem('nav-side-menu');

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const drawerVisible = useSelector((state) => state.common.drawerVisible);
  const isMobile = useSelector((state) => state.common.isMobile);
  const user = useSelector((state) => state.user);

  const { role } = user.oidcState;
  const isSignedIn = !!user.oidcUser.userName;

  const authState = (condition: string) => {
    const isAuthorizedWithRole = condition === role && isSignedIn;
    const isAuthorized = condition === 'signedIn' && isSignedIn;
    const isUnauthorized = condition === 'signedOut' && !isSignedIn;


    return isAuthorizedWithRole || isAuthorized || isUnauthorized || !condition;
  };

  const [selectedKeys] = useState([]);

  useEffect(() => {
    if (isMobile) {
      dispatch(setDrawerVisible(!isMobile));
    }
  }, [isMobile, dispatch, location]);

  return (
    <div className={b()}>
      <Menu
        className={!drawerVisible ? 'opened' : ''}
        mode="inline"
        theme="dark"
        expandIcon={() => null}
        selectedKeys={selectedKeys}
      >
        <NavItem
          key="k1"
          route=""
          label="main"
          icon={<HomeIcon />}
          shouldRender={authState('signedIn')}
        />

        <NavItem
          key="k6"
          route="guest"
          icon={<AboutIcon color="white" />}
          shouldRender={authState('signedOut')}
        />

        <NavItem
          key="k2"
          route="courses"
          icon={<EducationIcon />}
        />

        <NavSubMenu
          shouldRender={authState('signedIn')}
          key="k3"
          title="educational-control"
          icon={<MonitoringIcon />}
          onTitleClick={() => history.push('/education-apps')}
        >
          <NavItem key="k31" route="education-apps" />
          <NavItem key="k32" route="my-staff" />
        </NavSubMenu>

        <NavSubMenu
          key="k4"
          title="consortium"
          icon={<UsersIcon />}
          shouldRender={authState('signedIn')}
          onTitleClick={() => history.push('/consortium')}
        >
          <NavItem key="k41" route="consortium-participants" />
        </NavSubMenu>

        <NavSubMenu
          key="k5"
          title="administration"
          icon={<AdminLockIcon />}
          shouldRender={authState('Administrator')}
          onTitleClick={() => history.push('/admin-users')}
        >
          <NavItem key="k51" route="admin-users" label="users" />
          <NavItem key="k52" route="admin-apps" label="applications" />
          <NavItem key="k53" route="admin-roles" label="roles" />

        </NavSubMenu>

        <NavItem
          key="k7"
          route="consortium"
          icon={<UsersIcon />}
          shouldRender={authState('signedOut')}
        />
        <NavItem
          key="k8"
          route="training"
          icon={<EducationIcon />}
          shouldRender={authState('signedOut')}
        />
        <NavItem
          key="k9"
          route="actualization"
          icon={<MonitoringIcon />}
          shouldRender={authState('signedOut')}
        />
        <NavItem
          key="k10"
          route="catalog"
          icon={<ComputerIcon color="white" />}
        />
        <NavSubMenu
          key="k11"
          title="educational-programs"
          icon={<EducationIcon />}
          shouldRender={authState('signedIn')}
          onTitleClick={() => history.push('/opop-constructor')}
        >
          <NavItem key="k57" route="opop-constructor" label="opop-constructor" />
        </NavSubMenu>
      </Menu>
      <div className="menu-footer">
        {drawerVisible && (
          <div className="contact-list">
            <ButtonV2 type="link" href="tel:+78005503171" icon={<PhoneIcon />} className="list-item">
              <span>+7 (800) 550-31-71</span>
            </ButtonV2>
            <ButtonV2
              className="list-item"
              type="link"
              href="mailto:ooc@innopolis.ru"
              icon={<EmailIcon />}
            >
              <span>ooc@innopolis.ru </span>
            </ButtonV2>
            <div className="list-item">
              <LocationIcon />
              <span>
                ул.Университетская, д.1
                420500, г. Иннополис
              </span>
            </div>
          </div>
        )}
        <Divider />
        <ArrowIcon
          className={`footer-toggle ${!drawerVisible ? 'opened' : ''}`}
          onClick={() => dispatch(setDrawerVisible(!drawerVisible))}
        />
      </div>
    </div>
  );
};
