import { Tabs } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  useHistory, useLocation
} from 'react-router-dom';

import routes from 'utils/routes';
import './style.less';

import {
  getUserRole,
  ROLE_ADMIN,
} from 'utils/auth';
import { ROLE_AUTHOR } from '../../utils/auth';


const { TabPane } = Tabs;


const TABS = [
  { label: 'Управление каталогом ПО', to: '/catalog-admin/directory-managment' },
  { label: 'Управление пользователями', to: '/catalog-admin/user-managment' },
  { label: 'Блог и Реклама', to: '/catalog-admin/promo-managment' },
];


const AdminLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const userRole = getUserRole();

  
  const tabs = useMemo(() => {
    if (userRole !== ROLE_ADMIN && userRole !== ROLE_AUTHOR) {
      return [];
    }
    return TABS;
  }, [userRole]);

  const activeKey = useMemo(() => tabs.find(({ to }) => location.pathname.startsWith(to))?.to, [location, tabs]);

  const onTabChange = (key) => {
    history.push(key);
  };
  
  return (
    <div className="admin-wrapper">
      <Tabs
        onChange={onTabChange}
        className="admin-wrapper_tabs"
        activeKey={activeKey}
      >
        {tabs.map((tab) => (<TabPane tab={tab.label} key={tab.to} />))}
      </Tabs>
      <div className="admin-wrapper_content">{routes}</div>
    </div>
  );
};

export default AdminLayout;
