import React, {
  useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import bem from 'easy-bem';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { ButtonV2 } from 'components';
import {
  getVendorById,
  getPaginationApplicationsByVendorId,
  getApplicationsTypes,
  getVendorRating,
} from 'models/catalog/actions';

import CardLogoBordered from 'components/card-logo-bordered';
import { ReactComponent as EmailIcon } from 'assets/icons/nav/nav-email.svg';
import RatingPanel from '../../../rating-panel';
import VerticalApplicationCard from '../vertical-application-card';

import './style.less';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const DEFAULT_APP_PARAMS = {
  page: 1,
  limit: 10,
  type: null,
};

const ApplicationsCatalog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('catalog');
  const { t: typesTranslation } = useTranslation('catalog-types');
  const { vendorSlug } = useParams();
  const b = bem('applications-catalog');
  const history = useHistory();
  const scrollToTop = useRef(null);
  const [applicationsParams, setApplicationsParams] = useState(DEFAULT_APP_PARAMS);
  const [applicationsByVendor, setApplicationsByVendor] = useState([]);

  const vendor = useSelector((state) => state.catalog.vendor);
  const types = useSelector((state) => state.catalog.types);
  const { items, totalCount, loading } = useSelector(
    (state) => state.catalog.paginationApplicationsByVendor
  );
  const rating = useSelector((state) => state.catalog.vendorRating);

  const setType = (type) => {
    setApplicationsParams({ ...DEFAULT_APP_PARAMS, type });
  };

  useEffect(() => {
    if (applicationsParams.page > 1) {
      setApplicationsByVendor((prev) => [...prev, ...items]);
    } else {
      setApplicationsByVendor(items);
    }
  }, [items]);

  useEffect(() => {
    dispatch(getVendorById(vendorSlug));
    scrollToTop?.current?.scrollIntoView();
  }, [dispatch, vendorSlug]);

  useEffect(() => {
    if (!vendor?.id) return;

    dispatch(
      getPaginationApplicationsByVendorId(vendor.id, applicationsParams)
    );
  }, [dispatch, vendor?.id, applicationsParams]);

  useEffect(() => {
    if (!vendor?.id) return;

    dispatch(getApplicationsTypes(vendor.id));
    dispatch(getVendorRating(vendor.id));
  }, [dispatch, vendor?.id]);

  if (vendor === false) history.push('/not-found');

  const vendorHasLogo = vendor?.logo && vendor?.logoUrl && !vendor?.logoUrl.endsWith('null');

  return (
    <div ref={scrollToTop} className={b()}>
      {vendor && (
        <div className="catalog-content">
          <div className={b('brief-block')}>
            <div className={b('brief-text')}>
              <div className="content">
                <div style={{ flexGrow: 1 }}>
                  <RatingPanel rating={rating} />
                  <h2 className="title">{vendor.name}</h2>
                  <div className="content-text">
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{ __html: vendor.description }}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                  <ButtonV2
                    className="buy-btn"
                    href={vendor.siteUrl}
                    target="_blank"
                    type="primary"
                  >
                    Перейти на сайт вендора
                  </ButtonV2>

                  <ButtonV2
                    className="buy-btn email"
                    href={`mailto:${vendor.email}`}
                    target="_blank"
                    icon={<EmailIcon />}
                  >
                    {vendor.email}
                  </ButtonV2>
                </div>
              </div>
            </div>
            
            <div className={
              vendorHasLogo ? 'vendor-image' : 'vendor-image-logo'
            }
            >
              {vendorHasLogo ? (
                <div className="img-wrapper">
                  <img src={vendor.logoUrl} alt="Лого вендора" />
                </div>
              ) : (
                <div className="vendor-image-logo-wrapper">
                  <CardLogoBordered label={vendor.name} />
                </div>
              )}
            </div>
          </div>

          <div className={b('list')}>
            <div className="category-content">
              <h2 className="subtitle">{t('applications')}</h2>
              <div className="category">
                {Boolean(types?.length) && (
                  <ul className="category-list">
                    <li
                      onClick={() => setType(null)}
                      className={
                        applicationsParams.type ? '' : 'category-list__active'
                      }
                    >
                      Все
                    </li>
                    {types.map((type) => (
                      <li
                        key={type}
                        onClick={() => setType(type)}
                        className={
                          applicationsParams.type === type ? 'category-list__active' : ''
                        }
                      >
                        {typesTranslation(type)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <Spin spinning={loading} indicator={loadingIcon}>
              {applicationsByVendor?.length ? (
                <>
                  <div className="content-grid">
                    {applicationsByVendor.map((app) => (
                      // eslint-disable-next-line no-underscore-dangle
                      <VerticalApplicationCard
                        key={app._id}
                        {...app}
                        vendor={vendor}
                      />
                    ))}
                  </div>
                  {applicationsByVendor?.length < totalCount && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 24,
                      }}
                    >
                      <ButtonV2
                        onClick={() => setApplicationsParams((prev) => ({
                          ...prev,
                          page: prev.page + 1,
                        }))}
                        type="link"
                        style={{
                          padding: '7px 16px',
                          borderRadius: 10,
                          background: '#FFFFFF',
                        }}
                      >
                        <RedoOutlined
                          style={{ transform: 'rotate(-100deg)' }}
                        />
                        Показать еще 10 приложений из
                        {' '}
                        {totalCount}
                      </ButtonV2>
                    </div>
                  )}
                </>
              ) : (
                <p className="empty-apps">Приложений не найдено</p>
              )}
            </Spin>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationsCatalog;
