import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import { ReactComponent as StarIcon } from 'assets/icons/starIconFilled.svg';


const Rating = ({ rating = 0, edit = false, onChange }) => {
  const [reviewRating, setReviewRating] = useState(rating);
  const [focusedRating, setFocudesRating] = useState(null);
  const ratingWrapperRef = useRef();

  useEffect(() => {
    setReviewRating(rating);
  }, [rating]);

  const mouseOver = (index) => {
    setFocudesRating(index);
  };

  const mouseMoveHandler = (e) => {
    if (!ratingWrapperRef.current) return;

    const { target } = e;
    if (target === ratingWrapperRef.current || ratingWrapperRef.current.contains(target)) return;

    setFocudesRating(null);
  };

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveHandler);
  
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  const handleChange = (val) => {
    setReviewRating(val);

    onChange?.(val);
  };

  const isHovered = useCallback((index) => {
    if (!edit) return false;
    if (!focusedRating && focusedRating !== 0) return false;

    return index <= focusedRating;
  }, [edit, focusedRating]);


  return (
    <div className={`ratingWrapper ${edit && 'interactive'}`}>
      {edit && <div className="title">Оцените приложение</div>}
      <div
        className="rating"
        ref={ratingWrapperRef}
      >
        {Array.from({ length: 5 }, (_, index) => {
          const starClass = (isHovered(index) || index < reviewRating)
            ? 'star-filled'
            : 'star-empty';
          const hoveredStarClass = isHovered(index) ? 'hovered' : '';

          return (
            <StarIcon
              key={index}
              className={`star ${starClass} ${hoveredStarClass}`}
              onMouseOver={() => mouseOver(index)}
              onClick={() => handleChange(index + 1)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Rating;
