import {
  get as oGet
} from 'utils/request';

import { REACT_APP_BACKEND_HOST } from 'utils/consts';


const target = REACT_APP_BACKEND_HOST;
export const TEMP_SOFTWARE_CATALOG_API = `${target}/api/v1`;
export const TEMP_SOFTWARE_CATALOG_API_V2 = `${target}/api/v2`;

export const getPromos = (query) => {
  const url = '/promos';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationsTypes = (id) => {
  const url = `/vendors/${id}/applications/types`;

  return oGet(
    url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    },
    TEMP_SOFTWARE_CATALOG_API,
  );
};

export const getPaginationPromos = (query) => {
  const url = '/promos';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getPromosById = (id) => {
  const url = `/promos/${id}`;

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getUsers = () => {
  const url = '/users';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getVendors = (query) => {
  const url = '/vendors';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getVendorById = (id) => {
  const url = '/vendors/slug';

  return oGet(`${url}/${id}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getApplications = (query) => {
  const url = '/applications';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationById = (id) => {
  const url = '/applications/slug';

  return oGet(`${url}/${id}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getApplicationsByVendorId = (id) => {
  const url = `/vendors/${id}/applications`;

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getPaginationApplicationsByVendorId = ({ id, query }) => {
  const url = `/vendors/${id}/applications`;

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getCategories = (query) => {
  const url = '/categories';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getFeedbacks = () => {
  const url = '/feedbacks';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getAuthors = () => {
  const url = '/authors';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getOneidUsers = (query) => {
  const url = '/search/oneid';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationsStatistic = (query) => {
  const url = '/applications-statistic';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationsStatisticOrganizations = () => {
  const url = '/applications-statistic/organizations';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};

export const getApplicationsStatisticReport = (query) => {
  const url = '/applications-statistic/report';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationsWithAnalog = (query) => {
  const url = '/applications-analogs';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};
export const getApplicationsWithAnalogSet = (query) => {
  const url = '/applications-analogs';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getApplicationsCount = (query) => {
  const url = '/applications-count';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getConfigurator = (query) => {
  const url = '/configurator';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getPaginationConfigurator = (query) => {
  const url = '/configurator';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getConfiguratorOptions = (query) => {
  const url = '/configurator/options';

  return oGet(`${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API, query);
};

export const getPaginationApplications = (query) => {
  const url = '/applications';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getPaginationCategories = (query) => {
  const url = '/categories';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getPaginationVendors = (query) => {
  const url = '/vendors';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getPaginationAuthors = (query) => {
  const url = '/authors';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getPaginationFeedbacks = (query) => {
  const url = '/feedbacks';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2, query);
};

export const getAuthorsOrganization = () => {
  const url = '/authors/organizations ';

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API_V2);
};

export const getVendorRating = (id) => oGet(
  `/vendors/${id}/rating`,
  {
    'content-type': 'application/json',
    accept: 'application/json'
  }, TEMP_SOFTWARE_CATALOG_API
);

export const getApplicationFeedbacks = (id) => {
  const url = `/feedbacks/${id}/application`;

  return oGet(url,
    {
      'content-type': 'application/json',
      accept: 'application/json'
    }, TEMP_SOFTWARE_CATALOG_API);
};
