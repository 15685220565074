const listFormatter = (list) => list.map((item) => {
  const obj = {
    value: item.id,
    label: item.name || item.title,
    key: item.id
  };
  return obj;
}).filter((v, i, a) => a.findIndex((t) => (t.value === v.value)) === i) || [];

export default listFormatter;
