/* eslint-disable no-underscore-dangle */
import React, { Fragment, useEffect, useState } from 'react';
import {
  message,
  Modal,
  Divider,
  Spin,
  Row,
  Space,
  Form,
  Input,
  Select,
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';

import { ButtonV2 } from 'components';
import { calcCropParams } from 'components/upload-cropper';
import {
  getVendors,
  getApplications,
  getPaginationVendors,
} from 'models/catalog/actions';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import {
  get as oGet,
  post as oPost,
  remove as oDelete,
  put as oPut,
  patch as oPatch,
  errorMessageCatcher,
} from 'utils/request';
import jsonToFormData from 'utils/json-to-form-data';
import getFileBinary from 'utils/antd-helpers/get-file-binary';

import { getUserRole, ROLE_ADMIN, getAccessData } from 'utils/auth';
import { stageOptions } from 'utils/consts';
import { writeVendorReportFile } from 'utils/xlsx/writeXlsx';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { useDebounce } from 'react-use';
import { Link } from 'react-router-dom';
import AdminVendorForm from './form';
import AdminVendorsPaneTable from './table';

import './style.less';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

/* TEMP create functions */
export const createVendor = (data) => {
  const url = '/vendors';

  return oPost(
    `${url}`,
    data,
    {
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const updateVendor = (data, id) => {
  const url = `/vendors/${id}`;

  return oPut(
    `${url}`,
    data,
    {
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const deleteVendor = (id) => {
  const url = `/vendors/${id}`;

  return oDelete(
    url,
    {
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
      accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const updateVendorsList = (data) => {
  const url = '/vendors/update/orders';

  return oPut(
    `${url}`,
    JSON.stringify(data),
    {
      accept: 'application/json',
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const getVendorsReport = () => {
  const url = '/vendors';

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API,
    { stage: 'draft,published' }
  );
};

const changeStage = (data, id) => {
  const url = `/vendors/${id}/stage`;

  return oPatch(
    url,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
  stage: 'draft,published',
};

const AdminVendorsPane = () => {
  const dispatch = useDispatch();

  const userRole = getUserRole();
  const user = getAccessData();
  const [filterForm] = Form.useForm();
  const [skipDebounce, setSkipDebounce] = useState(true);
  const [searchTitle, setSearchTitle] = useState('');

  const [modalConfig, setModalConfig] = useState({ modalOpen: false });
  const [applicationModalIsOpen, setApplicationModalIsOpen] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(undefined);
  const [vendorApplications, setVendorApplications] = useState([]);
  const [changesCounter, setChangesCounter] = useState(0);
  const [removeConfig, setRemoveConfig] = useState({ open: false });
  const [vendorsParams, setVendorsParams] = useState(DEFAULT_PARAMS);
  const [changeStageConfig, setChangeStageConfig] = useState({ open: false });
  const [modalLoading, setModalLoading] = useState(false);

  const { items, totalCount, loading } = useSelector(
    (state) => state.catalog.paginationVendors
  );
  const vendors = useSelector((state) => state.catalog.vendors);

  useEffect(() => {
    dispatch(getVendors({ stage: 'draft,published' }));
  }, [dispatch, changesCounter]);

  useEffect(() => {
    dispatch(getPaginationVendors(vendorsParams));
  }, [dispatch, changesCounter, vendorsParams]);


  const onAddVendorForm = async (model) => {
    setModalLoading(true);
    const { logo, completedCrop, ...rest } = model;

    const logoCropParams = calcCropParams(completedCrop);

    const formData = jsonToFormData({
      ...rest,
      logo: getFileBinary(logo),
      logoCropParams,
    });

    await createVendor(formData).then(() => {
      message.success({
        content: 'Вендор добавлен',
        icon: <CheckOutlined />,
      });
      setModalConfig({ openModal: false });
      setChangesCounter((state) => state + 1);
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onEditVendorForm = async (model, id) => {
    setModalLoading(true);
    const {
      logo, completedCrop, cropParameters, ...rest
    } = model;

    const logoCropParams = calcCropParams(completedCrop);

    const formData = jsonToFormData({
      ...rest,
      logo: typeof logo !== 'string' ? getFileBinary(logo) : undefined,
      logoCropParams: logoCropParams || cropParameters,
    });

    await updateVendor(formData, id).then(() => {
      message.success({
        content: 'Вендор обновлён',
        icon: <CheckOutlined />,
      });
      setChangesCounter((state) => state + 1);
      setModalConfig({ openModal: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const handleDeleteVendorClick = async (id, hasApplication = false) => {
    setModalLoading(true);
    const content = hasApplication
      ? 'Вендор и связанные с ним приложения удалены'
      : 'Вендор удалён';
    await deleteVendor(id).then(() => {
      message.success({
        content,
        icon: <CheckOutlined />,
      });
      setChangesCounter((state) => state + 1);
      setRemoveConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const openModal = (defaultValue) => {
    setModalConfig({ modalOpen: true, defaultValue });
  };

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setModalConfig({ modalOpen: false });
  };

  const handleChangeStage = async (vendor) => {
    setModalLoading(true);
    const stage = vendor.stage === 'draft' ? 'published' : 'draft';
    await changeStage({ stage }, vendor.id).then(() => {
      message.success({
        content: 'Вендор обновлен',
        icon: <CheckOutlined />,
      });
      setChangesCounter((state) => state + 1);
      setChangeStageConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const openApplicationsModal = (vendor) => {
    setCurrentVendor(vendor);
    setApplicationModalIsOpen(true);
  };

  const closeApplicationsModal = () => {
    setApplicationModalIsOpen(false);
    setCurrentVendor(null);
  };

  const applicationsByVendor = useSelector(
    (state) => state.catalog.applications
  );

  useEffect(() => {
    setVendorApplications(applicationsByVendor);
  }, [applicationsByVendor]);

  useEffect(() => {
    if (!currentVendor?.id) {
      setVendorApplications([]);
      return;
    }

    dispatch(
      getApplications({ vendorId: currentVendor.id, stage: 'published,draft' })
    );
  }, [dispatch, currentVendor]);

  useEffect(() => {
    if (!removeConfig.vendor?.applicationsCount) {
      return;
    }
    dispatch(
      getApplications({
        vendorId: removeConfig.vendor.id,
        stage: 'draft,published',
      })
    );
  }, [removeConfig.vendor, dispatch]);

  const getItems = (item) => {
    const removeVendor = () => {
      setRemoveConfig({ open: true, vendor: item });
    };
    const changeVendorStage = () => {
      if (item?.applicationsCount && item.stage === 'published') {
        setChangeStageConfig({ open: true, vendor: item });
      } else {
        handleChangeStage(item);
      }
    };
    const publishItemText = item.stage === 'draft' ? 'Опубликовать' : 'Снять с публикации';
    const hasAccess = userRole === ROLE_ADMIN || item.userCreator === user.id;
    const dropdownItems = [
      {
        key: '1',
        label: <div>Редактировать</div>,
        disabled: !hasAccess,
        onClick: () => openModal(item),
      },
      {
        key: '2',
        label: <div>{publishItemText}</div>,
        disabled: !hasAccess,
        onClick: changeVendorStage,
      },
    ];

    if (item.stage === 'draft') {
      dropdownItems.push({
        key: '4',
        label: <div>Удалить</div>,
        disabled: !hasAccess,
        onClick: removeVendor,
      });
    }
    if (item.stage === 'published') {
      dropdownItems.push({
        key: '3',
        label: <div>Связанные приложения</div>,
        disabled: !item.applicationsCount,
        onClick: () => openApplicationsModal(item),
      });
    }
    return dropdownItems;
  };

  const setVendorsSortHandler = (sort) => {
    setVendorsParams((prev) => ({
      ...prev,
      sort,
    }));
  };

  const onPaginationChange = (params) => {
    setVendorsParams((prev) => ({ ...prev, ...params }));
  };

  const handleReportVendors = () => {
    getVendorsReport().then((response) => writeVendorReportFile(response));
  };

  const onStageChange = (stage) => {
    setVendorsParams((prev) => ({ ...prev, stage, page: 1 }));
  };

  const resetFields = () => {
    setVendorsParams(DEFAULT_PARAMS);
  };

  const onFinish = ({ stage = 'draft', ...model }) => {
    if (modalConfig.defaultValue) {
      const preparedData = { ...model };
      onEditVendorForm(preparedData, modalConfig.defaultValue.id);
    } else {
      const preparedData = { ...model, stage };
      onAddVendorForm(preparedData);
    }
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setVendorsParams((prev) => ({ ...prev, name: searchTitle, page: 1 }));
    },
    500,
    [searchTitle]
  );

  const removeCancel = () => {
    if (modalLoading) {
      return;
    }
    setRemoveConfig({ open: false });
  };

  const changeCancel = () => {
    if (modalLoading) {
      return;
    }
    setChangeStageConfig({ open: false });
  };

  return (
    <div className="admin-form admin-vendors-pane">
      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Form form={filterForm} className="filter">
          <Space>
            <Form.Item name="name" className="filter-item filter-search">
              <Input
                placeholder="Поиск по названию"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item name="stage" className="filter-item filter-select">
              <Select
                placeholder="Выберите статус"
                popupClassName="admin-select-dropdown"
                options={stageOptions}
                onChange={onStageChange}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                filterForm.resetFields();
                resetFields();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
        </Form>
        <Space>
          <ButtonV2
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleReportVendors}
          >
            Сформировать отчёт
          </ButtonV2>
          <ButtonV2
            type="link"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Добавить новую запись
          </ButtonV2>
          {modalConfig.modalOpen && (
            <AdminVendorForm
              {...modalConfig}
              onClose={closeModal}
              vendors={vendors}
              onFinish={onFinish}
              requestLoading={modalLoading}
            />
          )}
        </Space>
      </Row>

      <Spin indicator={loadingIcon} spinning={loading}>
        <AdminVendorsPaneTable
          items={items}
          params={vendorsParams}
          totalCount={totalCount}
          onSortParams={setVendorsSortHandler}
          onChangeParams={onPaginationChange}
          getItems={getItems}
        />
      </Spin>

      <Modal
        width={710}
        open={removeConfig.open}
        okText="Удалить"
        onCancel={removeCancel}
        title="Удалить запись"
        footer={[
          <>
            <ButtonV2 onClick={removeCancel} disabled={modalLoading}>
              Отменить
            </ButtonV2>
            <ButtonV2
              type="primary"
              onClick={() => handleDeleteVendorClick(
                removeConfig.vendor.id,
                Boolean(removeConfig.vendor.applicationsCount)
              )}
              loading={modalLoading}
            >
              Удалить
            </ButtonV2>
          </>,
        ]}
        maskClosable
      >
        {removeConfig.vendor && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                {Boolean(removeConfig.vendor.applicationsCount) && (
                  <>
                    Связанные с вендором приложения будут удалены.
                    <br />
                  </>
                )}
                Вы уверены, что хотите удалить вендора "
                {removeConfig.vendor.name}
                "?
              </p>
            </div>
            {Boolean(removeConfig.vendor.applicationsCount) && (
              <Spin
                indicator={loadingIcon}
                spinning={!vendorApplications.length}
              >
                <div style={{ textAlign: 'left', marginTop: 10 }}>
                  <div
                    className="vendor-related-app"
                    style={{ color: '#6F7C98' }}
                  >
                    Связанные приложения
                  </div>
                  <Divider className="vendor-related-app-divider" />
                  {vendorApplications?.map((application, index) => (
                    <Fragment key={application.id}>
                      <div className="vendor-related-app">
                        {application.title}
                      </div>
                      {index !== vendorApplications.length - 1 && (
                        <Divider className="vendor-related-app-divider" />
                      )}
                    </Fragment>
                  ))}
                </div>
              </Spin>
            )}
          </div>
        )}
      </Modal>

      <Modal
        width={710}
        title="Снять с публикации"
        open={changeStageConfig.open}
        onCancel={changeCancel}
        destroyOnClose
        onOk={() => handleChangeStage(changeStageConfig.vendor)}
        okButtonProps={{ loading: modalLoading }}
        cancelButtonProps={{ disabled: modalLoading }}
        okText="Снять с публикации"
        maskClosable
      >
        {changeStageConfig.vendor && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                Связанные с вендором приложения так же будут сняты с публикации
                <br />
                Вы уверены, что хотите снять с публикации
                <br />
                "
                {changeStageConfig.vendor.name}
                "?
              </p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        width={710}
        title={`Связанные приложения "${currentVendor?.name}"`}
        footer={null}
        destroyOnClose
        open={applicationModalIsOpen}
        onCancel={closeApplicationsModal}
        maskClosable
      >
        <div className="admin_category_applications">
          <div className="centred">
            <Spin
              indicator={loadingIcon}
              spinning={!vendorApplications.length}
            />
          </div>
          {vendorApplications.map((application) => (
            <Fragment key={application.id}>
              {application.stage === 'published' ? (
                <Link
                  className="related-app"
                  to={`/catalog/${application.vendor.slug}/${application.slug}`}
                >
                  <div className="related-app-link">
                    <span>{application.title}</span>
                    <ArrowRightOutlined />
                  </div>
                </Link>
              ) : (
                <div className="draft-related-app">
                  <span className="gray">{application.title}</span>
                </div>
              )}
              <Divider className="related-app-divider" />
            </Fragment>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AdminVendorsPane;
