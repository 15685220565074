const onlySymbolsRegex = /[*%=@&^#\\/|+-]$/;
const conventQuilToText = (quil) => quil.replace(/<(.|\n)*?>/g, '');

export const whitespaceQuil = {
  validator: (_, value) => ((conventQuilToText(value).trim().length === 0 && conventQuilToText(value).length > 0)
    ? Promise.reject(new Error('Поле может содержать только текст'))
    : Promise.resolve()),
};

export const rulesQuillValidationOne = [
  whitespaceQuil,
  {
    validator: (_, value) => (onlySymbolsRegex.test(conventQuilToText(value))
      ? Promise.reject(new Error('Поле может содержать только текст'))
      : Promise.resolve()),
  },
];

export const requiredQuill = {
  validator: (_, value) => ((conventQuilToText(value).length === 0)
    ? Promise.reject(new Error('Поле "Описание" обязательно!'))
    : Promise.resolve()),
};

export const maxLength = (length, fieldName) => ({
  validator: (_, value) => ((conventQuilToText(value || '').length > length)
    ? Promise.reject(new Error(`Поле ${fieldName || ''} не должно содержать больше ${length} символов`))
    : Promise.resolve()),
});
