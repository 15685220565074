import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { CardLogo, PaginationV2 } from 'components';
import AppBlocksMobile from 'assets/images/catalog/analog-bg-mobile.svg';
import AnalogBg from 'assets/images/catalog/analog-bg.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationsWithAnalog, getApplicationsWithAnalogSet } from 'models/catalog/actions';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonV2 from 'components/button-v2';
import TagComponent from '../tag-component/components';

import './style.less';


const columns = [
  {
    title: 'Название ПО',
    dataIndex: 'mainImageUrl',
    render: (mainImageUrl, record) => (
      mainImageUrl
        ? (
          <div className="analogs-catalog__table-block">
            <img className="analogs-catalog__table-cell__icon" src={mainImageUrl} alt="application-logo" />
            <p className="analogs-catalog__table-block__title">{record.title}</p>
          </div>
        )
        : (
          <div className="analogs-catalog__table-block">
            <CardLogo className="analogs-catalog__table-cell__icon" label={record.title} />
            <p className="analogs-catalog__table-block__title">{record.title}</p>
          </div>
        )
    ),
  },
  {
    title: 'Импортный аналог',
    dataIndex: 'analogs',
    render: (analogs) => (
      analogs.map((analog) => <TagComponent key={analog.name}>{analog.name}</TagComponent>)
    ),
  },
];


const AnalogsCatalog = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pageSizeSet, setPageSizeSet] = useState(6);

  const applicationsWithAnalog = useSelector((state) => state.catalog.applicationsWithAnalog);
  const applicationsWithAnalogSet = useSelector((state) => state.catalog.applicationsWithAnalogSet);

  useEffect(() => {
    dispatch(getApplicationsWithAnalog({ page: currentPage, pageSize }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getApplicationsWithAnalogSet({ pageSize: pageSizeSet }));
  }, [pageSizeSet]);

  const handleChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    dispatch(getApplicationsWithAnalog({ page, pageSize }));
  };

  const handleShowMoreClick = () => setPageSizeSet(pageSizeSet + 6);

  return (
    <div className="analogs-catalog">
      <div className="analogs-catalog__header">
        <div className="analogs-catalog__header-title">
          <h2 className="title">Российские аналоги</h2>
          <p className="main-text">
            Программное обеспечение разработано отечественным вендором и является аналогом для импортного ПО
          </p>
        </div>
        <div className="analogs-catalog__header-icon">
          <img className="analogs-catalog__header-icon-inner" src={AnalogBg} alt="" />
        </div>
        <div className="analogs-catalog__header-icon-mobile">
          <img className="analogs-catalog__header-icon-mobile-inner" src={AppBlocksMobile} alt="" />
        </div>
      </div>

      <div className="analogs-catalog__body">
        {applicationsWithAnalog.list === false && (
          <LoadingOutlined style={{ fontSize: 40 }} spin />
        )}

        {applicationsWithAnalog.list !== false && (
          <div className="analogs-catalog__body__table">
            <Table
              columns={columns}
              dataSource={applicationsWithAnalog.list}
              pagination={false}
              rowClassName={(record, index) => (index % 2 === 0 ? 'ant-table__odd-row' : 'ant-table__even-row')}
            />

            <div style={{ marginTop: '40px' }} />

            <PaginationV2
              total={applicationsWithAnalog.total}
              data={applicationsWithAnalog.list}
              currentPage={currentPage}
              pageSize={pageSize}
              onChangePage={handleChangePage}
            />
          </div>
        )}
      </div>

      <div className="analogs-catalog__body-mobile">
        {applicationsWithAnalogSet === false && (
          <LoadingOutlined style={{ fontSize: 40 }} spin />
        )}
        {applicationsWithAnalogSet !== false && (
          applicationsWithAnalogSet.list?.map((app) => (
            <div key={app.id} className="analogs-catalog__body-mobile__block">
              <div className="header">
                {app.mainImageUrl
                  ? (
                    <img
                      className="analogs-catalog__table-cell__icon"
                      src={app.mainImageUrl}
                      alt="application-logo"
                    />
                  )
                  : <CardLogo className="analogs-catalog__table-cell__icon" label={app.title} />}
                <h2>{app.title}</h2>
              </div>
              <div className="content">
                <p>Импортные аналоги:</p>
                {app.analogs.map((analog) => (
                  <TagComponent key={analog.name}>{analog.name}</TagComponent>
                ))}
              </div>
            </div>
          ))
        )}
        <ButtonV2
          disabled={applicationsWithAnalogSet.list?.length === applicationsWithAnalogSet.total}
          onClick={handleShowMoreClick}
        >
          Показать еще
        </ButtonV2>
      </div>
    </div>
  );
};

export default AnalogsCatalog;
