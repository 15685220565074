import React, { useMemo, useState, useEffect } from 'react';
import { Dropdown, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { tablePaginationOptions } from 'utils/consts';

import { getSortField } from 'utils/get-sort-field';
import { SortDropdown, getSortMenu } from 'components/sort-dropdown';


const AdminAuthorsPaneTable = ({
  items,
  params,
  totalCount,
  onSortParams,
  onChangeParams,
  setRemoveConfig,
  setReportConfig,
  roleRu,
}) => {
  const [sortParams, setSortParams] = useState({});

  useEffect(() => {
    onSortParams(getSortField(sortParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParams]);

  const setSortParamsHandler = (field, sort) => {
    setSortParams((prev) => {
      const sortResult = prev[field] !== sort ? sort : null;

      return { [field]: sortResult };
    });
  };

  const onPaginationChange = ({ current: page, pageSize: limit }) => {
    onChangeParams({ page, limit });
  };

  const showTotal = (total, range) => {
    const allItemsText = `Всего записей: ${total}`;
    const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
    return (
      <div className="pagination-showTotal">
        <div>{allItemsText}</div>
        <div>{rangeItemsText}</div>
      </div>
    );
  };

  const getFullName = (item) => `${item.surname} ${item.name} ${item.patronymic}`;

  const paginationParams = {
    showTotal,
    total: totalCount,
    current: params.page,
    pageSize: params.limit,
    pageSizeOptions: tablePaginationOptions,
    showSizeChanger: true,
  };

  const FULL_NAME_SORT_MENU = getSortMenu({
    field: 'fullname',
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });

  const PLACE_SORT_MENU = getSortMenu({
    field: 'organization',
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });

  const ROLE_ASSIGNMENT_DATE_SORT_MENU = getSortMenu({
    field: 'roleAssignmentDate',
    ascendLabel: 'Сначала старые',
    descendLabel: 'Сначала новые',
    onSort: setSortParamsHandler,
  });

  const columns = [
    {
      title: 'ID записи',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: () => (
        <div className="table-sort-header">
          ФИО
          <SortDropdown
            items={FULL_NAME_SORT_MENU}
            order={sortParams.fullname}
          />
        </div>
      ),
      render: (item) => getFullName(item),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (role) => roleRu[role],
    },
    {
      title: () => (
        <div className="table-sort-header">
          Организация
          <SortDropdown
            items={PLACE_SORT_MENU}
            order={sortParams.organization}
          />
        </div>
      ),
      dataIndex: 'work',
      key: 'work',
      render: (work) => work.place,
    },
    {
      title: () => (
        <div className="table-sort-header">
          Дата назначения
          <SortDropdown
            items={ROLE_ASSIGNMENT_DATE_SORT_MENU}
            order={sortParams.roleAssignmentDate}
          />
        </div>
      ),
      dataIndex: 'roleAssignmentDate',
      key: 'roleAssignmentDate',
      render: (roleAssignmentDate) => dayjs(roleAssignmentDate).format('DD.MM.YYYY'),
    },
    {
      width: 30,
      render: (record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: <div>Изъять роль</div>,
                onClick: () => setRemoveConfig({ open: true, author: record }),
              },
              {
                key: '2',
                label: <div>Отчёт</div>,
                onClick: () => setReportConfig({ open: true, author: record }),
              },
            ],
          }}
          trigger={['click']}
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ];

  const tableItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [items]);

  return (
    <Table
      columns={columns}
      total={totalCount}
      dataSource={tableItems}
      pagination={paginationParams}
      onChange={onPaginationChange}
    />
  );
};

export default AdminAuthorsPaneTable;
