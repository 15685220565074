/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import bem from 'easy-bem';
import { TabPane, Tabs } from 'components';
import { ReactComponent as ChatIcon } from 'assets/icons/admin-pane/chat.svg';

import './style.less';
import AdminAdvertisingPane from './components/admin-promos-pane';


const AdminPromoManagment = () => {
  const b = bem('admin-panels');
  const [activeTab, setActiveTab] = useState('advertising');

  return (
    <div className={b()}>
      <h2 className={b('title')}>Блог и реклама</h2>
      <div className="catalog-content">
        <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          <TabPane
            tab={(
              <div className="tabWithIcon">
                <ChatIcon />
                <span>Рекламная подборка</span>
              </div>
            )}
            key="advertising"
          >
            {activeTab === 'advertising' && <AdminAdvertisingPane />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminPromoManagment;
