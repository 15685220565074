import React from 'react';
import bem from 'easy-bem';
import { useTranslation } from 'react-i18next';


const CatalogSidebarItemsSkeleton = () => {
  const cn = bem('catalog-sidebar');
  const { t } = useTranslation('catalog');

  return (
    <div className={cn('categories categories-skeleton')}>
      <h2 className={cn('subtitle')}>{t('industry-categories')}</h2>
      {
        Array(11).fill().map(() => (
          <div className="skeleton-row">
            <div className="skeleton-item" />
          </div>
        ))
      }
    </div>
  );
};

export default CatalogSidebarItemsSkeleton;
