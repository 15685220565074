import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import i18n from '../../i18n/i18n';

// import AuthProvider from '../auth-provider';
import PageWrapper from '../page-wrapper';

import store from '../../store/store';

import 'antd/dist/antd.css';
import './style.less';


const App = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      {/* <AuthProvider> */}
      <PageWrapper />
      {/* </AuthProvider> */}
    </Provider>
  </I18nextProvider>
);

export default App;
