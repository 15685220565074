import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import bem from 'easy-bem';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, List, Card } from 'antd';
import { errorMessageCatcher, get as oGet, post as oPost } from 'utils/request';

import { getApplicationById, getApplicationFeedbacks } from 'models/catalog/actions';
import { ReactComponent as HelpIcon } from 'assets/icons/help-circle-contained.svg';

import {
  ButtonV2, Tabs, TabPane, Modal, CardLogo
} from 'components';

import { ReactComponent as ArrowRightUpIcon } from 'assets/icons/link-external.svg';

import SuccessIcon from 'assets/images/catalog/cube.png';

import {
  getAccessData,
  getUserRole,
  ROLE_ADMIN,
  ROLE_AUTHOR,
} from 'utils/auth';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';

import CardLogoBordered from 'components/card-logo-bordered';

import { phoneMask } from 'utils/regexp';
import dayjs from 'dayjs';

import NeedConsultationModalForm from '../need-consultation-modal/component';
import UserReviews from '../user-reviews';

import './style.less';


export const sendApplicationStatistic = (data) => {
  const url = '/applications-statistic';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const getApplicationPackageUrl = (id) => {
  const url = `/applications/${id}/package-presigned-url`;

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const createDeal = (data) => {
  const url = '/deal';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const sendConsultation = (data) => {
  const url = '/consultation';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

const tabCategories = ['characteristics', 'analogs'];

const ApplicationOverview = () => {
  const b = bem('application-review');
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollToTop = useRef(null);
  const { appSlug } = useParams();

  const [isActiveTab, setIsActiveTab] = useState('');
  const [requestStatus, setRequestStatus] = useState({
    isLoading: false,
    isSuccess: false,
    link: '',
  });

  const [isConsultationOpen, setIsConsultationOpen] = useState(false);

  const [downloadWarning, setDownloadWarning] = useState({
    isOpen: false,
    isLoggedIn: false,
    description: '',
  });

  const [placeAnOrder, setPlaceAnOrder] = useState({
    isOpen: false,
    isLoggedIn: false,
    description: '',
  });

  const [form] = Form.useForm();

  const userRole = getUserRole();
  const user = getAccessData();

  const application = useSelector((state) => state.catalog.application);

  const canDownload = useMemo(() => {
    const isAdmin = userRole === ROLE_ADMIN;
    let isAuthor = userRole === ROLE_AUTHOR;
    if (isAuthor) {
      isAuthor = application?.userCreator === user?.id;
    }

    return isAdmin || isAuthor || user?.isModerated;
  }, [userRole, user, application]);

  useEffect(() => {
    dispatch(getApplicationById(appSlug));
    scrollToTop?.current?.scrollIntoView();
  }, [dispatch, appSlug]);

  useEffect(() => {
    if (!application?.id) {
      return;
    }
    dispatch(getApplicationFeedbacks(application?.id));
  }, [dispatch, application?.id]);

  const handleOrderClick = async () => {
    if (user) {
      setPlaceAnOrder({
        isOpen: false,
        isLoggedIn: true,
        description:
          'Оформить заказ могут только авторизованные пользователи',
      });
    } else {
      setPlaceAnOrder({
        isOpen: true,
        isLoggedIn: false,
        description:
          'Оформить заказ могут только авторизованные пользователи. Войти в систему?',
      });
    }
  };

  const handleDownloadDemoClick = async () => {
    if (canDownload) {
      sendApplicationStatistic({ id: application?.id });
      window.open(application.packages[0].fullUrl);
    } else if (user) {
      setDownloadWarning({
        isOpen: true,
        isLoggedIn: true,
        description:
          'Скачивание ПО доступно только авторизованным пользователям',
      });
    } else {
      setDownloadWarning({
        isOpen: true,
        isLoggedIn: false,
        description:
          'Скачивание ПО доступно только авторизованным пользователям. Войти в систему?',
      });
    }
  };

  const handleLoginClick = () => {
    setDownloadWarning({ isOpen: false });
    setPlaceAnOrder({ isOpen: false });

    if (window.unione) {
      window.unione.goToAuth();
    }
  };

  if (application === false) history.push('/not-found');

  const handleCategorySearch = (slug) => {
    history.push(`/catalog/search?category=${slug}`);
  };

  const isSliderVisible = useMemo(() => {
    let visible = true;
    tabCategories.map((item) => {
      if (isActiveTab === item) {
        visible = false;
      }
    });
    return visible;
  }, [isActiveTab]);

  const onSendNeedConsulationForm = async (model) => {
    const productTitle = application.title;
    const link = window.location.href;
    await sendConsultation({ ...model, productTitle, link });
  };

  const handleOnFinish = async () => {
    setRequestStatus({ isLoading: true });
    try {
      await createDeal({
        applicationId: application?.id,
      }).then((response) => {
        setRequestStatus({
          isLoading: false,
          isSuccess: true,
          link: response.paymentLink || '',
        });
        setPlaceAnOrder({ isOpen: false });
        form.resetFields();
      });
    } catch {
      errorMessageCatcher({ message: 'Заказ не оформлен. Попробуйте позже' });
      setRequestStatus({ isLoading: false });
    }
  };

  const handleCancelLoginClick = () => {
    setDownloadWarning({ isOpen: false });
    setPlaceAnOrder({ isOpen: false });
  };

  const characteristics = useMemo(() => {
    const dataModificator = (nomination, complectsType) => `${nomination} (${complectsType})`;

    return {
      operationSystem: application?.operationSystem
        ?.map((item) => item?.value)
        .join(', '),
      language: application?.language?.map((item) => item?.value).join(', '),
      complectsOfDelivery: application?.complectsOfDelivery
        ?.map((item) => dataModificator(item.nomination?.value, item.complectsType?.value))
        .join(', '),
      territoryActivation: application?.territoryActivation
        ?.map((item) => item?.value)
        .join(', '),
    };
  }, [application]);

  const colors = [
    { bg: '#E5F5FF', color: '#0071CE' },
    { bg: '#E9E4FF', color: '#7B61FF' },
    {
      bg: '#D7F4D0',
      color: '#40BA21',
    },
  ];
  const categoryColors = [
    { bg: '#FFF6F3', color: '#FF7139' },
    { bg: '#F1FBF9', color: '#17B69C' },
    {
      bg: '#F7F6FF',
      color: '#7B61FF',
    },
  ];

  const navigateToAnalogs = () => {
    history.push('/catalog/analogs');
  };

  const getBgColorCategoriesByIndex = (index) => categoryColors[index % colors.length].bg;
  const getColorCategoriesByIndex = (index) => categoryColors[index % colors.length].color;

  const showPriceBadge = application?.paymentType && application.paymentType !== 'no-price';

  const priceBadge = useMemo(() => {
    if (!application) {
      return '';
    }
    if (application.paymentType === 'free') {
      return 'бесплатно';
    }
    if (application.paymentType === 'price-on-request') {
      return 'по запросу';
    }
    if (application.paymentType === 'specified') {
      return `${application.price} ₽`;
    }
    return application.price;
  }, [application]);


  return (
    <div className={b()}>
      {application && (
        <div className="catalog-content">

          <div ref={scrollToTop} className={b('info')}>
            <div
              className={
                application.mainImageUrl ? 'app-image' : 'app-image-logo'
              }
            >
              {application.mainImageUrl ? (
                <div className="img-wrapper">
                  <img src={application.mainImageUrl} alt="Лого приложения" />
                </div>
              ) : (
                <CardLogoBordered label={application.title} />
              )}
              {showPriceBadge && (
                <div className="app-image__category">
                  <p>{priceBadge}</p>
                </div>
              )}
            </div>
            <div className="app-content">
              <div>
                <h1 className="title">{application.title}</h1>
              </div>
              <div className="content-text">
                <p className="main-text">{application.descriptionShort}</p>
              </div>
              <div className="content-actions">
                <div>
                  <ButtonV2
                    className="content-action__button"
                    type="primary"
                    onClick={handleOrderClick}
                  >
                    <span>Отправить заявку</span>
                  </ButtonV2>
                  {application.packages?.[0]?.fullUrl && (
                    <ButtonV2
                      className="content-action__button content-action__button--flat"
                      onClick={handleDownloadDemoClick}
                    >
                      Скачать Демо
                    </ButtonV2>
                  )}
                </div>
                {application.siteUrl && (
                  <ButtonV2 className="redirect">
                    <a
                      className="content-action__link"
                      href={application.siteUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Перейти на сайт приложения
                    </a>
                    <ArrowRightUpIcon />
                  </ButtonV2>
                )}
              </div>
            </div>
          </div>

          <div className={b('tabs')}>
            <Tabs onChange={(e) => setIsActiveTab(e)} defaultActiveKey={isActiveTab} type="card">
              {application.descriptions.map((app, id) => (
                <TabPane tab={app.titleShort} key={id} hideAdd>
                  <div className="inner-tab-block">
                    <div className="inner-tab-content">
                      <div
                        className="tab-text ql-editor"
                        dangerouslySetInnerHTML={{ __html: app.content }}
                      />
                      <div className="categories-list">
                        {isSliderVisible
                          && application.categories?.map((item, id) => (
                            <div
                              key={item.id}
                              onClick={() => handleCategorySearch(item.slug)}
                              className="categories-item"
                              style={{
                                backgroundColor:
                                  getBgColorCategoriesByIndex(id),
                                color: getColorCategoriesByIndex(id),
                              }}
                            >
                              <p className="categories-item__title">
                                {item.title}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </TabPane>
              ))}
              <TabPane tab="Характеристики" key="characteristics">
                <div className="character">
                  <div className="character-main-block character-main-block__mr">
                    <div className="character-header">
                      <span className="character-main-block__title">
                        Основные характеристики
                      </span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">Вендор:</span>
                      <span>{application.vendor.name}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">
                        Категория:
                      </span>
                      <span>
                        {application.categories
                          .map((category) => category.title)
                          .join(', ')}
                      </span>
                    </div>
                    {Boolean(application.tags?.length) && (
                      <div className="character-item">
                        <span className="general-title">Теги:</span>
                        <span>{application.tags.map((el) => el.value)?.join(', ')}</span>
                      </div>
                    )}
                    {application.registry?.numOfRegistry && (
                      <>
                        <div className="character-item">
                          <span className="general-title">
                            № реестровой записи в реестре отечественного ПО:
                          </span>
                          <span>{application.registry.numOfRegistry}</span>
                        </div>
                        <div className="character-item">
                          <span className="general-title">
                            Дата регистрации отечественного ПО
                          </span>
                          <span>
                            {dayjs(application.registry.dateOfRegistry).format(
                              'DD.MM.YYYY'
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="character-main-block">
                    <div className="character-header">
                      <span className="character-main-block__title">
                        Характеристики
                      </span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">Установочный пакет:</span>
                      <span>{application.packages?.length ? 'Да' : 'Нет'}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">Артикул:</span>
                      <span>{application.article}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">
                        Территория активации:
                      </span>
                      <span>{characteristics.territoryActivation}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">Язык:</span>
                      <span>{characteristics.language}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">Комплект поставки:</span>
                      <span>{characteristics.complectsOfDelivery}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">
                        Операционная система:
                      </span>
                      <span>{characteristics.operationSystem}</span>
                    </div>
                    <div className="character-item">
                      <span className="general-title">
                        Срок действия лицензии:
                      </span>
                      <span>
                        {application.licenseExpires?.licenseType === 'unlimit'
                          ? 'Бессрочно'
                          : `${application.licenseExpires?.value} ${application.licenseExpires?.unit?.value}`}
                      </span>
                    </div>
                    {application.devicesCount && (
                      <div className="character-item">
                        <span className="general-title">
                          Количество устройств:
                        </span>
                        <span>{application.devicesCount}</span>
                      </div>
                    )}
                  </div>
                </div>
              </TabPane>
              {application.analogs?.length && (
                <TabPane tab="Аналоги" key="analogs">
                  <p className="analogs_desc">
                    Программное обеспечение разработано отечественным вендором и является аналогом для импортного ПО
                  </p>
                  <div className="inner-tab-content inner-tab-content--width">
                    <List
                      dataSource={application.analogs}
                      renderItem={({ name }) => (
                        <List.Item className="tab-list-item hover-color" onClick={navigateToAnalogs}>
                          <div className="tab-list-item__name">
                            <CardLogo label={name} className="tab-list-logo" />
                            {name}
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                </TabPane>
              )}
            </Tabs>
            <UserReviews />
          </div>

          <div className={b('consultation')}>
            <div className="consultation-content">
              <h2 className="header">Нужна консультация?</h2>
              <div className="description">
                Напишите нам и мы ответим на все интересующие вас вопросы
              </div>
            </div>
            <div className="consultation-actions">
              <ButtonV2
                type="primary"
                onClick={() => setIsConsultationOpen(true)}
                icon={<HelpIcon className={b('btn-icon')} />}
                className="consultation-action-btn"
              >
                Задать вопрос
              </ButtonV2>
            </div>
          </div>
        </div>
      )}

      <NeedConsultationModalForm
        onFinish={onSendNeedConsulationForm}
        isOpen={isConsultationOpen}
        setIsOpen={setIsConsultationOpen}
      />

      <Modal
        className="download-modal"
        title="Скачать ПО"
        visible={downloadWarning.isOpen || placeAnOrder.isOpen}
        onCancel={handleCancelLoginClick}
        footer={[
          <>
            {downloadWarning.isLoggedIn && (
              <ButtonV2 type="primary" onClick={handleCancelLoginClick}>
                Хорошо
              </ButtonV2>
            )}

            {!downloadWarning.isLoggedIn && (
              <>
                <ButtonV2 onClick={handleCancelLoginClick}>Не сейчас</ButtonV2>
                <ButtonV2 type="primary" onClick={handleLoginClick}>
                  Войти
                </ButtonV2>
              </>
            )}
          </>,
        ]}
      >
        <div className="content">
          <img
            src="/images/download-progress-image.svg"
            alt="download-access-denied"
          />
          <p className="content-text">
            {downloadWarning.description}
            {' '}
            {placeAnOrder.description}
          </p>
        </div>
      </Modal>

      <Modal
        className={b('order-modal')}
        visible={placeAnOrder.isLoggedIn}
        onCancel={() => setPlaceAnOrder({ isOpen: false })}
        closable
        footer={false}
      >
        <header>
          <h3 className={b('order-modal__title')}>Оформление заявки</h3>
        </header>
        <div className={b('order-modal__header')}>
          {application?.article && (
            <span>
              {application?.article}
            </span>
          )}
          <p>
            {application?.title}
          </p>
        </div>
        <Card title="Данные заявителя" className={b('order-modal__card')} bordered={false}>
          <div className={b('order-modal__card__row')}>
            <p>Имя</p>
            <p>{[user?.surname, user?.name, user?.patronymic].join(' ')}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>E-mail</p>
            <p>{user?.email}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Телефон</p>
            <p>{phoneMask(user?.phone)}</p>
          </div>
        </Card>
        <Card title="Данные заказчика" className={b('order-modal__card')} bordered={false}>
          <div className={b('order-modal__card__row')}>
            <p>Территория активации</p>
            <p>{characteristics.territoryActivation}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Язык</p>
            <p>{characteristics.language}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Операционная система</p>
            <p>{characteristics.operationSystem}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Количество устройств</p>
            <p>{application?.devicesCount}</p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Срок действия лицензии</p>
            <p>
              {
                application?.licenseExpires?.value
                  ?? application?.licenseExpires.licenseType === 'unlimit' ? 'бессрочно' : '-'
              }
              {' '}
              {application?.licenseExpires?.unit?.value}
            </p>
          </div>
          <div className={b('order-modal__card__row')}>
            <p>Комплект поставки</p>
            <p>{characteristics.complectsOfDelivery}</p>
          </div>
        </Card>

        <div className={b('order-modal__footer')}>
          <ButtonV2
            className={b('order-modal__btn', { cancel: true })}
            onClick={() => setPlaceAnOrder({ isOpen: false })}
          >
            Отмена
          </ButtonV2>
          <ButtonV2
            className={b('order-modal__btn')}
            loading={requestStatus.isLoading}
            type="primary"
            onClick={handleOnFinish}
          >
            Отправить заявку
          </ButtonV2>
        </div>
      </Modal>

      <Modal
        title="Заказ получен"
        className={b('modal-success')}
        visible={requestStatus.isSuccess}
        footer={false}
        onCancel={() => setRequestStatus({ isSuccess: false })}
        closable={false}
      >
        <div className={b('success-block')}>
          <i>
            <img width={182} src={SuccessIcon} alt="" />
          </i>
          <h4>Мы получили ваш заказ</h4>
          <span>
            В ближайшее время с Вами свяжется сотрудник
            <br />
            {' '}
            Университета "Иннополис"
          </span>
          {!!requestStatus.link?.length && (
            <span className="success-link">
              Ссылка на оплату:
              <a href={requestStatus.link} target="_blank" rel="noreferrer">
                {requestStatus.link}
              </a>
            </span>
          )}
          <div className="success-footer">
            <ButtonV2
              onClick={() => setRequestStatus({ isSuccess: false })}
              type="primary"
            >
              Отлично!
            </ButtonV2>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApplicationOverview;
