import React, { useState } from 'react';

import InputV3 from 'components/input-v3';
import {
  Checkbox, Form, message, Tooltip
} from 'antd';

import { ReactComponent as SuccessIcon } from 'assets/icons/partner/success-icon.svg';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  emailValidation, nameValidation, phoneValidation, phoneValidationInternational
} from 'utils/form';

import AcceptFile from 'assets/docs/accept.pdf';

import bem from 'easy-bem';
import ButtonV2 from 'components/button-v2';
import Modal from 'components/modal';

import './style.less';
import { errorMessageCatcher } from 'utils/request';


const validateMessages = {
  required: 'Поле "${label}"обязательно!',
  types: {
    email: false,
  },
};

const nameRules = {
  rules: [{ type: 'string', required: true }, { pattern: nameValidation, message: 'Поле может содержать только текст' }],
};

const emailRules = {
  rules: [{ type: 'email', required: true }, { pattern: emailValidation, message: 'Введён некорректный E-mail' }],
};

const phoneRules = {
  rules: [{ pattern: phoneValidation, message: 'Введён некорректный номер телефона!' }],
};

const VendorsCatalogForm = ({ isOpen, setIsOpen, onFinish }) => {
  const b = bem('vendors-catalog');
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [numberValue, setNumberValue] = useState('');
  const [validatePhoneText, setValidatePhoneText] = useState('');
  const [isErrorValidate, setIsErrorValidate] = useState(true);

  const handleOnFinish = async (model) => {
    try {
      model.phone = numberValue;
      await onFinish(model);
      setIsOpen(false);
      setIsSuccess(true);
      setIsValid(false);
      setNumberValue('+7');
      form.resetFields();
    } catch {
      errorMessageCatcher({ message: 'Письмо не доставлено. Попробуйте позже' });
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const toHomePage = () => setIsSuccess(false);

  const handleOnChange = () => {
    form.validateFields().then(() => {
    }).catch((e) => (e.errorFields.length <= 0 || !isErrorValidate ? setIsValid(true) : setIsValid(false)));
  };

  const isValidPhoneNumber = (value) => {
    setNumberValue(value);
    const validate = phoneValidationInternational.test(value);
    const errorText = value ? 'Введён некорректный номер телефона!' : 'Поле "телефон" обязательно!';
    setIsErrorValidate(!validate);
    setValidatePhoneText(!validate ? errorText : '');
  };

  return (
    <>
      <Modal
        className={b('modal-width')}
        visible={isOpen}
        onCancel={handleOnClose}
        footer={false}
      >
        <div className={b('content-block')}>
          <p className="modal-title">Оставьте заявку</p>
          <p className="modal-desc">Оставьте заявку или задайте свой вопрос об условиях сотрудничества. Мы свяжемся с вами чтобы обсудить детали.</p>

          <Form
            onValuesChange={handleOnChange}
            autoComplete="off"
            initialValues={{ remember: false }}
            form={form}
            validateMessages={validateMessages}
            onFinish={(model) => handleOnFinish(model)}
          >

            <div className="main-content">

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Имя
                  </span>
                </div>
                <Form.Item
                  className="full"
                  {...nameRules}
                  name="name"
                  required={false}
                >
                  <InputV3
                    placeholder="Ваше имя"
                  />
                </Form.Item>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Ваш e-mail
                  </span>
                </div>
                <Form.Item
                  className="full"
                  {...emailRules}
                  name="Email"
                  required={false}
                >
                  <InputV3
                    placeholder="E-mail"
                  />
                </Form.Item>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Номер телефона
                  </span>
                </div>
                <PhoneInput
                  international
                  defaultCountry="RU"
                  className="full"
                  placeholder="Ваш номер телефона"
                  value={numberValue}
                  onChange={isValidPhoneNumber}
                />
                <div className="error-validate__field">
                  {isErrorValidate && <span className="error-validate__phone">{validatePhoneText}</span>}
                </div>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Ссылка на сайт вендора/ПО
                  </span>
                </div>

                <Form.Item
                  name="link"
                  required={false}
                >
                  <InputV3
                    placeholder="https://"
                  />
                </Form.Item>
              </div>

              <div className="data-block">
                <div className="data-title">
                  <span>
                    Текст вопроса/заявки
                  </span>
                </div>
                <Form.Item
                  name="text"
                  required={false}
                >
                  <InputV3
                    textarea
                    autoSize={{ minRows: 3, maxRows: 4 }}
                    placeholder="Комментарий"
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Поле обязательно!'))),
                },
              ]}
              className="checkbox-block"
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>
                Я согласен с условиями обработки
                {' '}
                {' '}
                <a href={AcceptFile} target="_blank" rel="noreferrer">
                  персональных данных
                </a>
              </Checkbox>
            </Form.Item>

            <div className="footer-block">
              <ButtonV2 htmlType="submit" className={isValid ? 'button-valid' : 'button-disabled'}>
                Отправить
              </ButtonV2>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Каталог ПО"
        className={b('modal-success')}
        visible={isSuccess}
        footer={false}
        onCancel={toHomePage}
      >
        <div
          className={b('success-block')}
        >
          <i>
            <SuccessIcon />
          </i>
          <h4>Мы получили ваш запрос</h4>
          <span>В ближайшее время с Вами свяжется сотрудник Университета "Иннополис"</span>
          <div className="success-footer">
            <ButtonV2 onClick={toHomePage} type="primary">
              Отлично!
            </ButtonV2>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VendorsCatalogForm;
