import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkToken, checkUserRoles, getUserRole } from 'utils/auth';


const PrivateRoute = ({
  component: Component, accessToRoles, noAccessComponent: NoAccessComponent, noAccessFunc, ...rest
}) => {
  let renderCondition = checkToken() !== null;

  if (renderCondition && Array.isArray(accessToRoles)) {
    renderCondition = checkUserRoles(accessToRoles);
  }

  const userRole = getUserRole();
  if (noAccessFunc) {
    noAccessFunc();
  }

  let FinalComponent = ({ location }) => (
    <Redirect to={{
      pathname: '/login',
      state: { from: location },
    }}
    />
  );

  if (NoAccessComponent) {
    FinalComponent = NoAccessComponent;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        renderCondition ? (
          <Component {...props} userRole={userRole} />
        ) : (
          <FinalComponent {...props} userRole={userRole} />
        )
      )}
    />
  );
};

export default PrivateRoute;
