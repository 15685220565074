export const getSortField = (sorts) => (
  Object.entries(sorts).map(([key, value]) => {
    if (!value) {
      return '';
    }

    const sortType = value === 'ascend' ? 1 : -1;

    return `${key}, ${sortType};`;
  }).join(' ').trim()
);
