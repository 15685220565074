import React from 'react';
import './style.less';


const PromoCard = ({ label, icon, type }) => (
  <div className={`promo-card promo-card--${type}`}>
    <div className="promo-card__label">
      {label}
    </div>
    <div className="promo-card__icon">
      {icon}
    </div>
  </div>
);

export default PromoCard;
