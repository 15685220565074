import { call, put, takeLatest } from 'redux-saga/effects';
import * as c from './constants';
import * as a from './api';
import * as v from './converters';
import { serverErrorsLogger } from '../../utils/server-errors-logger';
import { setToken, removeToken } from '../../utils/auth';
import userManager from '../../utils/auth-oidc';
import * as cf from '../../utils/constant-factory';


function* getOIDCInfoSaga() {
  const [, success, failure] = cf.getActionsFromConstant(
    c.getOIDCInfo
  );

  try {
    const info = yield userManager.getUser();
    if (!info) {
      removeToken();
      yield put(failure({ message: 'Unauthorized' }));
      return;
    }

    setToken(info.access_token);

    yield put(success(info));
  } catch (e) {
    removeToken();
    serverErrorsLogger(e);
    yield put(failure({ message: e.message }));
  }
}

function* getUserInfoOdataSaga() {
  const [, success, failure] = cf.getActionsFromConstant(
    c.getUserInfoOdata
  );

  try {
    const rawUser = yield call(a.getUserInfoOdata);

    const payload = v.adaptOdataUser(rawUser);
    yield put(success(payload));
  } catch (e) {
    serverErrorsLogger(e);
    yield put(failure({ message: e.message }));
  }
}

function* checkUserOIDCOdata({ callback }) {
  const [, success, failure] = cf.getActionsFromConstant(
    c.checkUserV2
  );

  try {
    yield getOIDCInfoSaga();
    yield getUserInfoOdataSaga();
    yield put(success());
    callback(true);
  } catch (e) {
    serverErrorsLogger(e);
    yield put(failure({ message: e.message }));
    callback(false);
  }
}

function* setUserOrgOData(action) {
  const [, success, failure] = cf.getActionsFromConstant(
    c.setUserOrgOData
  );

  try {
    const { orgId, positionId, org } = action.payload;

    yield call(a.setUserOrgOData, orgId, positionId);

    yield put(success({ org }));
  } catch (e) {
    serverErrorsLogger(e);
    yield put(failure({ message: e.message }));
  }
}

function* removeUserOrgODataSaga() {
  const [, success, failure] = cf.getActionsFromConstant(
    c.removeUserOrgOData
  );

  try {
    yield call(a.removeUserOrgOData);
    yield put(success());
  } catch (e) {
    serverErrorsLogger(e);
    yield put(failure({ message: e.message }));
  }
}

export default function* rootSaga() {
  yield takeLatest(c.checkUserV2.tries, checkUserOIDCOdata);
  yield takeLatest(c.getOIDCInfo.tries, getOIDCInfoSaga);
  yield takeLatest(c.getUserInfoOdata.tries, getUserInfoOdataSaga);
  yield takeLatest(c.setUserOrgOData.tries, setUserOrgOData);
  yield takeLatest(c.removeUserOrgOData.tries, removeUserOrgODataSaga);
}
