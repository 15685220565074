import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Col, DatePicker, Divider, Form, Radio, Row, Select,
} from 'antd';
import { ButtonV2, InputV2 } from 'components';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { numberValidation } from 'utils/form';

import './style.less';


const numberRulesRequired = {
  rules: [
    { required: true },
    {
      pattern: numberValidation,
      message: 'Поле может содержать только число',
    },
  ],
};

const CharacteristicsForm = ({
  isValidityPeriod,
  arrayRulesRequired,
  stringRulesRequired,
  selectOptions,
  dateOfRegistryRequired,
  setDateOfRegistryRequired,
  article
}) => (
  <>
    {article && (
      <Form.Item
        {...stringRulesRequired}
        name="article"
        label="Артикул"
      >
        <InputV2
          placeholder="0000е7667"
          disabled
        />
      </Form.Item>
    )}

    <Form.Item
      name="tags"
      label="Теги"
    >
      <Select
        mode="multiple"
        className="form-select"
        size="large"
        placeholder="Выберите теги"
        options={selectOptions.tag}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </Form.Item>

    <Form.Item
      {...arrayRulesRequired}
      name="territoryActivation"
      label="Территория активации"
    >
      <Select
        mode="multiple"
        className="form-select"
        size="large"
        showSearch
        placeholder="Выберите страны"
        options={selectOptions.territoryActivation}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </Form.Item>

    <Form.Item
      {...arrayRulesRequired}
      name="language"
      label="Язык"
    >
      <Select
        mode="multiple"
        className="form-select"
        size="large"
        showSearch
        placeholder="Выберите язык"
        options={selectOptions.language}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </Form.Item>

    <Divider orientation="left" orientationMargin="0">
      Комплект поставки
    </Divider>

    <div className="form-block-group-background">
      <Form.List name="complectsOfDelivery" rules={[{ required: true }]}>
        {(
          fields, {
            add,
            remove,
          }
        ) => (
          <>
            {fields.map(({
              key,
              name,
              fieldKey,
              ...restField
            }) => (
              <Row gutter={24} align="top">
                <Col span={11}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Наименование" обязательно!',
                      }]}
                    label="Наименование"
                    {...restField}
                    name={[name, 'nomination']}
                    fieldKey={[fieldKey, 'nomination']}
                  >
                    <Select
                      className="form-select"
                      size="large"
                      showSearch
                      placeholder="Выберите компонент комплекта поставки"
                      options={selectOptions.nomination}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Тип" обязательно!',
                      }]}
                    label="Тип"
                    {...restField}
                    name={[name, 'complectsType']}
                    fieldKey={[fieldKey, 'complectsType']}
                  >
                    <Select
                      className="form-select"
                      size="large"
                      showSearch
                      placeholder="Выберите тип носителя"
                      options={selectOptions.complectsType}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} className="col-icon-center">
                  {fields.length > 1 && (
                    <TrashIcon
                      className="trash-btn"
                      onClick={() => remove(name)}
                    />
                  )}
                </Col>
              </Row>
            ))}
            <ButtonV2 className="add-btn" onClick={() => add()} type="link" icon={<PlusOutlined />}>
              Добавить компонент
            </ButtonV2>
          </>
        )}
      </Form.List>
    </div>

    <Form.Item
      {...arrayRulesRequired}
      name="operationSystem"
      label="Операционная система"
    >
      <Select
        mode="multiple"
        className="form-select"
        size="large"
        showSearch
        placeholder="Выберите ОС"
        options={selectOptions.operationSystem}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
    </Form.Item>

    <Divider orientation="left" orientationMargin="0">
      <div className="required">Срок действия лицензии</div>
    </Divider>

    <div className="form-block-group-background">
      <Row gutter={24} align="top">
        <Col span={24}>
          <Form.Item
            className="item-bottom-0"
            type="string"
            name={['licenseExpires', 'licenseType']}
            rules={[
              {
                required: true,
                message: 'Пожалуйста выберите срок действия лицензии!',
              }]}
          >
            <Radio.Group>
              <Radio value="unlimit">Бессрочно</Radio>
              <Radio value="limit">Указать период</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {isValidityPeriod && (
        <Row gutter={24} align="middle" style={{ marginTop: 16 }}>
          <Col span={12}>
            <Form.Item
              {...numberRulesRequired}
              className="item-bottom-0"
              label="Значение"
              name={['licenseExpires', 'value']}
            >
              <InputV2
                controls={false}
                placeholder="Введите число"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              className="item-bottom-0"
              label="Единица измерения"
              name={['licenseExpires', 'unit']}
            >
              <Select
                className="form-select"
                size="large"
                showSearch
                placeholder="Введите единицу измерения"
                options={selectOptions.unit}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

    </div>

    <Form.Item
      rules={[
        {
          pattern: numberValidation,
          message: 'Поле может содержать только число',
        }]}
      name="devicesCount"
      label="Количество устройств"
    >
      <InputV2
        placeholder="Введите количество устройств"
        controls={false}
        maxLength={5}
      />
    </Form.Item>
    <Form.Item
      type="string"
      label="№ реестровой записи в реестре отечественного ПО"
      name={['registry', 'numOfRegistry']}
      rules={[
        {
          pattern: /[0-9]/,
          message: 'Поле может содержать только число',
        }]}
    >
      <InputV2
        onChange={(e) => setDateOfRegistryRequired(Boolean(e.target.value))}
        maxLength={10}
        type="string"
        placeholder="Введите название"
      />
    </Form.Item>

    {dateOfRegistryRequired && (
      <Form.Item
        type="date"
        label="Дата регистрации"
        name={['registry', 'dateOfRegistry']}
        rules={[
          {
            required: true,
            message: 'Пожалуйста выберите дату',
          }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          placeholder="31.12.2022"
          format="DD.MM.YYYY"
        />
      </Form.Item>
    )}
  </>
);

export default CharacteristicsForm;
