import * as c from '../constants';


const initialState = {
  name: undefined,
  surname: undefined,
  middleName: undefined,
  userName: undefined,
  dateOfBirth: undefined,
  email: undefined,
  organizationId: undefined,
  roleName: undefined,
  photoUrl: '',
  // notifications: 4,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.checkUser.failure:
    case c.loginUser.failure:
    case c.logoutUser.success: {
      return {
        ...initialState,
      };
    }

    case c.checkUser.success: {
      const {
        lastName,
        firstName,
        middleName,
        snils,
        email,
        phone,
        position,
        organization,
        orgParent,
        userroleSet,
        id,
      } = action.payload;

      return {
        ...initialState,
        id,
        lastName,
        firstName,
        middleName,
        userName: `${firstName} ${lastName}`,
        snils,
        email,
        phone,
        position,
        organization,
        orgParent,
        orgApps: organization?.consortiumApplicationOrganization ?? null,
        userroleSet,
      };
    }

    case c.getCurrentUserOrgAppStatus.success: {
      const { data } = action.payload;

      return {
        ...state,
        consortiumApplication: { ...data },
      };
    }

    default:
      return state;
  }
};

export default reducer;
