import React, {
  Fragment, useEffect, useState
} from 'react';
import {
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { ButtonV2 } from 'components';
import {
  getCategories,
  getPaginationCategories,
  getPaginationApplications,
} from 'models/catalog/actions';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import {
  get as oGet,
  post as oPost,
  remove as oDelete,
  put as oPut,
  errorMessageCatcher,
} from 'utils/request';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';

import { getUserRole, ROLE_ADMIN, getAccessData } from 'utils/auth';
import { stageOptions } from 'utils/consts';
import { writeCategoryReportFile } from 'utils/xlsx/writeXlsx';
import { useDebounce } from 'react-use';
import { Link } from 'react-router-dom';
import AdminCategoryForm from './form';
import RemoveModalWithChange from './components/removeModal/component';
import AdminCategoriesPaneTable from './table';

import './style.less';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

/* TEMP create functions */
export const createCategory = (data) => {
  const url = '/categories';

  return oPost(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const updateCategory = (data, id) => {
  const url = `/categories/${id}`;

  return oPut(
    `${url}`,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const deleteCategory = (id) => {
  const url = `/categories/${id}`;

  return oDelete(
    url,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const getCategoriesRecord = () => {
  const url = '/categories';

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API,
    { stage: 'draft,published' }
  );
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
  stage: 'draft,published',
};

const AdminCategoriesPane = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();

  const [modalConfig, setModalConfig] = useState({ open: false });
  const [removeConfig, setRemoveConfig] = useState({ open: false });
  const [removeWithChangeCategoryConfig, setRemoveWithChangeCategoryConfig] = useState({ open: false });
  const [applicationModalIsOpen, setApplicationModalIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState();
  const [skipDebounce, setSkipDebounce] = useState(true);
  const [searchTitle, setSearchTitle] = useState('');
  const [applicationParams, setApplicationParams] = useState();
  const [categoriesParams, setCategoriesParams] = useState(DEFAULT_PARAMS);
  const [applicationList, setApplicationList] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const userRole = getUserRole();
  const user = getAccessData();
  const [changesCounter, setChangesCounter] = useState(0);
  const {
    items: appItems,
    totalCount: appTotal,
    loading: appLoading,
  } = useSelector((state) => state.catalog.paginationApplications);
  const categories = useSelector((state) => state.catalog.categories);
  const {
    items: categoriesItems,
    totalCount: categoriesTotal,
    loading: categoriesLoading,
  } = useSelector((state) => state.catalog.paginationCategories);
  const showMoreBtn = Boolean(applicationList.length)
    && applicationParams?.limit * applicationParams?.page < appTotal;

  useEffect(() => {
    dispatch(getCategories({ stage: 'draft,published' }));
  }, [dispatch, changesCounter]);

  useEffect(() => {
    dispatch(getPaginationCategories(categoriesParams));
  }, [dispatch, changesCounter, categoriesParams]);

  useEffect(() => {
    if (!applicationParams) {
      return;
    }
    dispatch(getPaginationApplications(applicationParams));
  }, [applicationParams, dispatch]);

  useEffect(() => {
    const items = applicationParams?.page === 1
      ? appItems
      : [...applicationList, ...appItems];
    setApplicationList(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appItems]);

  const handleReportCategories = () => {
    getCategoriesRecord().then((response) => writeCategoryReportFile(response));
  };

  const onEditCategoryForm = async (model, id) => {
    setModalLoading(true);
    await updateCategory(model, id).then(() => {
      message.success({
        content: 'Категория обновлена',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setModalConfig({ openModal: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onAddCategoryForm = async (model) => {
    setModalLoading(true);
    await createCategory(model).then(() => {
      message.success({
        content: 'Категория добавлена',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setModalConfig({ openModal: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onFinish = (model) => {
    if (modalConfig.defaultValue) {
      const preparedData = {
        ...model,
        stage: modalConfig.defaultValue.stage,
      };
      onEditCategoryForm(preparedData, modalConfig.defaultValue.id);
    } else {
      const preparedData = {
        ...model,
        stage: 'draft',
      };
      onAddCategoryForm(preparedData);
    }
  };

  const handleDeleteCategoryClick = async (id, hasApplication = false) => {
    setModalLoading(true);
    const content = `Категория удалена${hasApplication ? ', замена произведена' : ''}`;
    await deleteCategory(id).then(() => {
      message.success({
        content,
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setRemoveConfig({ open: false });
      setRemoveWithChangeCategoryConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const openApplicationsModal = (category) => {
    if (!category.applicationsCount) {
      return;
    }
    setCurrentCategory(category);
    setApplicationModalIsOpen(true);
    setApplicationParams({
      ...DEFAULT_PARAMS,
      stage: 'published,draft',
      categoryId: category.id,
    });
  };

  const closeApplicationsModal = () => {
    setCurrentCategory(null);
    setApplicationList([]);
    setApplicationModalIsOpen(false);
  };

  const openModal = (defaultValue) => {
    setModalConfig({
      open: true,
      defaultValue,
    });
  };

  const closeModal = () => {
    if (modalLoading) {
      return;
    }
    setModalConfig({ open: false });
  };

  const changeStage = (category) => {
    const stage = category.stage === 'draft' ? 'published' : 'draft';
    const prepearedData = {
      title: category.title,
      slug: category.slug,
      icon: category.icon,
      stage,
    };
    onEditCategoryForm(prepearedData, category.id);
  };

  const getItems = (item) => {
    const publishItemText = item.stage === 'draft' ? 'Опубликовать' : 'Снять с публикации';
    const hasAccess = userRole === ROLE_ADMIN || item.userCreator === user.id;
    const removeOnCLick = () => {
      const removeConfigBody = { open: true, category: item };
      if (item.applicationsCount) {
        setRemoveWithChangeCategoryConfig(removeConfigBody);
        return;
      }
      setRemoveConfig(removeConfigBody);
    };

    const dropdownItems = [
      {
        key: '1',
        label: <div>Редактировать</div>,
        disabled: !hasAccess,
        onClick: () => openModal(item),
      },
      {
        key: '2',
        label: <div>{publishItemText}</div>,
        disabled: !hasAccess,
        onClick: () => changeStage(item),
      },
    ];

    if (item.stage === 'draft') {
      dropdownItems.push({
        key: '3',
        label: <div>Удалить</div>,
        disabled: !hasAccess,
        onClick: removeOnCLick,
      });
    }
    if (item.stage === 'published') {
      dropdownItems.push({
        key: '4',
        label: <div>Связанные приложения</div>,
        onClick: () => openApplicationsModal(item),
      });
    }
    return dropdownItems;
  };

  const onPaginationChange = (params) => {
    setCategoriesParams((prev) => ({ ...prev, ...params }));
  };

  const setCategoriesSortHandler = (sort) => {
    setCategoriesParams((prev) => ({
      ...prev,
      sort,
    }));
  };

  const onStageChange = (stage) => {
    setCategoriesParams((prev) => ({ ...prev, stage, page: 1 }));
  };

  const resetFields = () => {
    setCategoriesParams(DEFAULT_PARAMS);
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setCategoriesParams((prev) => ({ ...prev, title: searchTitle, page: 1 }));
    },
    500,
    [searchTitle]
  );

  const removeCancel = () => {
    if (modalLoading) {
      return;
    }
    setRemoveWithChangeCategoryConfig({ open: false });
    setRemoveConfig({ open: false });
  };

  return (
    <div className="admin-form admin-categories-pane">
      <Row justify="space-between" gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Form form={filterForm} className="filter">
          <Space>
            <Form.Item name="title" className="filter-item filter-search">
              <Input
                placeholder="Поиск по названию"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item name="stage" className="filter-item filter-select">
              <Select
                placeholder="Выберите статус"
                popupClassName="admin-select-dropdown"
                options={stageOptions}
                onChange={onStageChange}
              />
            </Form.Item>
            <ButtonV2
              icon={<CloseOutlined />}
              type="link"
              className="filter-resetButton"
              onClick={() => {
                filterForm.resetFields();
                resetFields();
              }}
            >
              Сбросить фильтр
            </ButtonV2>
          </Space>
        </Form>
        <Space>
          <ButtonV2
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleReportCategories}
          >
            Сформировать отчёт
          </ButtonV2>
          <ButtonV2
            type="link"
            icon={<PlusOutlined />}
            onClick={() => openModal()}
          >
            Добавить новую запись
          </ButtonV2>
        </Space>

        {modalConfig.open && (
          <AdminCategoryForm
            {...modalConfig}
            onClose={closeModal}
            categories={categories}
            onFinish={onFinish}
            requestLoading={modalLoading}
            destroyOnClose
          />
        )}
      </Row>

      <Spin indicator={loadingIcon} spinning={categoriesLoading}>
        <AdminCategoriesPaneTable
          items={categoriesItems}
          params={categoriesParams}
          totalCount={categoriesTotal}
          onSortParams={setCategoriesSortHandler}
          onChangeParams={onPaginationChange}
          getItems={getItems}
        />
      </Spin>

      {removeWithChangeCategoryConfig.open && (
        <RemoveModalWithChange
          {...removeWithChangeCategoryConfig}
          onCancel={removeCancel}
          handleDelete={(e) => handleDeleteCategoryClick(e, true)}
          requestLoading={modalLoading}
          startRequest={setModalLoading}
        />
      )}

      <Modal
        width={710}
        open={removeConfig.open}
        okText="Удалить"
        onCancel={removeCancel}
        title="Удалить запись"
        footer={[
          <>
            <ButtonV2 onClick={removeCancel} disabled={modalLoading}>
              Отменить
            </ButtonV2>
            <ButtonV2
              type="primary"
              onClick={() => handleDeleteCategoryClick(removeConfig.category.id)}
              loading={modalLoading}
            >
              Удалить
            </ButtonV2>
          </>,
        ]}
        maskClosable
      >
        {removeConfig.category && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                Вы уверены, что хотите удалить категорию "
                {removeConfig.category.title}
                "?
              </p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        width={710}
        title={`Связанные приложения "${currentCategory?.title}"`}
        footer={null}
        destroyOnClose
        open={applicationModalIsOpen}
        onCancel={closeApplicationsModal}
        maskClosable
      >
        <Spin indicator={loadingIcon} spinning={appLoading}>
          <div className="admin_category_applications">
            {applicationList.map((application) => (
              <Fragment key={application.id}>

                {application.stage === 'published' ? (
                  <Link
                    className="related-app"
                    to={`/catalog/${application.vendor.slug}/${application.slug}`}
                  >
                    <div className="related-app-link">
                      <span>{application.title}</span>
                      <ArrowRightOutlined />
                    </div>
                  </Link>
                ) : (
                  <div className="draft-related-app">
                    <span className="gray">{application.title}</span>
                  </div>
                )}
                <Divider className="related-app-divider" />
              </Fragment>
            ))}
            {showMoreBtn && (
              <div className="admin_category_applications_more">
                <ButtonV2
                  onClick={() => setApplicationParams((prev) => ({
                    ...prev,
                    page: prev.page + 1,
                  }))}
                >
                  Показать еще
                </ButtonV2>
              </div>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default AdminCategoriesPane;
