import React, { useMemo } from 'react';
import bem from 'easy-bem';
import './style.less';
import { Link } from 'react-router-dom';
import { CardLogo } from 'components';
import ApplicationTags from 'components/application-tags';


const VerticalApplicationCard = ({
  title,
  descriptionShort: description,
  mainImageUrl: image,
  badge,
  badgeText,
  vendor,
  price,
  publishedAt,
  paymentType,
  slug,
  promo
}) => {
  const b = bem('vertical-application-card');

  const priceBadge = useMemo(() => {
    if (paymentType === 'free') {
      return 'бесплатно';
    }
    if (paymentType === 'price-on-request') {
      return 'по запросу';
    }
    if (paymentType === 'specified') {
      return `${price} ₽`;
    }
    return price;
  }, [paymentType, price]);

  return (
    <Link className={b()} to={`/catalog/${vendor.slug || vendor}/${slug}`}>
      <div className="card-preview">
        <div className={b('badges')}>
          {badge === 'certificate' && (
            <div className="priceBadge">
              Сертификация
            </div>
          )}
          {paymentType !== 'no-price' && (
            <div className="priceBadge">{priceBadge}</div>
          )}
        </div>
        {image ? (
          <img className="preview-image" alt="Превью" src={image} />
        ) : (
          <CardLogo className="preview-logo" label={title} />
        )}
        <div className="preview-overlay" />
      </div>
      <div className="card-content-wrapper">
        <div className="card-content">
          <h5 className="content-title">{title}</h5>
          <p className="content-description">{description}</p>
        </div>
        <div className="card-category">
          <ApplicationTags
            className="badge"
            badge={badge}
            badgeText={badgeText}
            price={price}
            date={publishedAt}
            promo={promo}
          />
        </div>
      </div>
    </Link>
  );
};

export default VerticalApplicationCard;
