import React, { useEffect, useState } from 'react';
import {
  Form, Modal, Select, Tooltip
} from 'antd';

import { InputV2, ButtonV2 } from 'components';
import './style.less';
import FileUploader from 'components/file-uloader/component';
import { fileValidator } from 'utils/form';


const AdminPromosForm = ({
  specialOptions = [],
  defaultValue,
  buttonText,
  onFinish: onFinishInput,
  modalOpen,
  onClose,
  requestLoading,
  ...rest
}) => {
  const [form] = Form.useForm();

  const [logo, setLogo] = useState({
    desktopBanner: defaultValue?.desktopBanner,
    mobileBanner: defaultValue?.mobileBanner,
  });
  const [disabledOkBtn, setDisableOkBtn] = useState(!defaultValue);

  useEffect(() => {
    if (!defaultValue) {
      form.resetFields();
      return;
    }
    setLogo({
      desktopBanner: defaultValue.desktopBanner,
      mobileBanner: defaultValue.mobileBanner,
    });
    form.setFieldsValue({
      title: defaultValue.title,
      type: defaultValue.type
        ? { label: defaultValue.type.value, value: defaultValue.type.id }
        : undefined,
      desktopBanner: defaultValue.desktopBanner,
      mobileBanner: defaultValue.mobileBanner,
    });
  }, [defaultValue]);

  const handleOnFinish = async (model) => {
    await onFinishInput({ ...model, type: model.type?.id || model.type });
  };

  const handleChangeImage = (fieldName, value) => {
    form.setFieldValue(fieldName, value);
  };

  const handleOnChange = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisableOkBtn(!!e.errorFields.length));
  };

  return (
    <Modal
      title={`${defaultValue ? 'Редактировать' : 'Добавить новую'} запись`}
      onCancel={onClose}
      open={modalOpen}
      footer={[
        <>
          <ButtonV2 onClick={onClose} disabled={requestLoading}>Отменить</ButtonV2>
          <Tooltip
            title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}
          >
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
                loading={requestLoading}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={defaultValue}
        layout="vertical"
        form={form}
        onValuesChange={handleOnChange}
        onFinish={handleOnFinish}
        {...rest}
      >
        <Form.Item
          rules={[
            { type: 'string', required: true, message: 'Поле может содержать только текст' },
            {
              pattern: /^(?!\s*$).+/,
              message: 'Поле может содержать только текст',
            },
          ]}
          name="title"
          label="Название подборки"
        >
          <InputV2 maxLength={20} placeholder="Название подборки" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Выберите тип спецпредложения' }]}
          name="type"
          label="Тип спецпредложения"
        >
          <Select
            className="form-select"
            size="large"
            filterOption={false}
            placeholder="Тип спецпредложения"
            options={specialOptions}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>

        <FileUploader
          rules={[
            {
              required: true,
              validator: (_, value) => fileValidator(value, true),
            },
          ]}
          name="desktopBanner"
          label="Баннер десктоп версия"
          onChange={(val) => handleChangeImage('desktopBanner', val)}
          maxSize={10}
          initialSrc={logo.desktopBanner}
        />
        <FileUploader
          rules={[{
            required: true,
            validator: (_, value) => fileValidator(value, true),
          }]}
          name="mobileBanner"
          label="Баннер моб. версия"
          onChange={(val) => handleChangeImage('mobileBanner', val)}
          maxSize={10}
          initialSrc={logo.mobileBanner}
        />
      </Form>
    </Modal>
  );
};

export default AdminPromosForm;
