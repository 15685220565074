import React from 'react';
import ReactQuill from 'react-quill';
import bem from 'easy-bem';

import './style.less';


const modulesDefault = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

const formatsDefault = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'link',
];

const OOCTextEditor = (props) => {
  const b = bem('ooc-text-editor');
  const {
    value,
    onChange,
    theme,
    modules,
    formats,
    className,
    ...rest
  } = props;

  const cn = className ? `${className} ${b()}` : b();

  return (
    <ReactQuill
      {...rest}
      className={cn}
      theme={theme ?? 'snow'}
      value={value || ''}
      modules={modules ?? modulesDefault}
      formats={formats ?? formatsDefault}
      onChange={onChange}
    />
  );
};

export default OOCTextEditor;
