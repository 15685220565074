import React, { useMemo, useState, useEffect } from 'react';
import { Dropdown, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { stageRu, tablePaginationOptions } from 'utils/consts';
import { getSortField } from 'utils/get-sort-field';
import { SortDropdown, getSortMenu } from 'components/sort-dropdown';
import { getShortFio } from 'utils/get-short-fio';


const AdminAppsPaneTable = ({
  items,
  params,
  getItems,
  totalCount,
  onSortParams,
  onChangeParams,
}) => {
  const [sortParams, setSortParams] = useState({});

  useEffect(() => {
    onSortParams(getSortField(sortParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortParams]);

  const setSortParamsHandler = (field, sort) => {
    setSortParams((prev) => {
      const sortResult = prev[field] !== sort ? sort : null;

      return { [field]: sortResult };
    });
  };

  const onPaginationChange = ({ current: page, pageSize: limit }) => {
    onChangeParams({ page, limit });
  };

  const showTotal = (total, range) => {
    const allItemsText = `Всего записей: ${total}`;
    const rangeItemsText = `${range[0]} - ${range[1]} из ${total} записей`;
    return (
      <div className="pagination-showTotal">
        <div>{allItemsText}</div>
        <div>{rangeItemsText}</div>
      </div>
    );
  };

  const paginationParams = {
    showTotal,
    total: totalCount,
    current: params.page,
    pageSize: params.limit,
    pageSizeOptions: tablePaginationOptions,
    showSizeChanger: true,
  };

  const TITLE_SORT_MENU = getSortMenu({
    field: 'title',
    ascendLabel: 'По алфавиту (А-Я) (A-Z)',
    descendLabel: 'По алфавиту (Я-А) (Z-A)',
    onSort: setSortParamsHandler,
  });

  const PUBLISHED_AT_SORT_MENU = getSortMenu({
    field: 'publishedAt',
    ascendLabel: 'Сначала старые',
    descendLabel: 'Сначала новые',
    onSort: setSortParamsHandler,
  });

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: () => (
          <div className="table-sort-header">
            Название приложения
            <SortDropdown items={TITLE_SORT_MENU} order={sortParams.title} />
          </div>
        ),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Вендор',
        dataIndex: 'vendor',
        key: 'vendor',
        render: (vendor) => vendor.name,
      },
      {
        title: 'Статус',
        dataIndex: 'stage',
        key: 'stage',
        render: (stage) => {
          const cn = stage === 'published' ? 'green' : 'gray';
          return <span className={cn}>{stageRu[stage]}</span>;
        },
      },
      {
        title: () => (
          <div className="table-sort-header">
            Дата публикации
            <SortDropdown
              items={PUBLISHED_AT_SORT_MENU}
              order={sortParams.publishedAt}
            />
          </div>
        ),
        dataIndex: 'publishedAt',
        key: 'publishedAt',
        render: (publishedAt, { stage }) => (stage === 'published' ? dayjs(publishedAt).format('DD.MM.YYYY') : '-'),
      },
      {
        title: 'Дистрибутив',
        dataIndex: 'packages',
        key: 'packages',
        render: (packages) => (packages?.length ? 'Да' : 'Нет'),
      },
      {
        title: 'Автор записи',
        render: ({ userName, userPatronymic, userSurname }) => getShortFio({
          name: userName,
          patronymic: userPatronymic,
          surname: userSurname,
        }),
      },
      {
        title: 'Аналоги',
        dataIndex: 'analogs',
        key: 'analogs',
        render: (analogs) => (analogs?.length ? 'Да' : 'Нет'),
      },
      {
        width: 30,
        render: (record) => (
          <Dropdown
            menu={{
              items: getItems(record),
            }}
            trigger={['click']}
          >
            <EllipsisOutlined />
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortParams.publishedAt, sortParams.title]
  );

  const applicationItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [items]);

  return (
    <Table
      columns={columns}
      total={totalCount}
      pagination={paginationParams}
      dataSource={applicationItems}
      onChange={onPaginationChange}
    />
  );
};

export default AdminAppsPaneTable;
