import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { Select, Spin } from 'antd';


export const DebounceSelect = ({
  loading,
  fetchOptions,
  debounceTimeout = 800,
  ...props
}) => {
  const [search, setSearch] = useState('');

  useDebounce(
    () => fetchOptions(search),
    debounceTimeout,
    [search]
  );

  return (
    <Select
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      onSearch={(val) => setSearch(val)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      {...props}
    />
  );
};
