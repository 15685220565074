import pagination from 'components/pagination';
import * as c from './constants';


const initialState = {
  users: [],
  vendors: [],
  vendor: null,
  application: null,
  applications: [],
  applicationsLoading: true,
  paginationApplications: {
    totalCount: 0,
    items: [],
    loading: false,
  },
  paginationCategories: {
    totalCount: 0,
    items: [],
    loading: false,
  },
  paginationVendors: {
    totalCount: 0,
    items: [],
    loading: false,
  },
  paginationAuthors: {
    totalCount: 0,
    items: [],
    loading: false,
  },
  paginationConfigurator: {
    items: [],
    totalCount: 0,
    loading: false,
  },
  paginationPromos: {
    items: [],
    totalCount: 0,
    loading: false,
  },
  paginationApplicationsByVendor: {
    items: [],
    totalCount: 0,
    loading: false,
  },
  paginationFeedbacks: {
    items: [],
    totalCount: 0,
    loading: false,
  },
  authorsOrganization: [],
  categories: [],
  feedbacks: [],
  authors: [],
  oneidUsers: [],
  applicationsStatistic: null,
  applicationsStatisticOrganizations: [],
  applicationsWithAnalog: {
    totalCount: 0,
    list: false,
  },
  applicationsByVendor: [],
  applicationsWithAnalogSet: false,
  isSidebarVisible: true,
  applicationsCount: 0,
  configurator: [],
  configuratorOptions: null,
  promos: [],
  promo: null,
  types: null,
  vendorRating: null,
  applicationFeedbacks: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case c.getApplicationsTypes.success: {
      return { ...state, types: action.payload };
    }
    case c.getUsers.success: {
      return { ...state, users: action.payload };
    }
    case c.getVendors.before: {
      return { ...state, vendors: [] };
    }
    case c.getVendors.success: {
      return { ...state, vendors: action.payload };
    }
    case c.getVendorById.before: {
      return {
        ...state,
        vendor: null,
        vendorRating: null,
        applicationsByVendor: [],
      };
    }
    case c.getVendorById.success: {
      return { ...state, vendor: action.payload };
    }
    case c.getVendorById.failure: {
      if (action.payload.error.status === 404) {
        return { ...state, vendor: false };
      }
      return { ...state };
    }
    case c.getApplications.before: {
      return { ...state, applications: [], applicationsLoading: true };
    }
    case c.getApplications.success: {
      return { ...state, applications: action.payload, applicationsLoading: false };
    }
    case c.getApplicationsByVendorId.success: {
      return { ...state, applicationsByVendor: action.payload };
    }
    case c.getPaginationApplicationsByVendorId.before: {
      return { ...state, paginationApplicationsByVendor: { ...state.paginationApplicationsByVendor, loading: true } };
    }
    case c.getPaginationApplicationsByVendorId.success: {
      return { ...state, paginationApplicationsByVendor: { ...action.payload, loading: false } };
    }
    case c.getPaginationApplicationsByVendorId.failure: {
      return { ...state, paginationApplicationsByVendor: { ...state.paginationApplicationsByVendor, loading: false } };
    }
    case c.getApplicationById.before: {
      return { ...state, application: null, applicationFeedbacks: [] };
    }
    case c.getApplicationById.success: {
      return { ...state, application: action.payload };
    }
    case c.getApplicationById.failure: {
      if (action.payload.error.status === 404) {
        return { ...state, application: null };
      }
      return { ...state };
    }
    // TODO - find better method
    case c.getCategories.success: {
      if (action.payload && action.payload.length > 0) {
        const generalCategory = action.payload.pop();
        action.payload.unshift(generalCategory);
      }

      return { ...state, categories: action.payload };
    }
    case c.getFeedbacks.success: {
      return { ...state, feedbacks: action.payload };
    }
    case c.getAuthors.success: {
      return { ...state, authors: action.payload };
    }
    case c.getOneidUsers.success: {
      return { ...state, oneidUsers: action.payload.items };
    }
    case c.getApplicationsStatistic.success: {
      return { ...state, applicationsStatistic: action.payload };
    }
    case c.getApplicationsStatisticOrganizations.success: {
      return { ...state, applicationsStatisticOrganizations: action.payload };
    }
    case c.getApplicationsWithAnalog.success: {
      return { ...state, applicationsWithAnalog: action.payload };
    }
    case c.getApplicationsWithAnalogSet.success: {
      return { ...state, applicationsWithAnalogSet: action.payload };
    }
    case c.getSidebarVisible.success: {
      return { ...state, isSidebarVisible: action.payload };
    }
    case c.getApplicationsCount.success: {
      return { ...state, applicationsCount: action.payload };
    }
    case c.getConfigurator.success: {
      return { ...state, configurator: action.payload };
    }
    case c.getPaginationConfigurator.before: {
      return { ...state, paginationConfigurator: { ...state.paginationConfigurator, loading: true } };
    }
    case c.getPaginationConfigurator.success: {
      return { ...state, paginationConfigurator: { ...action.payload, loading: false } };
    }
    case c.getPaginationConfigurator.failure: {
      return { ...state, paginationConfigurator: { ...state.paginationConfigurator, loading: false } };
    }
    case c.getConfiguratorOptions.success: {
      return { ...state, configuratorOptions: action.payload };
    }
    case c.getPromos.success: {
      return { ...state, promos: action.payload };
    }
    case c.getPaginationPromos.before: {
      return { ...state, paginationPromos: { ...state.paginationPromos, loading: true } };
    }
    case c.getPaginationPromos.success: {
      return { ...state, paginationPromos: { ...action.payload, loading: false } };
    }
    case c.getPaginationPromos.failure: {
      return { ...state, paginationPromos: { ...state.paginationPromos, loading: false } };
    }
    case c.getPromosById.success: {
      return { ...state, promo: action.payload };
    }
    case c.getPaginationApplications.before: {
      return { ...state, paginationApplications: { ...state.paginationApplications, loading: true } };
    }
    case c.getPaginationCategories.before: {
      return { ...state, paginationCategories: { ...state.paginationCategories, loading: true } };
    }
    case c.getPaginationVendors.before: {
      return { ...state, paginationVendors: { ...state.paginationVendors, loading: true } };
    }
    case c.getPaginationAuthors.before: {
      return { ...state, paginationAuthors: { ...state.paginationAuthors, loading: true } };
    }
    case c.getPaginationFeedbacks.before: {
      return { ...state, paginationFeedbacks: { ...state.paginationFeedbacks, loading: true } };
    }
    
    case c.getPaginationApplications.success: {
      return { ...state, paginationApplications: { ...action.payload, loading: false } };
    }
    case c.getPaginationCategories.success: {
      return { ...state, paginationCategories: { ...action.payload, loading: false } };
    }
    case c.getPaginationVendors.success: {
      return { ...state, paginationVendors: { ...action.payload, loading: false } };
    }
    case c.getPaginationAuthors.success: {
      return { ...state, paginationAuthors: { ...action.payload, loading: false } };
    }
    case c.getPaginationFeedbacks.success: {
      return { ...state, paginationFeedbacks: { ...action.payload, loading: false } };
    }

    case c.getPaginationApplications.failure: {
      return { ...state, paginationApplications: { ...state.paginationApplications, loading: false } };
    }
    case c.getPaginationCategories.failure: {
      return { ...state, paginationCategories: { ...state.paginationCategories, loading: false } };
    }
    case c.getPaginationVendors.failure: {
      return { ...state, paginationVendors: { ...state.paginationVendors, loading: false } };
    }
    case c.getPaginationAuthors.failure: {
      return { ...state, paginationAuthors: { ...state.paginationAuthors, loading: false } };
    }
    case c.getPaginationFeedbacks.failure: {
      return { ...state, paginationFeedbacks: { ...state.paginationFeedbacks, loading: false } };
    }
    
    case c.getAuthorsOrganization.success: {
      const authorsOrganization = action.payload.filter((organization) => Boolean(organization));
      return { ...state, authorsOrganization };
    }
    case c.getVendorRating.failure: {
      return { ...state, vendorRating: null };
    }
    case c.getVendorRating.success: {
      return { ...state, vendorRating: action.payload?.averageRating };
    }
    case c.getApplicationFeedbacks.success: {
      return { ...state, applicationFeedbacks: action.payload };
    }
    case c.getApplicationFeedbacks.failure: {
      return { ...state, applicationFeedbacks: [] };
    }
    default:
      return state;
  }
};

export default reducer;
